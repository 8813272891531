import React, { Component } from 'react'
import face1 from '../../../assets/images/profile-2.webp'
import { ReactComponent as Logo} from '../../../assets/images/logo-icon.svg'
import logo_color from '../../../assets/images/genhaven-color.webp'
import  agent from '../../../assets/images/agent.svg'
import { ReactComponent as Lockaccess} from '../../../assets/images/lock-access.svg'
import { ReactComponent as Agent}  from '../../../assets/images/agent.svg'
import { ReactComponent as List} from '../../../assets/images/list.svg'
import { ReactComponent as Add} from '../../../assets/images/plus-lg.svg'
import { ReactComponent as Buildingcommunity}  from '../../../assets/images/building-community.svg'
import { ReactComponent as Transaction}  from '../../../assets/images/transaction.svg'
import { ReactComponent as CMS}  from '../../../assets/images/gear.svg'
import { ReactComponent as Person} from '../../../assets/images/person.svg'
import { ReactComponent as Package} from '../../../assets/images/package.svg'
import { ReactComponent as Rental} from '../../../assets/images/rental.svg'

import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import { Tooltip } from 'reactstrap';
import { APIURL, BASEURL, SOCKETURL } from '../../../components/constants/common';
import socketClient from "socket.io-client";

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // stylePath:true,
            sidebarClass: "sidebar-icon-only",
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            profile_image: "",
            dashboard: false,
            myProfile: false,
            property: false,
            enquiry: false,
            agentreq: false,
            changePass: false,
            reload:false,
            NoImage:false,
            chatNotification:0,
            socket:""
            // user:false
        }
    }

     
   

    getProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"seller/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        profile_image: response.data.data.url_path,
                        name: response.data.data.name,
                        NoImage:true
                    })
                   
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }



    getChatCount() {
        if (this.state.user) {
            const formData = new FormData();
            var token = this.state.token
            var app_url = APIURL+"sub-user/get-buyers-unread-message-count"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        chatNotification: response.data.data
                    })
                })
                .catch((error) => {
                  
                });
        }
    }

    configureSocket = () => {
        var socket = socketClient(SOCKETURL);
        
        this.setState({
            socket: socket
        })
        socket.on('message', data => {

            if(data.to==this.state.user.id && this.props.activePage !== "chat")
            {
                 var chatNotification=this.state.chatNotification
                    chatNotification+=1;
                    this.setState({
                        chatNotification: chatNotification
                    })
            }
        });

    }




    dashboard = () => { this.setState({ dashboard: !this.state.dashboard }) }
    myProfile = () => { this.setState({ myProfile: !this.state.myProfile }) }
    property = () => { this.setState({ property: !this.state.property }) }
    enquiry = () => { this.setState({ enquiry: !this.state.enquiry }) }
    agentreq = () => { this.setState({ agentreq: !this.state.agentreq }) }
    changePass = () => { this.setState({ changePass: !this.state.changePass }) }

    componentDidMount() {
        console.log("componentDidMount")
        this.getProfileInfo()
        this.getChatCount()
        this.configureSocket()
        
    }
   goToHome(){
        window.location.href="/";
    }

    render() {
        
        const { user, name } = this.state
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "User") {
            return <Redirect to="/permission" />;
        }
        return (
            <>
               <div class="admin-sidebar">
                    <div class="brand-logo">
                        <a href="#">
                            {/* <Logo class="img-svg" alt="logo" /> */}
                            <img src={logo_color} alt="genhaven logo" class="img-svg"/>
                        </a>
                    </div>

                    <div class="content">
                        <div>
                            <a href="#" class="d-block">
                                <div class="navprofile d-flex align-items-center">
                                    <div class="navprofile__img">
                                   <span>  {
                                        this.state.NoImage && 
                                         <img src={this.state.profile_image !== "" ? this.state.profile_image : agent} alt="profile" />
                                    }</span>
                                      
                                    </div>
                                    <div>
                                        <div class="navprofile__text ms-3">
                                            <h6 class="font-weight-bold mb-0">{name}</h6>
                                            <span class="text-muted text-small">Sub User Panel</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="mt-4">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                 
                                <li class="nav-item">
                                  <Link  className={this.props.activePage === "profile" ? "nav-link active" : "nav-link"} to="/sub-user">
                                  <Person class="img-svg" /><span><span class="ms-3">My Profile</span></span>
                                  </Link>
                                </li>
                                <li class="nav-item">
                                  <Link  className={this.props.activePage === "buyer" ? "nav-link active" : "nav-link"} to="/sub-user/buyer">
                                  <Person class="img-svg" /><span><span class="ms-3">Buyer</span></span>
                                  </Link>
                                </li>
                                 <li class="nav-item">
                                  <Link  className={this.props.activePage === "chat" ? "nav-link active" : "nav-link"} to="/sub-user/chat">
                                  <Person class="img-svg" /><span><span class="ms-3">Chat</span></span> 
                                  { this.state.chatNotification>0 && this.props.activePage !== "chat" &&
                                   <div class="indicator">{this.state.chatNotification}</div>
                                  }
                                  </Link>
                                </li>
                                                   
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
