import React, { Component } from 'react'

import {ReactComponent as Property  } from '../../assets/images/property.svg'
import {ReactComponent as Agent  } from '../../assets/images/agent.svg'
import {ReactComponent as Deal  } from '../../assets/images/deal.svg'


export default class HowItWork extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true
		}
	}
	componentDidMount(){
          
	}
	render() {
		return (	
			<section id="howit_works" class="burger">
				<div class="container">
					<header class="section_heading">
						<div class="row">
							<div class="col-lg-6 col-md-8 col-sm-10 m-auto">
								<h2>How It Works?</h2>
								<p>At GENHAVEN we help connect prospects with their dream homes while ensuring that the dreams of turning your house to a home is fulfilled from wherever you are in the world.</p>
							</div>
						</div>
					</header>
					
					<div class="row mt-3">
						<div class="col-sm-4">
							<article class="work_process process_1">
								<a href="#">
									<span class="img"><Property class="img-svg" alt="property" /></span>
									<h3>Identify</h3>
									<p>Search our website for your desired property using the filters or input the details of your already identified property for which you need our services.</p>
								</a>
							</article>
						</div>
						<div class="col-sm-4">
							<article class="work_process process_2">
								<a href="#">
									<span class="img"><Agent class="img-svg" alt="agent" /></span>
									<h3>Action</h3>
									<p>Review the property details and request any of our services (Verification/hands-free acquisition) or send the agent a message directly.</p>
								</a>
							</article>
						</div>
						<div class="col-sm-4">
							<article class="work_process process_3">
								<a href="#">
									<span class="img"><Deal class="img-svg" alt="deal" /></span>
									<h3>Close The Deal</h3>
									<p>Proceed to making your acquisition safely with the help of professionals.<strong>“Building your home at home in trust and integrity”.</strong></p>
								</a>
							</article>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
