import React, { Component } from 'react'

import genhavenWhite from '../../../assets/images/genhaven-white.webp'
import Images from '../../../assets/images/geo-alt.svg'
import Img1 from '../../../assets/images/envelope.svg'
import Navbar from '../../Front/TopNavbar'
import Footer from '../../Front/Footer'
import axios from 'axios';
import { APIURL } from '../../../components/constants/common';
import { Button, Input } from 'reactstrap'



export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stylePath: true,
      name: "",
      email: "",
      phone_number: "",
      subject: "",
      message: "",
      errMsg: {},
      scsMsg: "",
      redirect: false,

    }
    this.onChangePhone = this.onChangePhone.bind(this)
  }
  onChangeName = (e) => {
    this.setState({
      name: e.target.value
    })
  }
  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    })
  }
  onChangePhone = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ phone_number: e.target.value })
    }
  }
  onChangeSubject = (e) => {
    this.setState({
      subject: e.target.value
    })
  }
  onChangeMessage = (e) => {
    this.setState({
      message: e.target.value
    })
    console.warn(e.target.value)
  }

  handleSubmit = (e) => {
    var token = this.state.token

    const formData = new FormData();
    formData.append('name', this.state.name);
    formData.append('email', this.state.email);
    formData.append('phone_number', this.state.phone_number);
    formData.append('subject', this.state.subject);
    formData.append('message', this.state.message);


    axios
      .post(APIURL + "contact-us-enquiry-send", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      .then((response) => {
        // console.log("contact-us", response.data.FormData)

        this.setState({
          scsMsg: response.data.message,
          name: "",
          email: "",
          phone_number: "",
          subject: "",
          message: "",
          errMsg: {},
        })

      })
      .catch((error) => {
        this.setState({
          errMsg: error.response.data.error,

        })
      });
  };

  render() {
    console.log(this.props)
    return (
      <div>
        {/* Navbar cmp */}
        <Navbar activePage="contact-us" />
        {/* header cmp */}
        <div class="content-area">
          <div class="breadcrumb-area gradient1">
            <div class="container">
              <h1>Contact Us</h1>
              {/* <div><small>Lorem Ipsum available, but the majority have suffered alteration injected humour</small></div> */}
            </div>
          </div>
          <div class="contact-us">
            <section class="tburger">
              <div class="container">
                <div class="row">
                  <div class="col-sm-8 position-relative">
                    <form class="contact-form2">
                      <div class="mb-5">
                        <h2>Submit Form</h2>
                        <p>Fill in the below details and a member of the team would reach out as soon as possible.</p>
                        {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                                              {/* messgae err */}
                                 
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-4">
                            <input type="text"
                              value={this.state.name}
                              size="40" class="form-control"
                              placeholder="Enter Your Name"
                              onChange={this.onChangeName}
                            />
                            <span className="text-danger">{this.state.errMsg.name}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-4">
                            <input type="email"
                              value={this.state.email}
                              size="40" class="form-control" placeholder="Enter Your Email"
                              onChange={this.onChangeEmail} />
                            <span className="text-danger">{this.state.errMsg.email}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-4">
                            <input type="tel"
                              value={this.state.phone_number}
                              size="40" class="form-control"
                              placeholder="Enter Your Number"
                              onChange={this.onChangePhone}
                            /><span className="text-danger">{this.state.errMsg.phone_number}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-4">
                            <Input
                              value={this.state.subject}
                              size="40" class="form-control"
                              placeholder="Enter Your Subject"
                              onChange={this.onChangeSubject}
                            />
                            <span className="text-danger">{this.state.errMsg.subject}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-5">
                            <textarea
                              type="textarea"
                              cols="40" rows="5"
                              class="form-control"
                              placeholder="Write Your Message"
                              value={this.state.message}
                              onChange={this.onChangeMessage}
                            ></textarea><span className="text-danger">{this.state.errMsg.message}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-4">
                          <Button color='primary' onClick={() => this.handleSubmit()}>
                            Send Message
                          </Button>
                          {/* <input type="submit"
                            value="Send Message"
                            class="btn btn-primary"
                          /> */}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-sm-4 order-sm-first">
                    <div class="soft-feature-box">
                      <div class="feature-icon">
                        <img src={Images} alt="location" class="img-svg" />
                      </div>
                      <h4>Office Address</h4>
                      <p>Titan House, Titan Road, Cardiff Wales, United Kingdom CF24 5BS</p>
                    </div>
                    <div class="soft-feature-box">
                      <div class="feature-icon">
                        <img src={Img1} alt="location" class="img-svg" />
                      </div>
                      <h4>Contact Info:</h4>
                      <p>Phone: +44(0) 7879146191<br />
                      office@genhaven.co.uk</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 tburger">
                {/* <div class="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14718.965961987584!2d75.88823949999998!3d22.7378479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1640852721246!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
                </div> */}
              </div>
            </section>
          </div>
        </div>
        {/* footer cmp */}
        <Footer />

      </div>
    )
  }
}
