import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import Header from './Header'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import {Button} from 'reactstrap'
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'
import Select from 'react-select'
import { Redirect } from 'react-router'

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            userInfo: {
                name: "",
                last_name:"",
                email: "",
                phone: "",
                state: "",
                language: "",
                image: "",
                address:"",
                streetName:"",
                streetNumber:"",
                zip:"",
            },
            phone:"",
            countryId:"",
            countries_name:[],
            countrySelected:{},
            languages:[],
            Countries: [],
            States: [],
            state_name:[],
            state: "",
            name: "",
            LastName:"",
            image: "",
            selectType: "",
            msg: "",
            errMsg: "",
            scsMsg: "",
            country_id:"",
            selectedLanguages:[],
            stateId:"",
            cities_name: [],
            city_id: "",
            shareUrl:window.location.href,
            mobileView: false
        }

        this.handlephone = this.handlephone.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.getProfileInfo()
        this.getCountries();
    }

    //form handler

    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({ userInfo });
        //console.log(userInfo)
    };

    handleCity(e) {
        this.setState({
            city_id: e
        })
    }

    getCities(state_id) {
        axios
            .post(APIURL + "cities", {
                state_id: state_id,
            })
            .then((response) => {
                let cities_name = response.data.states;
                const cityNames = [];
                for (var c = 0; c < cities_name.length; c++) {
                    cityNames.push({ value: cities_name[c].id, label: cities_name[c].name })
                }
                this.setState({
                    cities_name: cityNames,
                })
            })
    }

    //profile information
    getProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"buyer/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    let list = response.data.data.language_data;
                    // let selectedLanguages = []
                    // for (var c = 0; c < list.length; c++) {
                    //     selectedLanguages.push({ "value": list[c].value, "label": list[c].label })
                    // }
                    // this.setState({
                    //     selectedLanguages: selectedLanguages
                    // })
                    this.setState({
                        name: response.data.data.name,

                        userInfo: {
                            name: info.name,
                            last_name:info.last_name,
                            email: info.email,
                            phone: "",
                            streetNumber: info.street_number === "null" ? "" : info.street_number,
                            streetName: info.street_name  === "null" ? "" : info.street_name,
                            zip: info.zip_code  === "null" ? "" : info.zip_code,
                            // address: info.address,
                            introduction:info.introduction,
                            license_number:info.license_number
                        },
                        phone: "",
                        profile_image: response.data.data.url_path,
                        Lang_id: response.data.data.language,
                        countryId:response.data.data.country,
                        language: response.data.data.language,
                        stateId: response.data.data.state,
                        city_id: response.data.data.city
                    })
                    this.handleCountryState(this.state.countryId)
                    if(response.data.data.state > 0)
                    {
                        this.getCities(response.data.data.state)
                    }
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    //get languages
    getLanguages() {
        axios
            .get(APIURL + "languages")
            .then((response) => {
                let languages = response.data.languages;
                for (var c = 0; c < languages.length; c++) {
                    this.state.languages.push({ value: languages[c].id, label: languages[c].name })
                }
                // this.setState({
                //     languages: response.data.languages
                // })

                //console.log(this.state.languages)
            })
    }

    handleLanguages = (selectedLanguages) => {
        this.setState({ selectedLanguages });
    }

    getCountries() {
        axios
            .get(APIURL + "countries")
            .then((response) => {
                let countries_name = response.data.countries;
                const CountryNames = [];
                for (var c = 0; c < countries_name.length; c++) {
                    CountryNames.push({ value: countries_name[c].id, label: countries_name[c].name })
                }
                this.setState({
                    Countries: CountryNames,
                })
                //console.log(this.state.Countries)
            })
    }

    handleCountry(e){
        this.setState({
            countryId: e
        })
    };

    // get states
    handleCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                //console.log(response.data)
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
            .catch((error) => {
                this.setState({

                })
            });
    };

    // handle states
    handleState(e) {
        this.setState({
            stateId: e
        }, () => {
            if(e) {
                this.getCities(e)
            } else {
                this.setState({
                    cities_name: [],
                    city_id: ""
                })
            }
        })
    }

    //profile image change handler
    handleChangeLogo = (e) => {
        this.setState({
            image: e.target.files[0]
        })
        //console.log(this.state.image)
    }

    handlephone(e){
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
           this.setState({phone: e.target.value})
        }
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    //    update  profile submit handler

    onSubmitHandler = (e) => {
        var token = this.state.token

        const { userInfo ,user } = this.state;
        const formData = new FormData();
        formData.append('name', this.state.userInfo.name);
        formData.append('last_name', this.state.userInfo.last_name);
        formData.append('phone', this.state.phone);
        formData.append('email', this.state.userInfo.email);
        formData.append('state', this.state.stateId);
        formData.append('country', this.state.countryId);
        formData.append('profile_image', this.state.image);
        formData.append('language',JSON.stringify(this.state.selectedLanguages));
        formData.append('street_number', this.state.userInfo.streetNumber);
        formData.append('street_name', this.state.userInfo.streetName);
        formData.append('city', this.state.city_id);
        formData.append('zip_code', this.state.userInfo.zip);
        formData.append('license_number', this.state.userInfo.license_number);
        formData.append('introduction', this.state.userInfo.introduction);
        formData.append('id', user.id);
        formData.append('user_type', user.user_type);

        this.setState({ Loader: true });
        axios
            .post(APIURL + "buyer/update-profile", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Loader: false });
                this.setState({
                    scsMsg: response.data.message,
                });
                setTimeout(() => this.setState({ 
                    scsMsg: ""
                }), 3000)
                this.getProfileInfo()
            })
            .catch((error) => {
                //console.log("errr",error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error ,
                    Loader: false
                })
            });
    };
    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if(this.state.user.user_type !== "Buyer"){
            return <Redirect to="/permission" />;
        }
       
        return (
            <>
             <Navbar/>
                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                <div class="content-area">
                        <div class="breadcrumb-area gradient1">
                            <div class="container">
                                <h1>My Account</h1>
                                <div><small>Welcome To Your Account</small></div>
                            </div>
                        </div>
                        <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                        <div class="myaccount">
                            <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-xxl-3 col-lg-4">
                                        <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                            <div class="px-xxl-4 py-4 px-3">
                                                <Sidebar activePage="profile" data={{profile_image:this.state.profile_image}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-9 col-lg-8">
                                        <div class="bg-light">
                                            <div class="px-xxl-4 py-4 px-3">
                                                <div>
                                                    <div>
                                                        <div>
                                                          <h2 id="page_headeing"><span>Account Details </span></h2>
                                                          <hr />
                                                        </div>
                                                        <div class="submit-section">
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="mb-4">
                                                                        <label  class="form-label"> First Name <strong className="text-danger" >*</strong></label>
                                                                        <input
                                                                                className="form-control"
                                                                                required=""
                                                                                type="text"
                                                                                name="name"
                                                                                placeholder="First Name"
                                                                                value={this.state.userInfo.name}
                                                                                onChange={this.onChangehandler}
                                                                            />
                                                                           <span className="text-danger">{this.state.errMsg.name}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="mb-4">
                                                                        <label  class="form-label"> Last Name <strong className="text-danger" >*</strong></label>
                                                                        <input
                                                                                className="form-control"
                                                                                required=""
                                                                                type="text"
                                                                                name="last_name"
                                                                                placeholder="Last Name"
                                                                                value={this.state.userInfo.last_name}
                                                                                onChange={this.onChangehandler}
                                                                            />
                                                                           <span className="text-danger">{this.state.errMsg.last_name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="mb-4">

                                                                        <label  class="form-label">Email Address <strong className="text-danger" >*</strong></label>
                                                                        <input
                                                                                className="form-control"
                                                                                required=""
                                                                                disabled
                                                                                type="email"
                                                                                name="email"
                                                                                placeholder="Email Address"
                                                                                value={this.state.userInfo.email}
                                                                                onChange={this.onChangehandler}
                                                                            />
                                                                            <span className="text-danger">{this.state.errMsg.email}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="mb-4">
                                                                        <label  class="form-label">Profile Image</label>
                                                                        <input
                                                                            type="file"
                                                                            onChange={this.handleChangeLogo}
                                                                            className="form-control"
                                                                            id="fileinput"
                                                                            style={{ lineHeight:"2.1"}}

                                                                        />
                                                                        <span className="text-info small"> (Only png, jpg, jpeg format and size upto 2 MB is allowed)</span><br />
                                                                        <span className="text-danger">{this.state.errMsg.profile_image}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="mb-4">
                                                                        <label  class="form-label">Country</label>
                                                                        <select className="form-control" autoFocus={true}  value={this.state.countryId}  onChange={(e) => this.handleCountry(e.target.value)}>
                                                                            <option value="">Select Country</option>
                                                                                {this.state.Countries.map((option) => (
                                                                                    <option value={option.value}>{option.label}</option>
                                                                                ))}
                                                                        </select>
                                                                        <span className="text-danger">{this.state.errMsg.country}</span>
                                                                    </div>
                                                                </div>
                                                                {/* <div class="col-sm-6">
                                                                    <div class="mb-4">
                                                                        <label  class="form-label">State <strong className="text-danger" >*</strong></label>
                                                                        <select className="form-control" autoFocus={true} value={this.state.stateId}   onChange={(e) => this.handleState(e.target.value)} >
                                                                                <option value="" >Select State</option>
                                                                                {this.state.States.map((option) => (
                                                                                    <option value={option.value}>{option.label}</option>
                                                                                ))}
                                                                        </select>
                                                                        <span className="text-danger">{this.state.errMsg.state}</span>
                                                                    </div>
                                                                </div> */}
                                                            {/* </div>
                                                            <div class="row"> */}
                                                                {/* <div class="col-sm-6">
                                                                    <div class="mb-4">
                                                                        <label for="city" class="form-label">Local Government <strong className="text-danger" >*</strong></label>
                                                                        <select className="form-control" value={this.state.city_id} onChange={(e) => this.handleCity(e.target.value)} >
                                                                            <option value="" >Select Local Government</option>
                                                                            {this.state.cities_name.map((option) => (
                                                                                <option value={option.value}>{option.label}</option>
                                                                            ))}
                                                                        </select>
                                                                        <span className="text-danger">{this.state.errMsg.city}</span>
                                                                    </div>
                                                                </div> */}
                                                                <div class="col-sm-6">
                                                                    <div class="mb-4">
                                                                        <label  class="form-label">Zip/Post Code</label>
                                                                        <input
                                                                            className="form-control"
                                                                            required=""
                                                                            type="text"
                                                                            name="zip"
                                                                            placeholder="Zip/Post Code"
                                                                            value={this.state.userInfo.zip}
                                                                            onChange={this.onChangehandler}
                                                                        />
                                                                         <span className="text-danger">{this.state.errMsg.zip_code}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class=" col-sm-12">
                                                                    <div class="mb-4">
                                                                        <Button
                                                                        color="btn btn-primary" 
                                                            
                                                                            onClick={() => this.onSubmitHandler()}
                                                                        >Update</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
         
            </>
        )
    }
}
