import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Sidebar from './../Sidebar'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
import Navbar from './../Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import Pagination from "react-js-pagination";
import { Global, css } from "@emotion/core";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo',
        'FontColor',
        'fontBackgroundColor',
        'fontFamily',
        'imageUpload',
        '|',
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight',
    ],
    ckfinder: {
        // Upload the images to the server using the CKFinder QuickUpload command
        // You have to change this address to your server that has the ckfinder php connector
        uploadUrl: APIURL+'add-image'
    }
};


export default class PageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            pageTitle: "",
            content: "",
            errMsg: "",
            scsMsg: "",
            Loader: false,
            redirectback: false,
            activeSide: false
        }
    }
    ckWrapperStyle = css`
    position: relative;
    z-index: 1;
    &::before {
      color: rgba(251, 50, 8, 1.0);
      content: attr(data-placeholder);
      position: absolute;
      margin: var(--ck-spacing-large) 0;
      top: 0;
      z-index: -1;
    }
  `;
    componentDidMount() {
        this.getCmsListEdit()
    }

    getCmsListEdit = () => {
        var token = this.state.token
        const formData = new FormData();
        formData.append('cms_id', this.props.match.params.id);
        axios
            .post(APIURL + "admin/edit-cms-page", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                const info = response.data.data
                this.setState({
                    pageTitle: info.page_title,
                    content: info.content
                })
            })
            .catch((error) => {
                // setTimeout(() => this.setState({ errMsg: "" }), 3000);
            });
    };

    handleTitle = (e) => {
        this.setState({
            pageTitle: e
        });
    };

    handleChangeText(event) {
        this.setState({
            content: event
        })
        // console.log(this.state.text)
    }

    onSubmitHandler = (e) => {
        window.scrollTo(0, 0);
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('id', this.props.match.params.id);
        formData.append('page_title', this.state.pageTitle);
        formData.append('content', this.state.content);

        this.setState({ Loader: true });
        axios
            .post(APIURL + "admin/update-cms-page", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    scsMsg: response.data.message,
                    Loader: false
                })
                setTimeout(() => this.setState({ scsMsg: "", redirectback: true}), 3000);
            })
            .catch((error) => {
                console.log(error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
                setTimeout(() => this.setState({ errMsg: "", }), 3000);
            });
    };
    render() {
        if (this.state.redirectback) {
            return <Redirect to="/cms" push={true} />;
        }
        return (
            <div className="admin">

                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Sidebar activePage="cms/pagelist" />
                <div class="content-area">
                    <Navbar activePage="cmsEditor"/>

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>{this.state.pageTitle}</span></h2>
                                    <div class="d-flex justify-content-end align-items-center">

                                        <div class="row align-items-end">
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                                <div className="card-body">
                                    <form className="forms-sample">
                                        <div className="row">
                                            <div className="form-group col-lg-12 col-12">
                                                <label htmlFor="exampleInputName1">Page Content</label>
                                                <Global
                                                    styles={css`
                                            :root {
                                                
                                            --ck-border-radius: 4px;
                                            --ck-color-focus-border: rgba(142, 140, 140, 0.25);
                                            --ck-color-shadow-inner: rgba(142, 140, 140, 1);
                                            --ck-inner-shadow: 0 0 0 2px var(--ck-color-shadow-inner);
                                            --ck-spacing-large: var(--ck-spacing-standard);
                                            height: 500px;
                                            }
                                            .ck.ck-editor__editable_inline {
                                            border: 1px solid rgba(142, 140, 140, 0.25);
                                            transition: all 0.3s;
                                            color: rgba(19, 19, 18, 1);
                                            height: 500px;
                                            &:hover {
                                                border-color: rgba(19, 19, 18, 0.25);
                                                border-right-width: 1px !important;
                                              color: rgba(19, 19, 18, 1); !important;

                                            }
                                            }
                                            .ck-editor__editable.ck-read-only {
                                              color: rgba(19, 19, 18, 1);;
                                            opacity: 1;
                                            cursor: not-allowed;
                                             color: rgba(19, 19, 18, 1);
                                             height: 500px;
                                            &:hover {
                                                border-color: rgba(142, 140, 140, 0.25);
                                            }
                                            }
                                        `} />
                                                <CKEditor
                                                    editor= {Editor}
                                                    config= {editorConfiguration}
                                                    data= {this.state.content}
                                                    onReady={ editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        console.log( 'Editor is ready to use!', editor );
                                                    } }
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        this.handleChangeText(data)
                                                    } }
                                                    onBlur={ ( event, editor ) => {
                                                        console.log( 'Blur.', editor );
                                                    } }
                                                    onFocus={ ( event, editor ) => {
                                                        console.log( 'Focus.', editor );
                                                    } }
                                                />
                                                {/* <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Contract Title"
                                                    value={this.state.content}
                                                    onChange={(e) => this.handleChangeText(e.target.value)}
                                                /> */}
                                                
                                                <button type="button" class="mt-2 btn btn-primary" onClick={() => this.onSubmitHandler()}>Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}