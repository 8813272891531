import React, { Component } from 'react'
import logo_color from '../../assets/images/genhaven-color.webp'
import logo_white from '../../assets/images/genhaven-white.webp'
import profile from '../../assets/images/profile.webp'
import axios from 'axios';
import { APIURL, BASEURL } from '../../components/constants/common';
import { ReactComponent as Home} from '../../assets/images/home.svg'
import {ReactComponent as About} from '../../assets/images/sales.svg'
import {ReactComponent as Faq} from '../../assets/images/faq.svg'
import {ReactComponent as Contact} from '../../assets/images/envelope.svg'
import {ReactComponent as FindProperty} from '../../assets/images/find-property.svg'
import {ReactComponent as Search} from '../../assets/images/search.svg'
import {ReactComponent as Person} from '../../assets/images/person.svg'
import {ReactComponent as Heart} from '../../assets/images/heart.svg' 
    
import { Link, Redirect } from 'react-router-dom'

export default class TopNavbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: JSON.parse(localStorage.getItem("token")),
			user: JSON.parse(localStorage.getItem("userData")),
			isLoggedin: localStorage.getItem("isLoggedIn"),
			navigate: false,
			profile_image: ""
		}
	}

	onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

	// getProfileInfo() {
    //     if (this.state.user) {
    //         const formData = new FormData();
    //         // formData.append('token', this.state.token);
    //         formData.append('id', this.state.user.id);
    //         var token = this.state.token
    //         var app_url = APIURL+"buyer/edit-profile"
    //         axios
    //             .post(app_url, formData, {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`
    //                 }
    //             })
    //             .then((response) => {
    //                 this.setState({
    //                     profile_image: response.data.data.url_path
    //                 })
    //             })
    //     }
    // }

	// componentDidMount() {
    //     if(this.state.isLoggedin) {
    //     	this.getProfileInfo()
	// 	}
    // }
	render() {
		const { user_type } = this.state

        if (this.state.navigate) {
            return <Redirect to="/signin" push={true} />;
        }
		return (
			<div> 
				<nav class="navbar navbar-expand-lg navbar-light bg-light top_area">
					<div class="container">
						<Link to="/" class="navbar-brand">
							<img src={logo_color} alt="genhaven logo" className="logo_color" /> 
							<img src={logo_white} alt="genhaven logo" className="logo_white" />
						</Link>

						<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
						</button>						
						
						<div class="collapse navbar-collapse" id="navbarSupportedContent">

							<ul class="navbar-nav ms-auto mb-2 mb-l g-0">
								<li class="nav-item">
                          		<Link to="/" className={this.props.activePage === "home" ? "nav-link active" : "nav-link"} aria-current="page">
									<Home className="img-svg" alt="home" /> <span>Home</span>
                          		</Link>
                        		</li>
								<li className="nav-item">
								<Link to="/page/about-us" className={this.props.activePage === "about-us" ? "nav-link active" : "nav-link"} aria-current="page">
										<About className="img-svg" />
										<span>About Us</span>
								</Link>
								</li>
								{/*<li className={this.props.activePage === "faq" ? "nav-item active" : "nav-item"}>
									<Link to="faq"  class="nav-link">
										<Faq className="img-svg" alt="faq"  />
										<span>FAQs</span>
									</Link>
								</li>*/}
								<li className="nav-item">
									<Link to="/contact-us" className={this.props.activePage === "contact-us" ? "nav-link active" : "nav-link"}>
										<Contact className="img-svg" alt="rental"  />
										<span>Contact Us</span>
									</Link>
								</li>
								{	this.props.activePage !== "search" ?
									<li className="nav-item">
										<Link to="/search" className={this.props.activePage === "search" ? "nav-link active" : "nav-link"}>
											<FindProperty className="img-svg" width={50}  alt="find property"  />
											<span>Find Property</span>
										</Link>
									</li> :
									<li className="nav-item">
										<a href="javascript:;" class="nav-link">
											<FindProperty className="img-svg" width={50}  alt="find property" />
											<span>Find Property</span>
										</a>
									</li>
								}
							</ul>
							<div class="d-flex align-items-center utility-links ms-3">
								{/* <div class="position-relative">
									<form class="search">
										<input type="text" name="" placeholder="Search for..." class="search__field" />
										<button type="submit" class="search__icon"><Search class="img-svg" alt="Heart" /></button>
									</form>
								</div> */}
								{ this.state.user!=null &&  this.state.user.user_type=="Buyer" && 
								 	<Link to="/buyer/wishlist" class="icon"> <Heart  class="img-svg" alt="Heart" /> </Link>
							    }							    
								{
									this.state.user==null &&
									<Link to="/signin" class="icon"><Person class="img-svg" alt="user" /><span>Sign In</span> </Link>
								}
								{
									this.state.user!=null &&  this.state.user.user_type=="Admin" &&
									<div class="dropdown animate-click">
										<a class="dropdown-toggle icon" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> 
											<Person class="img-svg" alt="user" />
										</a>

										<ul class="dropdown-menu animate" aria-labelledby="dropdownMenuLink">
										    <li><Link to="/admin" class="icon">My Profile </Link></li>
											<li><a class="dropdown-item" href="javascript:;" onClick={() => this.onLogoutHandler()}>Logout</a></li>
										</ul>
									</div>
								}
								{
								  	this.state.user!=null &&  this.state.user.user_type=="Seller" &&
								  	<div class="dropdown animate-click">
										<a class="dropdown-toggle icon"  href="javascript:;"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> 
											<Person class="img-svg" alt="user" />
										</a>

										<ul class="dropdown-menu animate" aria-labelledby="dropdownMenuLink">
										    <li><Link to="/seller" class="icon">My Profile </Link></li>
											<li><a class="dropdown-item"  href="javascript:;"   onClick={() => this.onLogoutHandler()}>Logout</a></li>
										</ul>
									</div>
								}
								{
									this.state.user!=null &&  this.state.user.user_type=="Buyer" && 
									<div class="dropdown animate-click">
										<a class="dropdown-toggle icon"  href="javascript:;"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> 
											<Person class="img-svg" alt="user" />
										</a>

										<ul class="dropdown-menu animate" aria-labelledby="dropdownMenuLink">
										    <li><Link to="/buyer" class="icon">My Profile </Link></li>
											<li><a class="dropdown-item"  href="javascript:;"  onClick={() => this.onLogoutHandler()}>Logout</a></li>
										</ul>
									</div>
									
								}
								{
									this.state.user!=null &&  this.state.user.user_type=="Agent" &&
									<div class="dropdown animate-click">
										<a class="dropdown-toggle icon"  href="javascript:;"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> 
											<Person class="img-svg" alt="user" />
										</a>

										<ul class="dropdown-menu animate" aria-labelledby="dropdownMenuLink">
										    <li><Link to="/agent" class="icon">My Profile </Link></li>
											<li><a class="dropdown-item"  href="javascript:;"  onClick={() => this.onLogoutHandler()}>Logout</a></li>
										</ul>
									</div>
								}	
								{
									this.state.user!=null &&  this.state.user.user_type=="User" &&
									<div class="dropdown animate-click">
										<a class="dropdown-toggle icon"  href="javascript:;"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> 
											<Person class="img-svg" alt="user" />
										</a>

										<ul class="dropdown-menu animate" aria-labelledby="dropdownMenuLink">
										    <li><Link to="/sub-user" class="icon">My Profile </Link></li>
											<li><a class="dropdown-item"  href="javascript:;"  onClick={() => this.onLogoutHandler()}>Logout</a></li>
										</ul>
									</div>
								}								
							</div>
						</div>
					</div>
				</nav>
			</div>
		)
	}
}
