import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './../Sidebar'
import axios from "axios";
import { APIURL, SOCKETURL } from '../../../../components/constants/common';
import Navbar from './../Navbar'
import Button from '@restart/ui/esm/Button';
import {Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import Pagination from "react-js-pagination";
import face1 from '../../../../assets/images/profile-2.webp'
import send from '../../../../assets/images/send.png'
import {ReactComponent as  ChatLoader} from '../../../../assets/images/chat_loader.svg'
import { ReactComponent as User}  from '../../../../assets/images/sales.svg'
import socketClient from "socket.io-client";

export default class Enquiry extends Component {
    constructor() {
        super();
         this.chat_board_msg = React.createRef()
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            activePage: 1,
            limit: 0,
            search: "",
            buyerList:[],
            messageList:[],
            message:"",
            to:"",
            firstToUser:0,
            page:0,
            loadMoreVisiblity:false,
            cLoader:false,
            socket:"",
            IsUserDetails:false,
            userDetail:"",
            messageGetStatus:false,
            chatArea: false    
        }
        this.sendHandle = this.sendHandle.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.getBuyer();
        this.configureSocket()
    }

    chatUserView() {
        this.setState({
            chatArea: !this.state.chatArea
        }) 
    }

    configureSocket = () => {
        var socket = socketClient(SOCKETURL);
        this.setState({
            socket: socket
        })
        socket.on('message', data => {

           if(this.state.user)
            {
                if(data.to==this.state.user.id && data.from==this.state.to)
                {
                    var messageList=this.state.messageList;
                    messageList.push(data);
                    this.setMessageToLeftList(data.message,this.state.to)
                    this.setState({
                        messageList: messageList
                    },()=>{
                        this.scrollDownMessage()
                        this.updateMessage()
                    })
                }
                else if(data.to==this.state.user.id)
                {
                   this.setMessageToLeftList(data.message,data.from)
                   this.updateNotification(1,data.from)
                }
            }
        });
    }

    userDetailHandle(item){
      
         this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('id', item.id);
        axios
            .post(APIURL + "seller/get-user-detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
             console.log(response.data.data)
                this.setState({
                    userDetail: response.data.data,
                    Loader:false
                },()=>{
                    this.ModalClose() 
                    
                })


            })
            .catch((error) => {
                this.setState({
                    Loader:false
                })
            });
  
    }

    ModalClose(){
        this.setState({
        IsUserDetails:!this.state.IsUserDetails 
       })
    }

    sendHandle(){

        if(this.state.message.trim()=="")
        {
          return false  
        }

         this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);
        formData.append('message', this.state.message);
        formData.append('property_id','');
        this.setMessageToLeftList(this.state.message,this.state.to)
        axios
            .post(APIURL + "seller/send-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList;
                var message=this.state.message;
                messageList.push(response.data.data);

                var socket = this.state.socket;
                socket.emit('message',response.data.data);

                this.setState({
                    messageList: messageList,
                    message:"",
                    Loader:false
                },()=>{
                    this.scrollDownMessage() 
                    
                })


            })
            .catch((error) => {
                this.setState({
                    Loader:false
                })
            });
    }

    getBuyer() {
        this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('search', this.state.search);

        axios
            .post(APIURL + "seller/get-user-buyer", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                 this.setState({
                    buyerList: response.data.data.data
                })
                var firstToUser=this.state.firstToUser;
                if(firstToUser==0 &&  response.data.data.data.length>0)
                {
                    this.setState({
                        to: response.data.data.data[0].id,
                         messageGetStatus:true,
                    },()=>{
                        this.setNotificationzero()
                        this.updateMessage()
                        this.getMessage()
                        
                    })
                }
                firstToUser++;

                this.setState({
                    norecord:true,
                    Loader:false,
                     messageGetStatus:true,
                    firstToUser:firstToUser
                })
            })
            .catch((error) => {
                this.setState({
                    norecord:true,
                    Loader:false,
                    messageGetStatus:true,
                })
            });
    }

    getMessage() {
        this.setState({
            cLoader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);
        formData.append('page', this.state.page);

        axios
            .post(APIURL + "seller/get-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList
                messageList=[...response.data.data,...messageList];
                var loadMoreVisiblity=false;
                if(response.data.data.length>0){
                  loadMoreVisiblity=true
                }
                
                this.setState({
                    messageList: messageList,
                    cLoader:false,
                    loadMoreVisiblity:loadMoreVisiblity
                },()=>{
                    if(this.state.page==0){
                    this.scrollDownMessage()
                   }
                })

            })
            .catch((error) => {
                this.setState({
                    cLoader:false
                })
            });
    }

    setTo(item){
      
        this.setState({
            to: item.id,
            page:0,
            messageList:[]
        },()=>{
            this.getMessage()
            this.updateMessage()
            this.setNotificationzero()
        })
    }

    messageHandle(val){
      this.setState({
            message: val
        })
    }

   _handleKeyDown(e) {

        if (e.key === 'Enter') {
          this.sendHandle();
        }
      }

    scrollDownMessage(){
         this.chat_board_msg.current.scrollTop =  this.chat_board_msg.current.scrollHeight;
    }

    seacrhHandle(e) {
        this.setState({
            search: e
       },()=>{this.getBuyer()})
    }


    updateMessage() {
       
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);

        axios
            .post(APIURL + "seller/update-message-status", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
            })
            .catch((error) => {
               
            });
    }

    updateNotification(noti,to){
        var buyerList=this.state.buyerList
        buyerList.filter(person => person.id==to).map(result => {
            result.unreadCount+=noti;
        })
        this.setState({
            buyerList: buyerList,
        })
    }

    setNotificationzero(){
        var buyerList=this.state.buyerList
        buyerList.filter(person => person.id==this.state.to).map(result => {
            result.unreadCount=0;
            })
    }


    setMessageToLeftList(message,to){
        var buyerList=this.state.buyerList
        var messageBuyer=buyerList.filter(person => person.id==to).map(result => {
            result.message=message;
            })
        messageBuyer=buyerList.filter(person => person.id==to);
        var newBuyerList=buyerList.filter(person => person.id!=to);
        newBuyerList=[...messageBuyer,...newBuyerList];
        this.setState({
            buyerList: newBuyerList
        })
    }

    loadMore(){
        var page=this.state.page;
        page+=1;

    this.setState({
            page: page,
        },()=>{
            this.getMessage()
        })
    }


    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
            <div className="admin">
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Sidebar activePage="buyer_chat" />
                <div className="content-area">
                    <Navbar />                  
                    <div className={this.state.chatArea ? "chat_area open" : "chat_area"}>
                        <div className="chat_list">
                            <div className="chat-search">
                                <input type="text" placeholder="Search" onChange={(e) => this.seacrhHandle(e.target.value)} />
                            </div>
                            <ul>
                                {this.state.buyerList.length > 0 && this.state.buyerList.map((item, idx) => (

                                    <li>
                                        <div class={`chatprofile ${this.state.to==item.id?"active":""}`}>
                                            <div>
                                                <div class="chatprofile_img" onClick={() => this.setTo(item)}>
                                                      { item.profile_path == "" || item.plan=="premium" ?
                                                         <img src={face1} alt="user" />
                                                         :
                                                         <img src={item.profile_path} alt="user" />
                                                        }
                                                </div>
                                            </div>
                                            <div class="chatprofile_nm">
                                                {item.plan=="premium"?
                                                 <h6 onClick={() => this.setTo(item)}>Anonymous</h6>
                                                :<h6 onClick={() => this.setTo(item)}>{item.fullName}</h6>
                                               }
                                                
                                                <p>{item.message}</p>
                                               
                                            </div>
                                            {item.plan!="premium" &&
                                            <span className="input-visiblity"> <i onClick={() => this.userDetailHandle(item)} className="fas fa-eye"></i> </span>
                                            }
                                            { item.unreadCount>0 && 
                                            <div class="indicator">{item.unreadCount}</div>
                                           }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                         { this.state.cLoader===true &&
                           <div className="chat_loader"><ChatLoader class="img-svg" /></div>
                         }
                        <div className="chat_board">
                            <div className="chat_list_users" onClick={()=>this.chatUserView()}><User class="img-svg" alt="User" /></div>
                            <div className="chat_board_msg" ref={this.chat_board_msg}>
                             { this.state.loadMoreVisiblity==true && this.state.cLoader===false && this.state.messageList.length > 9 && 
                                <div className="chat_load_more">
                                <Button className="btn-primary btn btn-sm" onClick={() => this.loadMore()} > Load More</Button>
                                </div>
                             }
                              {this.state.messageList.length > 0 && this.state.messageList.map((item, idx) => (
                                <React.Fragment>
                                {item.to!=this.state.to &&
                                    <div className="bubble bubbleIn">
                                        <p>{item.message}</p>
                                        <span className="date">{item.date} {item.timestamp}</span>
                                    </div>
                                }
                                {item.to==this.state.to &&
                                    <div className="bubble bubbleOut">
                                        <p>{item.message}</p>
                                      
                                        <span className="date">{item.date} {item.timestamp}</span>
                                    </div>
                                }
                               
                                 </React.Fragment>
                                
                              ))}
                               {this.state.to == 0 && this.state.messageGetStatus==true &&
                                  <div className="bubble no-message">No Message from buyer  </div>
                               }
                            </div>

                           {this.state.to > 0 &&

                            <div className="chat-input">
                                <input type="text" placeholder="Type a message" value={this.state.message}  onKeyDown={this._handleKeyDown} onChange={(e) => this.messageHandle(e.target.value)} />
                                <Button><img src={send} alt="send" onClick={() => this.sendHandle()} /></Button>
                            </div>
                           }
                        </div>
                    </div>                    
                </div>

                 <Modal size="md" isOpen={this.state.IsUserDetails} toggle={() => this.ModalClose()} autoFocus={false}>
                    <ModalHeader toggle={() => this.ModalClose()}>Buyer Details</ModalHeader>
                    <ModalBody>
                     { this.state.userDetail!="" &&
                        <React.Fragment>
                        <div className="mb-4">
                            <table class="table table-striped">
                                <tbody>
                                   <tr>
                                       <th>Name</th>
                                       <td>{this.state.userDetail.fullName}</td>
                                   </tr>
                                    <tr>
                                       <th style={{minWidth:130}}>Email</th>
                                       <td>{this.state.userDetail.email}</td>
                                   </tr>
                                    <tr>
                                       <th>Country</th>
                                       <td>{this.state.userDetail.country_name}</td>
                                   </tr>                                  
                                </tbody>
                            </table>
                        </div>
                           
                           
                        </React.Fragment>
                    }
                       
                    </ModalBody>
                    <ModalFooter>
                        <div>
                             <button className="btn btn-light me-2" onClick={() => this.ModalClose()}> Close </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}