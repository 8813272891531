import React, { Component } from 'react'

import genhavenWhite from '../../assets/images/genhaven-white.webp'

import Navbar from '../Front/TopNavbar'
import Footer from '../Front/Footer'
import Banner from './Banner'
import HowItWork from './HowItWork'
import GoodProperty from './GoodProperty'
import Packages from './Packages'
import ReviewsByCustomers from './ReviewsByCustomers'
import MoreFeature from './MoreFeature'


export default class Home extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true
		}
	}
	componentDidMount(){
          
	}
	render() {
		console.log(this.props)
		return (
		<div className="home">	
		
				{/* Navbar cmp */}
				<Navbar activePage="home" />
				{/* header cmp */}

				<Banner />
	            <div class="content-area">
				 <HowItWork />
				 <GoodProperty />
				 <ReviewsByCustomers />
				 <Packages />
				 {/* <MoreFeature /> */}
				
                </div>
				
				{/* footer cmp */}
				<Footer />

		</div>	
		)
	}
}
