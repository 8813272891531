import React, { Component } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import axios from "axios";
import { APIURL, PAYPAL_CLIENT_ID } from '../../../../components/constants/common';
import { Modal, ModalBody, ModalHeader, ModalFooter, Spinner, TabContent, TabPane, Nav, NavItem, NavLink, Button, Input } from 'reactstrap'
import classnames from 'classnames';
import PropertyInfo from './PropertyInfo'
import Address from './Address'
import Features from './Features'
import Gallery from './Gallery';
import { Redirect } from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { Helmet } from "react-helmet";
export default class AddProperty extends Component {
    constructor() {
        super();

        this.state = {
            // property_id: JSON.parse(localStorage.getItem("property_id")),
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            local_p_id: "",
            isLoading: false,
            isPublishLoading: false,
            activeTab: '1',
            p_id: "",
            scsMsg: "",
            errMsg: "",
            assigned: false,
            reviewCancel: false,
            PropertyList:null,
            resend_id: "",
            disableRequestButton: false,
            disablePublishRequestButton: false,
            stickyheader: false,
            property_request_status: "",
            propertyDetailsStatus: false,
            priceModal: false,
            haveVerificationCode: false,
            verificationCode: "",
            checked: false,
            requestPriceDetails: [],
            amount: 0,
            showSuccessMsg: false,
            actualAmount: 0,
            disableSendButton: false,
            validationerrMsg: "",
            paymentModal: false,
            check_payment_mode: ""
        }
    }

    paymentMode(payment_mode) {
        this.setState({
            check_payment_mode: payment_mode 
        })
    }

    paymentModal() {
        this.setState({
            paymentModal: !this.state.paymentModal,
            priceModal: !this.state.priceModal,
        })
    }

    paymentModalClose() {
        this.setState({
            paymentModal: false
        })
    }

    handleVerificationCode = (e) => {
        this.setState({
            verificationCode: e
        })
    }

    handleVerificationCodeCheck = (e) => { 
        console.log(e);    
        if(this.state.checked == false)
        {
            this.setState({
                verificationCode:"",
            })
        } 
        if (e) {
            this.setState({
                disableSendButton: true,
            })
        } else {
            this.setState({
                amount: this.state.actualAmount,
                disableSendButton: false,
                showSuccessMsg: false
            })
        }

        this.setState({
            haveVerificationCode: e,
            checked: !this.state.checked
        })       
    }

    handleActiveTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    priceModal() {
        this.setState({
            priceModal: !this.state.priceModal,
            //adminComment: adminComment
        })
    }

    priceModalClose() {
        this.setState({
            validationerrMsg: "",
            priceModal: false,
            verificationCode: "",
            checked: false,
            haveVerificationCode: false,
            amount: this.state.requestPriceDetails.amount,
            showSuccessMsg: false,
            disableSendButton: false
        })
    }

    setMessage(message) {
        this.setState({
            scsMsg: message
        }) 
    }

    getPropertyId(e) {
        this.setState({
            p_id: e
        })
    }

    getStatus(status) {
        this.setState({
            property_request_status: status
        }) 
    }

    // getPropertyInfo() {
    //     if (this.state.user) {
    //         const formData = new FormData();
    //         formData.append('property_id', this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id);
    //         var token = this.state.token

    //         axios
    //             .post(APIURL + "seller/property-address-edit", formData, {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`
    //                 }
    //             })
    //             .then((response) => {
    //                 console.log(response);
    //                 // const info = response.data.data;
    //                 // this.setState({
    //                 //     name: response.data.data.name,
    //                 //     userInfo: {
    //                 //         name: info.name,
    //                 //         email: info.email,
    //                 //         phone: info.phone,
    //                 //         address: info.address,
    //                 //         introduction: info.introduction,
    //                 //         license_number: info.license_number
    //                 //     },
    //                 //     profile_image: response.data.data.profile_image,
    //                 //     // email: response.data.data.email,
    //                 //     Lang_id: response.data.data.language,
    //                 //     countryId: response.data.data.country,
    //                 //     language: response.data.data.language,
    //                 //     state: response.data.data.state
    //                 // })
    //                 // this.handleCountryState(this.state.countryId)
    //                 // console.log("sssssssss", this.state.language)
    //             })
    //             .catch((error) => {
    //                 this.setState({
    //                     // errMsg: error.response.data.errors,
    //                     Loader: false
    //                 })
    //             });
    //     }
    // }

    checkVerificationCodeStatus() {
        var token = this.state.token     
        const formData = new FormData();
        formData.append('coupon_code', this.state.verificationCode ? this.state.verificationCode : "");

        this.setState({ Loader: true });
        if(this.state.haveVerificationCode)
            axios
                .post(APIURL + "buyer/check-coupon-code-used-or-not", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        amount: response.data.price,
                        disableSendButton: false,
                        showSuccessMsg: true
                    }, () => {
                        setTimeout(() => this.setState({ 
                            showSuccessMsg: false
                        }), 4000);
                    });
                })
                .catch((error) => {
                    
                    this.setState({
                        Loader: false,
                        validationerrMsg: error.response.data.error
                    });
                    setTimeout(() => this.setState({ validationerrMsg: "", }), 4000); 
                })
        else{
            this.verificationRequest(); 
        }
    }

    goToPropertyList() {
        this.setState({
            reviewCancel: true
        })
    }

    // getPropertyList() {
    //     var token = this.state.token
    //     const formData = new FormData();
    //     formData.append('property_id', this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id);

    //     axios
    //         .get(APIURL + "seller/resend-property-edit", {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         })
    //         .then((response) => {
    //             this.setState({
    //                 PropertyList: response.data.data
    //             })
    //         })
    // }

    // getPropertyStatus() {
    //     var token = this.state.token
    //     const formData = new FormData();
    //     formData.append('property_id', this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id);

    //     axios
    //         .post(APIURL + "seller/get-property-list", formData, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         })
    //         .then((response) => {
    //             //console.log("seller/get-properties", response.data.data)
    //             this.setState({
    //                 propertyStatus: response.data.data.status
    //             })
    //         })
    // }

    getRequestPrice() {
        const formData = new FormData();
        formData.append('user_type', 'Seller');
        formData.append('plan_type', 'request');

        axios
        .post(APIURL + "get-subscription-plan", formData)
        .then((response) => {
            this.setState({
				requestPriceDetails: response.data.data,
                amount: response.data.data.amount,
                actualAmount: response.data.data.amount
			})
        })
    }

    checkPropertyDetails() {
        const { user } = this.state
        var token = this.state.token;
        const formData = new FormData();
        formData.append('property_id', this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id);
        
        const option = {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        axios
            .post(APIURL + "seller/check-property-data-sufficiency", formData, option)
            .then(result => {
                this.setState({
                    propertyDetailsStatus: result.data.data
                })
                setTimeout(() => this.setState({ scsMsg: "", }), 4000);
            })
            .catch(error => {
                console.log("err", error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({ errMsg: "" }), 4000);
            }
            );
    }


    listenScrollEvent = e => {
        if (window.scrollY > 50) {
          this.setState({
            stickyheader:true
            })
        } else {
            this.setState({
                stickyheader:false
              })
        }
    }

    verificationRequest() {
        const { user } = this.state
        var token = this.state.token;
        const formData = new FormData();
        this.setState({ isLoading: true });
        formData.append('property_id', this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id);
        formData.append('coupon_code', this.state.verificationCode ? this.state.verificationCode : "");
        formData.append('amount', this.state.amount);
        formData.append('payment_mode', "Paid");
        const option = {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        if(this.state.propertyDetailsStatus && (this.state.property_request_status !== "reject" || this.state.check_payment_mode === "Unpaid")) {
            axios
            .post(APIURL + "seller/property-request-send-by-seller", formData, option)
            .then(result => {
                this.setState({
                    paymentModal: false,
                    isLoading: false,
                    priceModal: false,
                    scsMsg: result.data.message,
                    //disableRequestButton: true
                })
                setTimeout(() => this.setState({ reviewCancel: true, }), 2000);
                setTimeout(() => this.setState({ scsMsg: "", }), 4000);
                
                //console.log(result);
            })
            .catch(error => {
                console.log("err", error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({ errMsg: "" }), 4000);
            }
            );
        } else {
            axios
            .post(APIURL + "seller/resend-property-request-by-seller", formData, option)
            .then(result => {
                this.setState({
                    isLoading: false,
                    scsMsg: result.data.message,
                    //disableRequestButton: true,
                })
                setTimeout(() => this.setState({ reviewCancel: true, }), 2000);
                setTimeout(() => this.setState({ scsMsg: "", }), 3000);
            })
            .catch(error => {
                this.setState({
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({ errMsg: "" }), 3000);
            }
            );
        }
    }

    sendPublishRequest() {
        const { user } = this.state
        var token = this.state.token;
        const formData = new FormData();
        this.setState({ isPublishLoading: true });
        formData.append('property_id', this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id);
        formData.append('coupon_code', "");
        formData.append('amount', 0);
        formData.append('payment_mode', "Unpaid");
        const option = {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        if(this.state.propertyDetailsStatus && (this.state.property_request_status !== "reject" && this.state.property_request_status !== "verify")) {
            axios
            .post(APIURL + "seller/property-request-send-by-seller", formData, option)
            .then(result => {
                this.setState({
                    isPublishLoading: false,
                    scsMsg: result.data.message,
                    //disableRequestButton: true
                })
                setTimeout(() => this.setState({ reviewCancel: true, }), 2000);
                setTimeout(() => this.setState({ scsMsg: "", }), 4000);
                
                //console.log(result);
            })
            .catch(error => {
                console.log("err", error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({ errMsg: "" }), 4000);
            }
            );
        } else {
            axios
            .post(APIURL + "seller/resend-property-request-by-seller", formData, option)
            .then(result => {
                this.setState({
                    isPublishLoading: false,
                    scsMsg: result.data.message,
                    //disableRequestButton: true,
                })
                setTimeout(() => this.setState({ reviewCancel: true, }), 2000);
                setTimeout(() => this.setState({ scsMsg: "", }), 3000);
            })
            .catch(error => {
                this.setState({
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({ errMsg: "" }), 3000);
            }
            );
        }
    }

    componentDidMount() {
        //this.getPropertyList()
        //this.getPropertyStatus();
        this.checkPropertyDetails();
        this.getRequestPrice()
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    render() {
        const property_id = this.props.match.params.id
        console.log(this.state.property_request_status)
        if (this.state.reviewCancel) {
            return <Redirect to="/seller/property" push={true} />;
        }

        return (
            <div className="admin">
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}
                <Sidebar activePage="property-listing" />
                <div class="content-area">
                <Navbar activePage="edit-property"/>   
                <div class="content">
                    <div className="mb-4 d-flex justify-content-start">
                        {   
                            this.state.propertyDetailsStatus && this.state.property_request_status === null
                            ?
                            <React.Fragment>
                                <Button onClick={() => this.priceModal()} color="info" className="px-3 btn-sm" disabled={this.state.disableRequestButton?true:false}>Send Verification Request 
                                {this.state.isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-2 ms-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    ) : (
                                    <span></span>
                                )}</Button>
                                <Button onClick={() => this.sendPublishRequest()} color="info" className="px-3 btn-sm ms-2" disabled={this.state.disablePublishRequestButton?true:false}>Send Publish Request 
                                {this.state.isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-2 ms-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    ) : (
                                    <span></span>
                                )}</Button>
                            </React.Fragment>
                            : (this.state.property_request_status === "reject" && this.state.check_payment_mode === "Unpaid") ? 
                                <Button onClick={() => this.sendPublishRequest()} color="info" className="px-3 btn-sm ms-2" disabled={this.state.disablePublishRequestButton?true:false}>Send Publish Request 
                                {this.state.isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-2 ms-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    ) : (
                                    <span></span>
                                )}</Button>
                            : (this.state.property_request_status === "reject" && this.state.check_payment_mode === "Paid") ? <Button onClick={() => this.priceModal()} color="info" className="px-3 btn-sm" disabled={this.state.disableRequestButton?true:false}>Send Verification Request 
                                {this.state.isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-2 ms-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    ) : (
                                    <span></span>
                                )}</Button>
                            : (this.state.property_request_status === "verify" && this.state.check_payment_mode === "Unpaid") ? <Button onClick={() => this.priceModal()} color="info" className="px-3 btn-sm" disabled={this.state.disableRequestButton?true:false}>Send Verification Request 
                                {this.state.isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-2 ms-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    ) : (
                                    <span></span>
                                )}</Button>
                            :""
                        }
                        {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                        {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                    </div>
                    
                    <div class="text-center">
                        {/* <ul class="nav justify-content-center tablist mb-0 mx-auto" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="info-tab" data-bs-toggle="pill" data-bs-target="#pills-info" type="button" role="tab" aria-controls="pills-info" aria-selected="true">Property Info</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="address-tab" data-bs-toggle="pill" data-bs-target="#pills-address" type="button" role="tab" aria-controls="pills-address" aria-selected="false">Address</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="features-tab" data-bs-toggle="pill" data-bs-target="#pills-features" type="button" role="tab" aria-controls="pills-features" aria-selected="false">Features</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="gallery-tab" data-bs-toggle="pill" data-bs-target="#pills-gallery" type="button" role="tab" aria-controls="pills-gallery" aria-selected="false">Gallery</a>
                        </li>
                        </ul> */}
                        <Nav tabs style={{ padding: "0px" }} className='nav justify-content-center tablist mb-0 mx-auto'>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: this.state.activeTab === '1' })} 
                                    onClick={() => { this.handleActiveTab('1'); }}
                                >
                                    Property Info
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.handleActiveTab('2'); }}
                                >
                                    Address
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.handleActiveTab('3'); }}
                                >
                                    Features
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink
                                    style={{ color: "black", cursor: "pointer", }}
                                    className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => { this.handleActiveTab('4'); }}
                                >
                                    Map
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => { this.handleActiveTab('4'); }}
                                >
                                    Gallery
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div class="card">
                        <div class="card-body">
                        <div class="tab-content" id="pills-tabContent">
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <PropertyInfo
                                        data={{
                                            property_id: this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id,
                                            handleActiveTab: this.handleActiveTab.bind(this),
                                            // handleCompleteTab: this.handleCompleteTab.bind(this)
                                            activatedTab: "propertyInfo",
                                            paymentMode: this.paymentMode.bind(this),
                                            checkPropertyDetails: this.checkPropertyDetails.bind(this),
                                            getStatus: this.getStatus.bind(this),
                                            setMessage: this.setMessage.bind(this)
                                        }}
                                    />
                                </TabPane>
                                <TabPane tabId="2">
                                    <Address
                                        data={{
                                            property_id: this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id,
                                            handleActiveTab: this.handleActiveTab.bind(this),
                                            // handleCompleteTab: this.handleCompleteTab.bind(this)
                                            activatedTab: "address",
                                            checkPropertyDetails: this.checkPropertyDetails.bind(this),
                                            getStatus: this.getStatus.bind(this),
                                            setMessage: this.setMessage.bind(this)
                                        }} />
                                </TabPane>
                                <TabPane tabId="3">
                                    <Features
                                        data={{
                                            property_id: this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id,
                                            handleActiveTab: this.handleActiveTab.bind(this),
                                            // handleCompleteTab: this.handleCompleteTab.bind(this)
                                            activatedTab: "features",
                                            checkPropertyDetails: this.checkPropertyDetails.bind(this),
                                            getStatus: this.getStatus.bind(this),
                                            setMessage: this.setMessage.bind(this)
                                        }} />
                                </TabPane>
                                {/* <TabPane tabId="4">
                                    <PropertyMap
                                        data={{
                                            property_id: this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id,
                                            handleActiveTab: this.handleActiveTab.bind(this),
                                            // handleCompleteTab: this.handleCompleteTab.bind(this)
                                        }} />
                                </TabPane> */}
                                <TabPane tabId="4">
                                    <Gallery
                                        data={{
                                            property_id: this.props.match.params.id ? this.props.match.params.id : this.state.local_p_id,
                                            handleActiveTab: this.handleActiveTab.bind(this),
                                            checkPropertyDetails: this.checkPropertyDetails.bind(this),
                                            // handleCompleteTab: this.handleCompleteTab.bind(this)
                                            activatedTab: "gallery",
                                            getStatus: this.getStatus.bind(this),
                                            setMessage: this.setMessage.bind(this)
                                        }} />
                                </TabPane>
                            </TabContent>
                        </div>
                        </div>
                    </div>
                </div>
                </div>

                <Modal size="md" isOpen={this.state.priceModal} toggle={() => this.priceModalClose()}>
                    <ModalHeader className="" toggle={() => this.priceModalClose()}>
                        Sent Verification Request <br />
                        <span className="text-danger">{this.state.errMsgCmt}</span>
                    </ModalHeader>
                    <ModalBody className="border-0">
                    {this.state.validationerrMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.validationerrMsg.message}   </div> : ""}
                        <div className="sidebar__varification">
                            <form>
                                <div className="packages__rate">
                                    <label>Verification Price</label>
                                    <h5>£{this.state.amount}</h5>
                                </div>
                                {/* { this.state.user && this.state.user.subscriptionPlanDetail && this.state.user.subscriptionPlanDetail.plan_name!="Basic Plan" && */}
                                    <div className="mb-0">
                                        <label className="customcheck">Do you have Transferrable Verification Code or Coupon Code
                                            <Input type="checkbox"checked={this.state.checked} onChange={(e) => this.handleVerificationCodeCheck(e.target.checked)} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                {/* } */}
                                {  
                                    this.state.haveVerificationCode &&
                                    <div>
                                        <div className="coupon_code_cnt">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Code"
                                                value = {this.state.verificationCode}
                                                onChange={(e) => this.handleVerificationCode(e.target.value)}
                                            />
                                            <Button className="mb-2 btn btn-secondary" onClick={(e) => this.checkVerificationCodeStatus()}>Apply Code</Button>
                                        </div>
                                        {
                                            this.state.showSuccessMsg && 
                                            <span className="text-success">Verification Code applied successfully</span>
                                        }
                                    </div>
                                }                             
                            </form>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button type="button" className="btn btn-primary me-1" onClick={() => this.paymentModal()} disabled={this.state.disableSendButton?true:false}>Submit</button>
                            <button type="button" className="btn btn-light" onClick={() => this.priceModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="md" isOpen={this.state.paymentModal} toggle={() => this.paymentModalClose()}>
                    <ModalHeader className="" toggle={() => this.paymentModalClose()}>
                        Choose Payment Option <br />
                        <span className="text-danger">{this.state.errMsgCmt}</span>
                    </ModalHeader>
                    <ModalBody className="border-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID,}}>
                                    <PayPalButtons
                                        //fundingSource="paypal"
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: this.state.amount,
                                                        }
                                                    },
                                                ],
                                                application_context: {
                                                    shipping_preference: 'NO_SHIPPING'
                                                }
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then((details) => {
                                                const name = details.payer.name.given_name;
                                                {
                                                    details.status === "COMPLETED" &&
                                                    this.verificationRequest()
                                                }
                                            });
                                        }}
                                    />
                                </PayPalScriptProvider>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button type="button" className="btn btn-light" onClick={() => this.paymentModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
