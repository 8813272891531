import React, { Component } from 'react'
import logo from '../../../assets/img/logo.png'
import search from '../../../assets/images/search.svg'
import profile from '../../../assets/images/profile.webp'
import agent from '../../../assets/images/agent.svg'
import {ReactComponent as Back} from '../../../assets/images/back.svg'
import bell from '../../../assets/images/bell.svg'
import addPoperty from '../../../assets/images/plus-circle-fill.svg'
import logo_color from '../../../assets/images/genhaven-color.webp'

import { Redirect ,Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar'
import { APIURL, BASEURL } from '../../../components/constants/common';

export default class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            profile_image:"",
            name:"",
            openSidebar:false,
            NoImage: false
        }
    }

    componentDidMount() {
        this.setState({
            openSidebar: false
        })
        this.getProfileInfo()
    }

    getProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"seller/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        profile_image: response.data.data.url_path,
                        name:response.data.data.name,
                        NoImage: true
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    onHover = () =>  
        this.setState({ 
            showIcons: true 
        });
    
    onNotHover = () =>  
        this.setState({ 
            showIcons: false 
        });

    Sidebar = (event) => {
        this.setState({
            openSidebar: !this.state.openSidebar
        })
        if(this.state.openSidebar){
            document.body.classList.remove('sidebar-icon-only');
        }
        if(!this.state.openSidebar){
            document.body.classList.add('sidebar-icon-only');
        }
    }
    goToHome(){
        window.location.href="/";
    }

    render() {
        const {openSidebar ,closeSidebar } = this.state 
        const { user ,name } = this.state
        
        if (!this.state.user_type === "seller") {
            return <Redirect to="/" push={true} />;
        }

        if (!this.state.user) {
            return <Redirect to="/" push={true} />;
        }

        if (this.state.navigate) {
            return <Redirect to="/" push={true} />;
        }
        return (
            <>
            
             <header>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                        <div class="brand-logo_mobile">
                            <a href="#" className="me-2">
                                {/* <Logo onClick={() =>this.goToHome()} class="img-svg" alt="logo" /> */}
                                <img src={logo_color} alt="genhaven logo" class="img-svg"/>
                            </a>
                        </div>
                        <div class="position-relative">
                            <form class="search">
                                {/*<input type="text" name="" placeholder="Search for..." class="search__field to_right" />
                                <button type="submit" class="search__icon"><img src={search} class="img-svg" alt="Heart" /></button>
                                */}
                            </form>
                            {
                                this.props.activePage === "edit-property" &&
                                <Link to={"/seller/property"} className="btn btn-sm btn-info"><Back class="img-svg" /></Link> 
                            }
                        </div>
                    </div>
                    
                    <div>
                      <div class="d-flex justify-content-between align-items-center ml-3 additional-link">
                        <div>
                          <a href="#"  class="notification">
                            {/* <img src={bell} class="img-svg" alt="search" />
                            <span></span> */}
                          </a>
                        </div>
                        <div>
                          <div class="dropdown animate-click">
                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> 
                                {   this.state.NoImage && this.props.data !== undefined && this.props.data.profile_image !== ""
                                    ? <span class="img"><img  src={this.props.data.profile_image} class="img-svg" alt="profile" /></span> 
                                    : this.state.NoImage && <span class="img"><img  src={this.state.profile_image ? this.state.profile_image : agent} class="img-svg" alt="profile" /></span> 
                                }
                                <span class="text">{this.state.name}</span>
                            </a>

                            <ul class="dropdown-menu animate" aria-labelledby="dropdownMenuLink">
                                <li><a class="dropdown-item" href="#" onClick={() => this.onLogoutHandler()}>Logout</a></li>
                            </ul>
                          </div>
                        </div>
                    </div>
                    </div>
                </div>
                </header>
                
            </>
        )
    }
}
