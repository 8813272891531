import React, { Component } from 'react'
import face1 from '../../../assets/images/profile-2.webp'
import { ReactComponent as Logo} from '../../../assets/images/logo-icon.svg'
import logo_color from '../../../assets/images/genhaven-color.webp'
import  { ReactComponent as agent} from '../../../assets/images/agent.svg'
import { ReactComponent as Lockaccess} from '../../../assets/images/lock-access.svg'
import { ReactComponent as Radiocircle}  from '../../../assets/images/radio-circle.svg'
import { ReactComponent as List} from '../../../assets/images/list.svg'
import { ReactComponent as Buildingcommunity}  from '../../../assets/images/building-community.svg'
import { ReactComponent as Invited}  from '../../../assets/images/person-plus.svg'
import { ReactComponent as Package} from '../../../assets/images/package.svg'
import { ReactComponent as Person} from '../../../assets/images/person.svg'
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'

import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import { Tooltip } from 'reactstrap';
import { APIURL, BASEURL, SOCKETURL } from '../../../components/constants/common';
import socketClient from "socket.io-client";

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // stylePath:true,
            sidebarClass: "sidebar-icon-only",
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            profile_image: "",
            dashboard: false,
            myProfile: false,
            property: false,
            enquiry: false,
            agentreq: false,
            changePass: false,
            reload:false,
            NoImage:false,
            subscribedPlans: {},
            errMsg: "",
            scsMsg: "",
            mobileView: false,
            buyerChatNotification:0
            // user:false
        }
    }
    
    getProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"seller/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        profile_image: response.data.data.url_path,
                        name: response.data.data.name,
                        NoImage:true
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    getUserSubscribedPlans() {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"get-user-subscription-plan"
            const formData = new FormData();
            formData.append('user_id', this.state.user.id);
           
            axios
                .post(app_url,formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        subscribedPlans: response.data != null ? response.data.data : ""
                    })
                })
                .catch((error) => {
                    this.setState({
                        //errMsg: error.response.data.error
                    })
                });
        }
    }


    getBuyerChatCount() {
        if (this.state.user) {
            const formData = new FormData();
            var token = this.state.token
            var app_url = APIURL+"seller/get-buyer-unread-message-count"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                        this.setState({
                            buyerChatNotification: response.data.data
                        })
                })
                .catch((error) => {
                  
                });
        }
    }

    configureSocket = () => {
        var socket = socketClient(SOCKETURL);
        
        this.setState({
            socket: socket
        })
        socket.on('message', data => {

            if(data.to==this.state.user.id && this.props.activePage !== "buyer_chat")
            {
                 var buyerChatNotification=this.state.buyerChatNotification
                    buyerChatNotification+=1;
                    this.setState({
                        buyerChatNotification: buyerChatNotification
                    })
            }
        });

    }


    dashboard = () => { this.setState({ dashboard: !this.state.dashboard }) }
    myProfile = () => { this.setState({ myProfile: !this.state.myProfile }) }
    property = () => { this.setState({ property: !this.state.property }) }
    enquiry = () => { this.setState({ enquiry: !this.state.enquiry }) }
    agentreq = () => { this.setState({ agentreq: !this.state.agentreq }) }
    changePass = () => { this.setState({ changePass: !this.state.changePass }) }

    componentDidMount() {
        this.getProfileInfo()
        this.getUserSubscribedPlans()
        this.getBuyerChatCount()
        this.configureSocket()
    }
    
    goToHome(){
        window.location.href="/";
    }
    
    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}
    //  componentWillMount() {
     
    //     this.getUserSubscribedPlans()
    // }

    render() {
        const { user, name } = this.state
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "Seller") {
            return <Redirect to="/permission" />;
        }
        console.log(this.props.data)
        return (
            <>
            <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
            <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
               <div className={this.state.mobileView ? "admin-sidebar sidebar_mobile open" : "admin-sidebar sidebar_mobile"}>
                    <div class="brand-logo">
                        <a href="#">
                            {/* <Logo onClick={() =>this.goToHome()} class="img-svg" alt="logo" /> */}
                            <img src={logo_color} alt="genhaven logo" class="img-svg"/>
                        </a>
                    </div>

                    <div class="content">
                        <div>
                            <a href="#" class="d-block">
                                <div class="navprofile d-flex align-items-center">
                                    <div class="navprofile__img">
                                    <span> 
                                    {
                                        this.state.NoImage && this.props.data !== undefined && this.props.data.profile_image !== ""
                                        ? <img src={this.props.data.profile_image} alt="profile" />
                                        : this.state.NoImage && <img src={this.state.profile_image !== "" ? this.state.profile_image : agent} alt="profile" />
                                    }
                                    </span>
                                      
                                    </div>
                                    <div>
                                        <div class="navprofile__text ms-3">
                                            <h6 class="font-weight-bold mb-0">{name}</h6>
                                            <span class="text-muted text-small">Seller Panel</span>
                                            <br/><span class="text-muted text-small">{this.state.subscribedPlans != null ? this.state.subscribedPlans.plan_name : ""}</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="mt-4">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                {/* <li class="nav-item">
                                  <Link  className={this.props.activePage === "dashboard" ? "nav-link active" : "nav-link"} to="/seller"><img src={gearwide} class="img-svg" /><span><span class="ms-3">Dashboard</span></span></Link>
                                </li> */}
                                <li class="nav-item">
                                  <Link  className={this.props.activePage === "profile" ? "nav-link active" : "nav-link"} to="/seller">
                                  <Person class="img-svg" /><span><span class="ms-3">My Profile</span></span>
                                  </Link>
                                </li>
                                <li class="nav-item">
                                    <Link className={this.props.activePage === "property-listing" ? "nav-link active" : "nav-link"} to="/seller/property">
                                    <Buildingcommunity class="img-svg" /><span><span class="ms-3">My Properties</span></span>
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link className={this.props.activePage === "subscribe" ? "nav-link active" : "nav-link"} to="/seller/subscribe">
                                    <Radiocircle class="img-svg" /><span><span class="ms-3">Subscribe</span></span>
                                    </Link>
                                </li>
                                { this.state.user && this.state.user.subscriptionPlanDetail && this.state.user.subscriptionPlanDetail.plan_name!="Basic Plan" &&
                                <li class="nav-item">
                                    <Link className={this.props.activePage === "invited" ? "nav-link active" : "nav-link"} to="/seller/invited">
                                    <Invited class="img-svg" /><span><span class="ms-3">Invited User</span></span>
                                    </Link>
                                </li>
                                }
                                <li class="nav-item">
                                    <h5 className='mt-3'>Transaction History</h5>
                                    <ul className='ms-4'>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "package_history" ? "nav-link active" : "nav-link"} to="/seller/package-history">
                                            <Package class="img-svg" /><span><span class="ms-3">Package</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "request_history" ? "nav-link active" : "nav-link"} to="/seller/request-history">
                                            <List class="img-svg" /><span><span class="ms-3">Request</span></span>
                                            </Link>
                                        </li>
                                        { this.state.user.subscriptionPlanDetail !== null && this.state.user.subscriptionPlanDetail.type === "premium" &&
                                            <li class="nav-item">
                                                <Link className={this.props.activePage === "boost_transaction" ? "nav-link active" : "nav-link"} to="/seller/boost-history">
                                                    <Package class="img-svg" /><span><span class="ms-3">Property Boost</span></span>
                                                </Link>
                                            </li>
                                        }
                                    </ul>
                                </li>
                                { this.state.user.subscriptionPlanDetail !== null && this.state.user.subscriptionPlanDetail.type === "premium" &&
                                    <li class="nav-item">
                                        <h5 className='mt-3'>Boost Property</h5>
                                        <ul className='ms-4'>
                                            <li class="nav-item">
                                                <Link className={this.props.activePage === "propertylist" ? "nav-link active" : "nav-link"} to="/seller/boost/propertylist">
                                                    <List class="img-svg" /><span><span class="ms-3">Property List</span></span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                }   

                                 <li class="nav-item">
                                 <Link to="/seller/buyer-chat" className={this.props.activePage === "buyer_chat" ? "nav-link active" : "nav-link"} >
                                 <List class="img-svg" /> <span><span class="ms-3">Buyer Chat</span></span>
                                    { this.state.buyerChatNotification>0 && this.props.activePage !== "buyer_chat" &&
                                        <div class="indicator">{this.state.buyerChatNotification}</div>
                                    }
                                </Link>
                                </li>

                                <li class="nav-item">
                                     <Link  className={this.props.activePage === "change_password" ? "nav-link active" : "nav-link"} to="/seller/change-password">
                                     <Lockaccess class="img-svg" /><span><span class="ms-3">Change Password</span></span>
                                     </Link>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
