import React, { Component } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Footer from '../../../Front/Footer'
import axios from "axios";
import { APIURL, SOCKETURL } from '../../../../components/constants/common';
import { Redirect, Link } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import face1 from '../../../../assets/images/profile-2.webp'
import send from '../../../../assets/images/send.png'
import {ReactComponent as  ChatLoader} from '../../../../assets/images/chat_loader.svg'
import { ReactComponent as MenuList}  from '../../../../assets/images/three-dots-vertical.svg'
import { ReactComponent as User}  from '../../../../assets/images/sales.svg'
import socketIOClient from "socket.io-client";
import socketClient from "socket.io-client";

export default class PackageTransaction extends Component {
    constructor() {
        super();
         this.chat_board_msg = React.createRef()
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            search: "",
            sellerList:[],
            messageList:[],
            message:"",
            to:"",
            firstToUser:0,
            page:0,
            loadMoreVisiblity:false,
            cLoader:false,
            socket:"",
            messageGetStatus:false,
            mobileView: false,
            chatArea: false      
        }
        this.sendHandle = this.sendHandle.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    configureSocket = () => {
        var socket = socketClient(SOCKETURL);
        
        this.setState({
            socket: socket
        })
        socket.on('message', data => {

           if(this.state.user)
            {
                if(data.to==this.state.user.id && data.from==this.state.to)
                {
                    var messageList=this.state.messageList;
                    messageList.push(data);
                    this.setMessageToLeftList(data.message,this.state.to)
                    this.setState({
                        messageList: messageList
                    },()=>{
                        this.scrollDownMessage()
                        this.updateMessage()
                    })
                }
                else if(data.to==this.state.user.id)
                {
                   this.setMessageToLeftList(data.message,data.from)
                   this.updateNotification(1,data.from)
                }
            }
        });
    }


    componentDidMount() {
      this.getSeller();
      this.configureSocket();
    }

    toggleView() {
        this.setState({
            mobileView: !this.state.mobileView
        })
    }

    chatUserView() {
        this.setState({
            chatArea: !this.state.chatArea
        }) 
    }

    sendHandle(){

        if(this.state.message.trim()=="")
        {
          return false  
        }

        this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);
        formData.append('message', this.state.message);
        formData.append('property_id','');
        this.setMessageToLeftList(this.state.message,this.state.to)
        axios
            .post(APIURL + "sub-user/send-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList;
                var message=this.state.message;
                messageList.push(response.data.data);

                var socket = this.state.socket;
                socket.emit('message',response.data.data);

                this.setState({
                    messageList: messageList,
                    message:"",
                    Loader:false
                },()=>{
                    this.scrollDownMessage() 
                    
                })


            })
            .catch((error) => {
                this.setState({
                    Loader:false
                })
            });
    }

    getSeller() {
        this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('search', this.state.search);

        axios
            .post(APIURL + "buyer/get-user-seller", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    sellerList: response.data.data.data
                })
                var firstToUser=this.state.firstToUser;
                if(firstToUser==0 &&  response.data.data.data.length>0)
                {
                   this.setState({
                        to: response.data.data.data[0].id,
                        messageGetStatus:true,
                    },()=>{
                        this.setNotificationzero()
                        this.updateMessage()
                        this.getMessage()
                    })
                    
                }
                firstToUser++;

                this.setState({
                    norecord:true,
                    Loader:false,
                    messageGetStatus:true,
                    firstToUser:firstToUser
                })
            })
            .catch((error) => {
                this.setState({
                    norecord:true,
                    Loader:false,
                    messageGetStatus:true,
                })
            });
    }

    getMessage() {
        this.setState({
            cLoader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);
        formData.append('page', this.state.page);

        axios
            .post(APIURL + "buyer/get-message", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                var messageList=this.state.messageList
                messageList=[...response.data.data,...messageList];
                var loadMoreVisiblity=false;
                if(response.data.data.length>0){
                  loadMoreVisiblity=true
                }
                
                this.setState({
                    messageList: messageList,
                    cLoader:false,
                    messageGetStatus:true,
                    loadMoreVisiblity:loadMoreVisiblity
                },()=>{
                    if(this.state.page==0){
                    this.scrollDownMessage()
                   }
                })

            })
            .catch((error) => {
                this.setState({
                    cLoader:false,
                    messageGetStatus:true,
                })
            });
    }

    setTo(item){
      
        this.setState({
            to: item.id,
            page:0,
            messageList:[]
        },()=>{
            this.getMessage()
            this.updateMessage()
            this.setNotificationzero()
        })
    }

    messageHandle(val){
      this.setState({
            message: val
        })
    }

   _handleKeyDown(e) {

        if (e.key === 'Enter') {
          this.sendHandle();
        }
      }

    scrollDownMessage(){
         this.chat_board_msg.current.scrollTop =  this.chat_board_msg.current.scrollHeight;
    }

    seacrhHandle(e) {
        this.setState({
            search: e
       },()=>{this.getSeller()})
    }


    updateMessage() {
       
        var token = this.state.token
        const formData = new FormData();
        formData.append('to', this.state.to);

        axios
            .post(APIURL + "buyer/update-message-status", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
            })
            .catch((error) => {
               
            });
    }

    updateNotification(noti,to){
        var sellerList=this.state.sellerList
        sellerList.filter(person => person.id==to).map(result => {
            result.unreadCount+=noti;
        })
        this.setState({
            sellerList: sellerList,
        })
    }

    setNotificationzero(){
        var sellerList=this.state.sellerList
        sellerList.filter(person => person.id==this.state.to).map(result => {
            result.unreadCount=0;
            })
    }


    setMessageToLeftList(message,to){
        var sellerList=this.state.sellerList
        var messageBuyer=sellerList.filter(person => person.id==to).map(result => {
            result.message=message;
            })
        messageBuyer=sellerList.filter(person => person.id==to);
        var newBuyerList=sellerList.filter(person => person.id!=to);
        newBuyerList=[...messageBuyer,...newBuyerList];
        this.setState({
            sellerList: newBuyerList
        })
    }



    loadMore(){
        var page=this.state.page;
        page+=1;

    this.setState({
            page: page,
        },()=>{
            this.getMessage()
        })
    }


    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }

        if (this.state.user.user_type !== "Buyer") {
            return <Redirect to="/permission" />;
        }

        return (
            <>
                <Navbar />
                <div class="content-area">
                    {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}

                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account</small></div>
                        </div>
                    </div>
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                        <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-lg-4">
                                    <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="seller_chat" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-lg-8">
                                   <div className={this.state.chatArea ? "chat_area border open" : "chat_area border"}>
                                        <div className="chat_list">
                                            <div className="chat-search">
                                                <input type="text" placeholder="Search" onChange={(e) => this.seacrhHandle(e.target.value)} />
                                            </div>
                                            <ul>
                                                {this.state.sellerList.length > 0 && this.state.sellerList.map((item, idx) => (

                                                    <li onClick={() => this.setTo(item)} >
                                                        <div class={`chatprofile ${this.state.to==item.id?"active":""}`}>
                                                            <div>
                                                                <div class="chatprofile_img">
                                                                     { item.profile_path == "" ?
                                                                     <img src={face1} alt="user" />
                                                                     :
                                                                     <img src={item.profile_path} alt="user" />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="chatprofile_nm">
                                                                <h6>{item.fullName}</h6>
                                                                <p>{item.message}</p>
                                                            </div>
                                                            { item.unreadCount>0 && 
                                                            <div class="indicator">{item.unreadCount}</div>
                                                           }
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                         { this.state.cLoader===true &&
                                           <div className="chat_loader"><ChatLoader class="img-svg" /></div>
                                         }
                                        <div className="chat_board">
                                            <div className="chat_list_users" onClick={()=>this.chatUserView()}><User class="img-svg" alt="User" /></div>
                                            <div className="chat_board_msg" ref={this.chat_board_msg}>

                                             { this.state.loadMoreVisiblity==true &&  this.state.cLoader===false && this.state.messageList.length > 9 && 
                                                <div className="chat_load_more">
                                                <Button className="btn-primary btn btn-sm" onClick={() => this.loadMore()} > Load More</Button>
                                                </div>
                                             }
                                              {this.state.messageList.length > 0 && this.state.messageList.map((item, idx) => (
                                                <React.Fragment>
                                                {item.to!=this.state.to &&
                                                    <div className="bubble bubbleIn">
                                                        <p>{item.message}</p>
                                                        <span className="date">{item.date} {item.timestamp}</span>
                                                    </div>
                                                }
                                                {item.to==this.state.to &&
                                                    <div className="bubble bubbleOut">
                                                        <p>{item.message}</p>
                                                      
                                                        <span className="date">{item.date} {item.timestamp}</span>
                                                    </div>
                                                }
                                               
                                                 </React.Fragment>
                                                
                                              ))}

                                               {this.state.to == 0 && this.state.messageGetStatus==true &&
                                                  <div className="bubble no-message">Send direct message to seller from property detail page  </div>
                                               }
                                            </div>

                                           {this.state.to > 0 &&

                                            <div className="chat-input">
                                                <input type="text" placeholder="Type a message" value={this.state.message}  onKeyDown={this._handleKeyDown} onChange={(e) => this.messageHandle(e.target.value)} />
                                                <Button><img src={send} alt="send" onClick={() => this.sendHandle()} /></Button>
                                            </div>
                                           }
                                        </div>
                                    </div>    
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
