import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import Header from './Header'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'
import { Button } from 'reactstrap'
import Select from 'react-select'
import { Redirect } from 'react-router'

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            userInfo: {      
                city: "",
                bedroom_from: "",
                bedroom_to: "",
                price_from: "",
                price_to: "",
                category_id: "",
                sub_category_id: "",
                address: "",
                country: "",
            },
            countryId: "",
            countries_name: [],
            countrySelected: {},
            Countries: [],
            category_id: "",
            CategoryId: "",
            category_name: [],
            categorySelected: {},
            Categories: [],
            category_id: "",
            States: [],
            state: "",
            state_name: [],
            SubCategory: [],
            SubCategoryId: "",
            sub_category: [],
            selectType: "",
            msg: "",
            errMsg: {},
            scsMsg: "",
            cities_name: [],
            city_id: "",
            mobileView: false
        }
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    handleCountry(e) {
        this.setState({
            country: e
        })
    };

    handleState(e) {
        this.setState({
            state: e
        }, () => {
        if(e) {
            this.getCities(e)
        } else {
            this.setState({
                cities_name: [],
                city_id: ""
            })
        }})
    }

    handleCity(e) {
        this.setState({
            city_id: e
        })
    }

    getCities(state_id) {
        axios
            .post(APIURL + "cities", {
                state_id: state_id,
            })
            .then((response) => {
                let cities_name = response.data.states;
                const cityNames = [];
                for (var c = 0; c < cities_name.length; c++) {
                    cityNames.push({ value: cities_name[c].id, label: cities_name[c].name })
                }
                this.setState({
                    cities_name: cityNames,
                })
            })
    }

    handleCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                console.log(response.data)
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
            .catch((error) => {
                this.setState({

                })
            });
    };

    // getCountries() {
    //     axios
    //         .get(APIURL + "countries")
    //         .then((response) => {
    //             let countries_name = response.data.countries;
    //             const CountryNames = [];
    //             for (var c = 0; c < countries_name.length; c++) {
    //                 CountryNames.push({ value: countries_name[c].id, label: countries_name[c].name })
    //             }
    //             this.setState({
    //                 Countries: CountryNames,
    //             })
    //             console.log(this.state.Countries)
    //         })
    // }

    getCategoryList(e) {
        axios
            .post(APIURL + "get-category-list", {
                country_id: e
            })
            .then((response) => {
                console.log("st", response.data.data)
                let category_name = response.data.data
                const CountryNames = [];
                for (var c = 0; c < category_name.length; c++) {
                    CountryNames.push({ value: category_name[c].id, label: category_name[c].category_name })
                }
                this.setState({
                    Categories: CountryNames,
                })
                console.log("category", this.state.Categories)
            })
    }

    handleCategory(e) {
        this.setState({
            CategoryId: e,
            SubCategoryId: ""
        }, () => {
            if(e) {
                this.handleCategorySubCategory(e)
            } else {
                this.setState({
                    SubCategory: []
                })
            }            
        })
    };

    handleSubCategory = (e) => {
        this.setState({
            SubCategoryId: e
        })
    }

    // //sub category
    handleCategorySubCategory = (id) => {
        // alert(id)
        axios
            .post(APIURL + "get-sub-category-list", {
                category_id: id,
            })
            .then((response) => {

                this.setState({
                    SubCategory: response.data.data
                })
            })
            .catch((error) => {
                this.setState({

                })
            });
    };
    //form handler

    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({ userInfo });
        console.log(userInfo)
    };

    //Alert information edit 
    getPropertyInfo() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('user_id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL + "buyer/property-alert-setting-edit"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    if (response.data.data != "") {
                        this.setState({
                            userInfo: {
                                address: info.address,
                                bedroom_from: info.bedroom_from,
                                bedroom_to: info.bedroom_to,
                                price_from: info.price_from,
                                price_to: info.price_to,
                            },
                            city_id: response.data.data.city,
                            CategoryId: info.category_id,
                            SubCategoryId: info.sub_category_id,
                            countryId: response.data.data.country,
                            state: response.data.data.state
                        })
                        // alert(info.sub_category_id)
                        this.handleCountryState(response.data.data.country)
                        this.handleCategorySubCategory(response.data.data.category_id)
                        this.handleCountryState(1)
                        if(response.data.data.state > 0)
                        {
                            this.getCities(response.data.data.state)
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }
    //    update  profile submit handler

    onSubmitHandler = (e) => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('country', 1);
        formData.append('state', this.state.state);
        formData.append('city', this.state.city_id);
        formData.append('address', this.state.userInfo.address);
        formData.append('bedroom_to', this.state.userInfo.bedroom_to ? this.state.userInfo.bedroom_to :"");
        formData.append('bedroom_from', this.state.userInfo.bedroom_from ? this.state.userInfo.bedroom_from : "");
        formData.append('price_from', this.state.userInfo.price_from ? this.state.userInfo.price_from : "");
        formData.append('price_to', this.state.userInfo.price_to ? this.state.userInfo.price_to : "");
        formData.append('category_id', this.state.CategoryId);
        formData.append('sub_category_id', this.state.SubCategoryId);
        formData.append('user_id', this.state.user.id);

        axios
            .post(APIURL + "buyer/property-alert-setting-update", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                this.setState({
                    scsMsg: "Property alert successfuly updated",
                })
                setTimeout(() => this.setState({
                    scsMsg: ""
                }), 4000);
                this.getPropertyInfo()
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                })
                setTimeout(() => this.setState({
                    errMsg: ""
                }), 4000);
            });
    };

    componentDidMount() {
        this.getPropertyInfo()
        this.handleCountryState(1)
        this.getCategoryList()
    }


    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "Buyer") {
            return <Redirect to="/buyer/propertyalertion" />;
        }

        return (
            <>
                <Navbar />
                <div class="content-area">
                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account </small></div>
                        </div>
                    </div>
                    {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                        <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-sm-4">
                                    <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="propertyalert"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-sm-8">
                                    <div class="bg-light">
                                        <div class="px-xxl-4 py-4 px-3">
                                            <div>
                                                <div>
                                                    <div>
                                                        <h2 id="page_headeing"><span>Property Alert</span></h2>
                                                        <hr />
                                                    </div>
                                                     {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                                    {/* messgae err */}
                                                    {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}

                                                    <div class="submit-section">
                                                        <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="mb-4">
                                                                <label for="country" class="form-label">Country <strong className="text-danger" >*</strong></label>
                                                                <select className="form-control" value={this.state.country} onChange={(e) => this.handleCountry(e.target.value)} disabled>
                                                                    <option value="1">Nigeria</option>
                                                                </select>
                                                                <span className="text-danger">{this.state.errMsg.country}</span>
                                                            </div>
                                                        </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">State <strong className="text-danger" >*</strong></label>
                                                                    <select className="form-control" autoFocus={true}
                                                                        value={this.state.state}
                                                                        onChange={(e) => this.handleState(e.target.value)} >
                                                                        <option value="" >Select State</option>
                                                                        {this.state.States.map((option) => (
                                                                            <option value={option.value}>{option.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    <span className="text-danger">{this.state.errMsg.state}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label for="city" class="form-label">Locality <strong className="text-danger" >*</strong></label>
                                                                    <select className="form-control" value={this.state.city_id} onChange={(e) => this.handleCity(e.target.value)} >
                                                                        <option value="" >Select Locality</option>
                                                                        {this.state.cities_name.map((option) => (
                                                                            <option value={option.value}>{option.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    <span className="text-danger">{this.state.errMsg.city}</span>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Address</label>
                                                                    <textarea className="form-control"
                                                                        autoFocus={true}
                                                                        name="address"
                                                                        placeholder="address"
                                                                        value={this.state.userInfo.address}
                                                                        onChange={this.onChangehandler}>

                                                                    </textarea>
                                                                    {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Bedroom From</label>
                                                                    <select className="form-control"
                                                                        autoFocus={true}
                                                                        name="bedroom_from"
                                                                        value={this.state.userInfo.bedroom_from}
                                                                        onChange={this.onChangehandler}
                                                                    ><option value="">Select </option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                        <option value="6">6</option>
                                                                        <option value="7">7</option>
                                                                        <option value="8">8</option>
                                                                        <option value="9">9</option>
                                                                        <option value="10">10+</option>
                                                                    </select>

                                                                    {/* <span className="text-danger">{this.state.errMsg.bedroom_from}</span> */}
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Bedroom To </label>
                                                                    <select className="form-control"
                                                                        autoFocus={true}
                                                                        name="bedroom_to"
                                                                        value={this.state.userInfo.bedroom_to}
                                                                        onChange={this.onChangehandler}
                                                                    ><option value="">Select </option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                        <option value="6">6</option>
                                                                        <option value="7">7</option>
                                                                        <option value="8">8</option>
                                                                        <option value="9">9</option>
                                                                        <option value="10">10+</option>

                                                                    </select>

                                                                    {/* <span className="text-danger">{this.state.errMsg.bedroom_to}</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Price From</label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="number"
                                                                        name="price_from"
                                                                        value={this.state.userInfo.price_from}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    {/* <span className="text-danger">{this.state.errMsg.price_from}</span> */}
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Price To</label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="number"
                                                                        name="price_to"
                                                                        value={this.state.userInfo.price_to}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    {/* <span className="text-danger">{this.state.errMsg.price_to}</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Category</label>
                                                                    <select className="form-control" autoFocus={true}
                                                                        value={this.state.CategoryId}
                                                                        onChange={(e) => this.handleCategory(e.target.value)} >
                                                                        <option value="">Select Category</option>
                                                                        {this.state.Categories.map((option) => (
                                                                            <option value={option.value}>{option.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    {/* <span className="text-danger">{this.state.errMsg.categoryId}</span> */}

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Sub Category</label>
                                                                    <select className="form-control"
                                                                        autoFocus={true}
                                                                        value={this.state.SubCategoryId}
                                                                        onChange={(e) => this.handleSubCategory(e.target.value)} >
                                                                        <option value="" >Select SubCategory</option>
                                                                        {this.state.SubCategory.map((option) => (
                                                                            <option value={option.id}>{option.category_name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {/* <span className="text-danger">{this.state.errMsg.sub_category_id}</span> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class=" col-sm-12">
                                                                <div class="mb-4">
                                                                    <Button
                                                                        color="btn btn-primary"

                                                                        onClick={() => this.onSubmitHandler()}
                                                                    >Update</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
