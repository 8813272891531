import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'
import axios from "axios";
import { APIURL, PAYPAL_CLIENT_ID } from '../../../components/constants/common';
import { Redirect } from 'react-router'
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'

export default class Subscribe extends Component {
    constructor(){
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            plans: [],
            errMsg: "",
            scsMsg: "",
            premiumPlan: "1",
            paymentModal: false,
            amountToPay: 0,
            id: "",
            mobileView: false
        }
    }

    componentDidMount() {
        this.getSubscriptionPlans()
    }

    paymentModal(amount, id) {
        this.setState({
            paymentModal: !this.state.paymentModal,
            amountToPay: this.state.premiumPlan === "1" ? amount : this.state.premiumPlan === "12" ? ((amount * this.state.premiumPlan)*(0.85)) : ((amount * this.state.premiumPlan)*(0.9)),
            id: id
        })
    }

    paymentModalClose() {
        this.setState({
            paymentModal: false
        })
    }

    handlePremiumPlan(e) {
        this.setState({
            premiumPlan: e
        })
    }

    getSubscriptionPlans() {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"get-subscription-plan-list"
            const formData = new FormData();
            // formData.append('token', this.state.token);
           
           axios
                .post(app_url,null, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {

                    this.setState({
                        plans: response.data.data
                    })
                    console.log(this.state.user);
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.error ,
                    })
                });
        }
    }

    buySubscriptionPlan(plan_id) {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"subscription-plan-purchase"
            const formData = new FormData();
            formData.append('user_id', this.state.user.id);
            formData.append('plan_id', plan_id);
            formData.append('months', this.state.premiumPlan);
           
           axios
                .post(app_url,formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        premiumPlan: "1",
                        paymentModal: false,
                        scsMsg: response.data.message
                    }, () => {
                        setTimeout(() => this.setState({ 
                            scsMsg: ""
                        }), 4000);
                    })
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.error ,
                    })
                });
        }
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if(this.state.user.user_type !== "Buyer"){
            return <Redirect to="/permission" />;
        }
     
        return (
            <>
                    <Navbar />
                    <div class="content-area">
                        <div class="breadcrumb-area gradient1">
                            <div class="container">
                                <h1>My Account</h1>
                                <div><small>Welcome To Your Account</small></div>
                            </div>
                        </div>
                        <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                        <div class="myaccount">
                        <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-xxl-3 col-lg-4">
                                        <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                            <div class="px-xxl-4 py-4 px-3">
                                                <Sidebar activePage="subscribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-9 col-lg-8">
                                        <div class="bg-light">
                                            <div class="px-xxl-4 py-4 px-3">
                                                <div>
                                                    <div>
                                                      <h2 class="mb-3">Subscribe</h2>
                                                    </div>
                                                    {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                                    {/* messgae err */}
                                                    {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                                                    <div class="row">
                                                    {this.state.plans.length > 0 && this.state.plans.map((item, idx) => (
                                                        <>
                                                            {/* {   item.plan_name == "Basic" &&
                                                                <div class="col-lg-6">
                                                                    <div class="card subscribeplans">
                                                                      <div class="card-body">
                                                                        <h2 class="subscribeplans__heading">{item.plan_name}</h2>
                                                                        <hr />
                                                                        <h4 class="subscribeplans__subheading">Features</h4>
                                                                        <ul class="subscribeplans__feature">
                                                                          <li>Profile Name Identifiable</li>
                                                                          <li>Standard Checkout</li>
                                                                          <li>Individual Shopper</li>
                                                                          <li>Non Transferrable Verification</li>
                                                                          <li>No Referral Discount</li>
                                                                          <li>Free Chatwith Seller</li>
                                                                          <li>Free to Search/Browse</li>
                                                                          <li>Create Property Alert</li>
                                                                        </ul>
                                                                        <div class="btn btn-primary" onClick={() => this.buySubscriptionPlan(item.id)}>Buy Plan</div>
                                                                      </div>
                                                                    </div>
                                                                </div>
                                                            } */}
                                                            {   item.plan_name == "Premium Plan" &&
                                                                <div class="col-lg-4 col-md-6">
                                                                    <div class="card subscribeplans">
                                                                        <div class="card-body">
                                                                            <div class="d-flex justify-content-between align-items-center">
                                                                                <div>
                                                                                    <h2 class="subscribeplans__heading">{item.plan_name}</h2>
                                                                                    <div>
                                                                                        <select class="form-control" value={this.state.premiumPlan} onChange={(e) => this.handlePremiumPlan(e.target.value)}>
                                                                                            <option value="">Select Month</option>
                                                                                            <option value="1">1 Month</option>
                                                                                            <option value="3">3 Months</option>
                                                                                            <option value="6">6 Months</option>
                                                                                            <option value="12">12 Months</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ml-2">
                                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                                    {
                                                                                        this.state.premiumPlan === "1"
                                                                                        ? <div class="price"><h4> £{item.amount}</h4></div>
                                                                                        : this.state.premiumPlan === "12"
                                                                                        ? <div class="price"><h4> £{(item.amount * this.state.premiumPlan)*(0.85)}</h4></div>
                                                                                        : <div class="price"><h4> £{(item.amount * this.state.premiumPlan)*(0.9)}</h4></div>
                                                                                    }                             
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        <hr />
                                                                        <h4 class="subscribeplans__subheading">Features</h4>
                                                                        <ul class="subscribeplans__feature">
                                                                          <li>Incognito Mode</li>
                                                                          <li>Easier/Faster Checkout</li>
                                                                          <li>Executive purchase(E2E/P2P)</li>
                                                                          <li>Transferrable Verification</li>
                                                                          <li>Referral Discount (Unique User)</li>
                                                                          <li>Free Chatwith Seller</li>
                                                                          <li>In App Personal Shopper Chat with Recruiter/Assistance</li>
                                                                          <li>Create Property Alert</li>
                                                                        </ul>
                                                                        <div class="btn btn-primary" onClick={() => this.paymentModal(item.amount, item.id)}>Buy Plan</div>
                                                                      </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal size="md" isOpen={this.state.paymentModal} toggle={() => this.paymentModalClose()}>
                        <ModalHeader className="" toggle={() => this.paymentModalClose()}>
                            Choose Payment Option<br />
                            <span className="text-danger">{this.state.errMsgCmt}</span>
                        </ModalHeader>
                        <ModalBody className="border-0">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 mx-auto">
                                    <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID,}}>
                                    <PayPalButtons
                                        //fundingSource="paypal"
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: this.state.amountToPay,
                                                        },
                                                    },
                                                ],
                                                application_context: {
                                                    shipping_preference: 'NO_SHIPPING'
                                                }
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then((details) => {
                                                const name = details.payer.name.given_name;
                                                {
                                                    details.status === "COMPLETED" &&
                                                    this.buySubscriptionPlan(this.state.id)
                                                }
                                            });
                                        }}
                                    />
                                    </PayPalScriptProvider>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="">
                                <button type="button" className="btn btn-light" onClick={() => this.paymentModalClose()}>Close</button>
                            </div>
                        </ModalFooter>
                    </Modal>
                   <Footer />
                   
            </>
        )
    }
}
