import React, { Component } from 'react'
import { Row, Col, Spinner, Jumbotron, Label, Input, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter} from 'reactstrap';
import axios from "axios";
import { APIURL } from '../../../constants/common';
import { Redirect, Link } from "react-router-dom";
// import { Helmet } from "react-helmet";

export default class Map extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            selectedFiles: [],
            selectedVideo: [],
            selectedOptions: [],
            Images: [],
            tempArr: [],
            GallertData: [],
            removeId: "",
            scsMsg: "",
            errMsg: "",
            propertyStatus: ""
        };
    }

    componentDidMount() {
        this.getPropertyMedia()
    }

    fileSelectedHandler = (e) => {
        this.setState({
            selectedFiles: [...this.state.selectedFiles, ...e.target.files],

        }, () => {
            this.uploadImage();
            e.target.value = null;
        })

    }
    VideoSelectedHandler = (e) => {
        this.setState({
            selectedVideo: [...this.state.selectedVideo, ...e.target.files]
        }, () => {
            this.uploadImage();
            e.target.value = null;

        })

    }

    getPropertyMedia() {

        this.setState({
            selectedFiles: [],

        })
        this.forceUpdate()
        var token = this.state.token
        if (this.state.user) {
            const formData = new FormData();
            formData.append('property_id', this.props.data.property_id);
            axios
                .post(APIURL + "seller/property-gallery-edit", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                  
                    this.setState({
                        GalleryData: response.data.data,
                        propertyStatus: response.data.property_request_status
                    })
                    // this.props.data.getStatus(response.data.property_request_status);
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }
    handleRemoveTeamAttrs = (item) => {
        var token = this.state.token
        if (this.state.user) {
            const formData = new FormData();
            formData.append('id', item.id);
            axios
                .post(APIURL + "seller/delete-property-image", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then((response) => {
                    console.log("removed Image", response.data.data)
                    this.setState({
                        scsMsg: response.data.data,
                        GalleryData: this.state.GalleryData.filter((s, sidx) => item.id !== s.id)
                    })
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    uploadImage = () => {
        this.ref.current.value = ""
        const tempArr = [];
        var token = this.state.token;
        const formData = new FormData();
        formData.append('property_id', this.props.data.property_id);

        this.state.selectedFiles.forEach(file => {
            console.log("file >>> ", file);
            formData.append('upload_files[]', file)
        });

        this.state.selectedVideo.forEach(video => {
            console.log("file >>> ", video);
            formData.append('upload_video[]', video)
        });
        console.log("pictures >> ", tempArr);
        const option = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data'
            }
        };
        this.setState({ Loader: true })
        axios
            .post(APIURL + "seller/property-gallery-update", formData, option)
            .then(result => {
                console.log("result", result)
                this.setState({
                    Loader: false,
                    selectedFiles: [],
                    selectedVideo: []
                }, () => {
                    this.props.data.setMessage(result.data.message);
                    this.getPropertyMedia();
                    this.props.data.checkPropertyDetails();
                })
            })
            .catch(error => {
                console.log("err", error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false,
                    selectedFiles: [],
                })
                setTimeout(() => this.setState({
                    errMsg: "",
                }), 3000)
            }
            );
    }
    render() {

        return (
            <React.Fragment>
                {/* <Helmet>
                    <link rel="stylesheet" href="http://192.168.1.13/custom.css" />
                </Helmet> */}
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {/* {this.state.errMsg.upload_files || this.state.errMsg.upload_video ?
                    <div class="alert alert-danger text-center" role="alert">
                        {this.state.errMsg.upload_files} {this.state.errMsg.upload_video}
                    </div>
                    : ""} */}
                <div className={this.props.data.activatedTab === "gallery" ? "tab-pane fade show" : "tab-pane fade"} id="pills-gallery" role="tabpanel" aria-labelledby="pills-gallery-tab">
                    <form class="forms-sample">
                        <div>
                            <div>
                                <h4>Property Images <strong class="text-danger">*</strong></h4>
                                <hr />
                            </div>
                            <div class="w-auto mb-4">
                            {
                                this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject"
                                ?
                                    <>
                                    <input
                                        type="file" ref={this.ref}
                                        className="form-control form-control-sm"
                                        multiple
                                        onChange={this.fileSelectedHandler}
                                        accept="image/png, image/jpg, image/jpeg" 
                                    />
                                    <label>Your first uploaded image will be display property image</label>
                                    </>
                                : ""
                            }
                            </div>

                            
                                    {this.state.scsMsg}
                                    
                                        <div className="row">
                                            {this.state.GalleryData &&
                                                this.state.GalleryData.map((file, index) => (                                                        
                                                file.type !== "video" ?
                                                <div className="col-sm-2 col-12">
                                                    <div className="img-gallery">
                                                        <img className="w-100 " src={file.url_path} />
                                                        {
                                                            this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject"
                                                            ?
                                                            <div>
                                                                <div class="img-delete">
                                                                    <i onClick={(e) => this.handleRemoveTeamAttrs(file)} className="cursor_pointer far fa-trash-alt"></i>
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                    </div>
                                                    :
                                                    ""
                                                ))}
                                        </div>
                                    
                        </div>
                        <div class="mb-2">
                        <div class="my-4">
                            <h4>Property Video</h4>
                            <hr />
                        </div>
                            <div class="w-auto mb-4">
                            {
                                this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject"
                                ?
                                    <input
                                        type="file" ref={this.ref}
                                        className="form-control form-control-sm"
                                        multiple
                                        onChange={this.VideoSelectedHandler}
                                        accept="video/mp4" 
                                    />
                                : ""
                            }
                            </div>

                            
                                    {this.state.scsMsg}
                                    
                                        <div className="row">
                                            {this.state.GalleryData &&
                                                this.state.GalleryData.map((file, index) => (
                                                    file.type === "video" ?
                                                        <div class="col-sm-4">
                                                        <div className="img-gallery">
                                                            <video controls >
                                                                <source src={file.url_path} type="video/mp4" />
                                                            </video>
                                                            {
                                                                this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject"
                                                                ?
                                                                <div>
                                                                    <div class="img-delete">
                                                                        <i onClick={(e) => this.handleRemoveTeamAttrs(file)} className="cursor_pointer far fa-trash-alt"></i>
                                                                    </div>
                                                                </div>
                                                                :""
                                                            }
                                                        </div>
                                                        </div>
                                                        :
                                                        ""
                                                ))}
                                        </div>


                        </div>
                        <div class="row mt-4">
                        <div class="col-12">
                        {
                            this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject"
                            ? 
                            <button type="button" onClick={(e) => this.props.data.handleActiveTab("3")}  class="btn btn-secondary me-3">Back to Features</button>
                            //{/*<button type="button" onClick={(e) => this.onSaveHandler()}  class="btn btn-primary">Submit</button>*/}
                            : ""
                        }
                        </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}
