import React, { Component } from 'react'
import { Redirect } from 'react-router';
import NoItem from '../../../assets/images/no_item.svg'

import Sidebar from './Sidebar'
import Navbar from './Navbar'

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            reload:false
        }
    }



    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    componentDidMount() {
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
       
        // if(this.state.user.user_type !== "Agent"){
        //     return <Redirect to="/permission" />;
        // }
        return (
            <div className='admin'>
            <Sidebar />
                <div class="content-area">
                  <Navbar />

                  <div class="content">
                    <div class="no_item">
                      <img src={NoItem} class="img-svg" alt="no item" />
                    </div>
                  </div>

                </div>
            </div>
        )
    }
}
