// export const APIURL = "https://apigenhaven.imenso.in/api/"
// export const BASEURL = "https://genhaven.imenso.in/"
// export const APIDOMAIN = "https://apigenhaven.imenso.in/"
// export const SOCKETURL = "https://imenso.in:6001/"
// export const PAYPAL_CLIENT_ID = "AbWe-As9NgXer70W18z03Dt1azfH9iUfYPpMeL3xZ1j0vtx9zmQssTbmRn-6RJQZtFlQ3RJu0GFfXhXX&currency=USD"

export const APIURL = "https://api.genhaven.co.uk/api/"
export const BASEURL = "https://genhaven.co.uk/"
export const APIDOMAIN = "https://api.genhaven.co.uk/"
export const PAYPAL_CLIENT_ID = "AcKjxUuSQWxM-oMWot2CLtTUK8lcWE7oALsEKKHfq85wkzWbq-q0e5wslBGgQ-n1B3tweqG9MkwUvx6F&currency=GBP"
export const SOCKETURL = "https://chat.genhaven.co.uk/"