import React, { Component } from 'react'

//import '../src/css/style.css'
import 'react-image-lightbox/style.css';
import "react-image-gallery/styles/css/image-gallery.css";
import "react-datepicker/dist/react-datepicker.css";
import '../src/assets/css/bootstrap.min.css'

import '../src/assets/css/style.css'
import '../src/assets/css/style-admin.css'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/Home/Index'
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import AboutUs from './components/Pages/About/Index';
import PaypalTest from './components/Pages/Paypal/Index';
import TermofUse from './components/Pages/TermsofUse/Index';
import PrivacyPolicy from './components/Pages/PrivacyPolicy/Index';
import CookiesPolicy from './components/Pages/CookiesPolicy/Index';
import ContactUs from './components/Pages/Contact/Index';
import Faq from './components/Pages/Faq/Index';
import Search from './components/Search/Index';
import PropertyDetails from './components/Pages/PropertyDetails/Index';

// User check
import CheckUser from './components/GlobalComponents/CheckUser';

// Buyer panel 
import Buyer from './components/users/Buyer/Index';
import BuyerProperty from './components/users/Buyer/PropertyList';
import BuyerAddProperty from './components/users/Buyer/AddProperty';
import BuyerNewAddProperty from './components/users/Buyer/NewAddProperty';
import BuyerPropertyDetails from './components/users/Buyer/ViewAddedPropertyDetails';

import BuyerPropertyAlert from './components/users/Buyer/PropertyAlert';

import BuyerProfile from './components/users/Buyer/MyProfile';
import BuyerWishlist from './components/users/Buyer/Wishlist';
import BuyerInvitedlist from './components/users/Buyer/Invited';
import BuyerPackageHistory from './components/users/Buyer/Transaction/PackageTransaction';
import BuyerRequestHistory from './components/users/Buyer/Transaction/RequestTransaction';
import BuyerAddedRequestHistory from './components/users/Buyer/Transaction/BuyerAddedRequestTransaction';

import BuyerChangePassword from './components/users/Buyer/ChangePassword';
import BuyerSubscribe from './components/users/Buyer/Subscribe';
import BuyerSellerChat from './components/users/Buyer/SellerChat/Index';
import BuyerSubUserChat from './components/users/Buyer/SubUserChat/Index';
import BuyerChatTest from './components/users/Buyer/ChatTest/Index';


// Seller panel 
import Seller from './components/users/Seller/Index';
import SellerProperty from './components/users/Seller/PropertyList';
import SellerAddProperty from './components/users/Seller/Property/AddProperty';
import SellerProfile from './components/users/Seller/Profile';
import SellerChangePassword from './components/users/Seller/ChangePassword';
import SellerSubscribe from './components/users/Seller/Subscribe';
import SellerInvitedCoupon from './components/users/Seller/Invited/Index';
import SellerPackageHistory from './components/users/Seller/Transaction/PackageTransaction';
import SellerRequestHistory from './components/users/Seller/Transaction/RequestTransaction';
import SellerBoostProperty from './components/users/Seller/Boost/PropertyList';
import SellerBoostHistory   from './components/users/Seller/Transaction/BoostingTransaction';
import SellerBuyerChat from './components/users/Seller/BuyerChat/Index';

// Agent panel 
import Agent from './components/users/Agent/Index';
import AgentProperty from './components/users/Agent/SellerProperty';
import AgentBuyerProperty from './components/users/Agent/BuyerProperty';
import AgentBuyerAddedProperty from './components/users/Agent/BuyerAddedProperty';
import AgentProfile from './components/users/Agent/Profile';
import AgentChangePassword from './components/users/Agent/ChangePassword';
import ViewPropertyDetails from './components/users/Agent/ViewPropertyDetails';
import ViewPropertyDetailsByBuyer from './components/users/Agent/ViewPropertyDetailsByBuyer';
import ViewPropertyDetailsAddedByBuyer from './components/users/Agent/ViewPropertyDetailsAddedByBuyer';
// Admin panel 
import Admin from './components/users/Admin/Index';
import AdminProfile from './components/users/Admin/Profile';
import Cms from './components/users/Admin/Cms/PageList';
import EditCms from './components/users/Admin/Cms/EditPage'
import AdminChangePassword from './components/users/Admin/ChangePassword';
import AdminAddCommission from './components/users/Admin/AddCommission';
import AdminCommissionHistory from './components/users/Admin/CommissionHistory';
import AdminCommissionAlertList from './components/users/Admin/CommissionAlertList';
import AdminTransactionHistory from './components/users/Admin/TransactionHistory';
import AdminTransactionHistoryBuyer from './components/users/Admin/TransactionHistoryBuyer'
import AdminTransactionHistorySeller from './components/users/Admin/TransactionHistorySeller';
import UserManagementAgent from './components/users/Admin/UserManagement/Agent';
import UserManagementBuyerSeller from './components/users/Admin/UserManagement/BuyerSeller';


import AdminListSubUser from './components/users/Admin/ListSubUser';
import AdminAddSubUser from "./components/users/Admin/AddSubUser"
import AdminUpdateSubUser from "./components/users/Admin/UpdateSubUser"
import AdminBuyerAddedRequestHistory from './components/users/Admin/TransactionHistoryBuyerAdded';

import AdminProperty from './components/users/Admin/Property/Index';
import AdminPublishProperty from './components/users/Admin/Property/SellersPublish';
import AdminBuyerProperty from './components/users/Admin/Property/BuyerProperty';
import AdminBuyerAddedProperty from './components/users/Admin/Property/BuyerAddedProperty';
import AdminViewPropertyDetails from './components/users/Admin/Property/ViewPropertyDetails';
import AdminViewPropertyDetailsByBuyer from './components/users/Admin/Property/ViewPropertyDetailsByBuyer';
import AdminViewPropertyDetailsAddedByBuyer from './components/users/Admin/Property/ViewPropertyDetailsByBuyerAdded';
import PropertyCertificate from './components/users/Common/PropertyCertificate';
import AdminPackage from './components/users/Admin/Package/Index';
import EditPackage from './components/users/Admin/Package/EditPackage';
import AdminEnquiry from "./components/users/Admin/Enquiry/Index"
import SubUserAssign from "./components/users/Admin/SubuserAssign/BuyerList"

import SubUserProfile from './components/users/SubUser/Profile';
import SubUserBuyer from './components/users/SubUser/Buyer/Index';
import SubUserChat from './components/users/SubUser/Chat/Index';

// Error page
import ErrorPage from './components/GlobalComponents/ErrorPage'
import permissionDenied from './components/GlobalComponents/PermissionDenied'

import ScrollToTop from "./components/ScrollToTop";

export default class App extends Component {
  render() {
    const login = localStorage.getItem("isLoggedIn");
    return (
      <div>
        <Router>
         <ScrollToTop>
          <Switch>
            <Route exact path="/"  component={Home} />
            <Route exact path="/signin"  component={SignIn} />
            <Route exact path="/signup"  component={SignUp} />
            <Route exact path="/user/verify/:token"  component={SignIn} />
            <Route exact path="/forgot/password"  component={ForgotPassword} />
            <Route exact path="/password/reset/:token" component={ResetPassword}></Route>
            <Route exact path="/page/about-us"  component={AboutUs} />
            <Route exact path="/page/terms-condition"  component={TermofUse} />
            <Route exact path="/page/privacy-policy"  component={PrivacyPolicy} />
            <Route exact path="/page/cookies-policy"  component={CookiesPolicy} />
            {/* <Route exact path="/about-us" component={AboutUs}></Route> */}
            <Route exact path="/page/paypal"  component={PaypalTest} />
            <Route exact path="/contact-us" component={ContactUs}></Route>
            <Route exact path="/faq" component={Faq}></Route>
            <Route exact path="/search" component={Search}></Route>
            <Route exact path="/realestateproperty/:name/:local/:state/:id" component={PropertyDetails}></Route>

            <Route exact path="/checkuser" component={CheckUser} />


            <Route exact path="/buyer" component={BuyerProfile} />
            <Route exact path="/buyer/wishlist" component={BuyerWishlist} />
            <Route exact path="/buyer/invited" component={BuyerInvitedlist} />
            <Route exact path="/buyer/property" component={BuyerProperty} />
            <Route exact path="/buyer/addproperty" component={BuyerAddProperty} />
            <Route exact path="/buyer/newaddproperty" component={BuyerNewAddProperty} />
            <Route exact path="/buyer/property/details/:id?" component={BuyerPropertyDetails} />
            <Route exact path="/buyer/propertyalert" component={BuyerPropertyAlert} />
            <Route exact path="/buyer/subscribe"  component={BuyerSubscribe} />
            <Route exact path="/buyer/package-history" component={BuyerPackageHistory} />
            <Route exact path="/buyer/request-history" component={BuyerRequestHistory} />
            <Route exact path="/buyer/not-listed/request-history" component={BuyerAddedRequestHistory} />
            <Route exact path="/buyer/change-password" component={BuyerChangePassword} />
            <Route exact path="/buyer/seller-chat" component={BuyerSellerChat} />
            <Route exact path="/buyer/support-chat" component={BuyerSubUserChat} />
            <Route exact path="/buyer/test-chat" component={BuyerChatTest} />

             


            <Route exact path="/seller" component={SellerProfile} />
            <Route exact path="/seller/property" component={SellerProperty} />
            <Route exact path="/seller/add-property/:id?" component={SellerAddProperty} />
            <Route exact path="/seller/change-password" component={SellerChangePassword} />
            <Route exact path="/seller/subscribe" component={SellerSubscribe} />
            <Route exact path="/seller/invited" component={SellerInvitedCoupon} />
            <Route exact path="/seller/package-history" component={SellerPackageHistory} />
            <Route exact path="/seller/request-history" component={SellerRequestHistory} />
            <Route exact path="/seller/boost/propertylist" component={SellerBoostProperty} />
            <Route exact path="/seller/boost-history" component={SellerBoostHistory} />             
            <Route exact path="/seller/buyer-chat" component={SellerBuyerChat} />  
                      

            <Route exact path="/agent" component={AgentProfile} />
            <Route exact path="/agent/property" component={AgentProperty} />
            <Route exact path="/agent/buyerproperty" component={AgentBuyerProperty} />
            <Route exact path="/agent/buyeraddedproperty" component={AgentBuyerAddedProperty} />
            <Route exact path="/agent/property/details/:id?" component={ViewPropertyDetails} />
            <Route exact path="/agent/propertybybuyer/details/:id?" component={ViewPropertyDetailsByBuyer} />
            <Route exact path="/agent/propertyaddedbybuyer/details/:id?" component={ViewPropertyDetailsAddedByBuyer} />
            <Route exact path="/agent/change-password" component={AgentChangePassword} />

            <Route exact path="/admin" component={AdminProfile} />
            <Route exact path="/cms" component={Cms} />
            <Route exact path="/edit/cms/:id" component={EditCms} />
            <Route exact path="/admin/change-password" component={AdminChangePassword} />
            <Route exact path="/admin/add-commission/:id" component={AdminAddCommission} />
            <Route exact path="/admin/commission-history" component={AdminCommissionHistory} />
            <Route exact path="/admin/commission-alert-list" component={AdminCommissionAlertList} />
            <Route exact path="/admin/transaction-history" component={AdminTransactionHistory} />
            <Route exact path="/admin/transaction-history/buyer" component={AdminTransactionHistoryBuyer} />
            <Route exact path="/admin/transaction-history/seller" component={AdminTransactionHistorySeller} />
            <Route exact path="/admin/user-management/agent" component={UserManagementAgent} />

            <Route exact path="/admin/user-management/buyer-seller" component={UserManagementBuyerSeller} />
            <Route exact path="/admin/subuserlist" component={AdminListSubUser} />
            <Route exact path="/admin/updatesubuser/:id" component={AdminUpdateSubUser} />
            <Route exact path="/admin/addsubuser" component={AdminAddSubUser} />

            <Route exact path="/admin/verify-property" component={AdminProperty} />
            <Route exact path="/admin/publish-property" component={AdminPublishProperty} />
            <Route exact path="/admin/buyerproperty" component={AdminBuyerProperty} />
            <Route exact path="/admin/buyeraddedproperty" component={AdminBuyerAddedProperty} />
            <Route exact path="/admin/property/details/:id/:from?" component={AdminViewPropertyDetails} />
            <Route exact path="/admin/propertybybuyer/details/:id?" component={AdminViewPropertyDetailsByBuyer} />
            <Route exact path="/admin/propertyaddedbybuyer/details/:id?" component={AdminViewPropertyDetailsAddedByBuyer} />
            <Route exact path="/admin/package" component={AdminPackage} />
            <Route exact path="/admin/package/editpackage/:id" component={EditPackage} />
            <Route exact path="/admin/transaction-history/buyeradded" component={AdminBuyerAddedRequestHistory} />
            <Route exact path="/admin/sub-user-assign" component={SubUserAssign} />
            {/* <Route exact path="/admin/enquiry" exact component={Enquiry} /> */}
            <Route exact path="/admin/enquiry"  component={AdminEnquiry} />
            <Route exact path="/common/property-certificate/:id/:request_id?"  component={PropertyCertificate} />
            
            <Route exact path="/sub-user" component={SubUserProfile} />
            <Route exact path="/sub-user/buyer" component={SubUserBuyer} />
            <Route exact path="/sub-user/chat" component={SubUserChat} />
           
            <Route path="/permission" component={permissionDenied}></Route>
            <Route path="" component={ErrorPage}></Route>
          </Switch>
           </ScrollToTop>
        </Router>

      </div>
    );
  }
}
