import React, { Component } from 'react'
import face1 from '../../../assets/images/profile-2.webp'
import { ReactComponent as Logo} from '../../../assets/images/logo-icon.svg'
import logo_color from '../../../assets/images/genhaven-color.webp'
import  agent from '../../../assets/images/agent.svg'
import { ReactComponent as Lockaccess} from '../../../assets/images/lock-access.svg'
import { ReactComponent as Agent}  from '../../../assets/images/agent.svg'
import { ReactComponent as List} from '../../../assets/images/list.svg'
import { ReactComponent as Add} from '../../../assets/images/plus-lg.svg'
import { ReactComponent as Buildingcommunity}  from '../../../assets/images/building-community.svg'
import { ReactComponent as Transaction}  from '../../../assets/images/transaction.svg'
import { ReactComponent as CMS}  from '../../../assets/images/gear.svg'
import { ReactComponent as Person} from '../../../assets/images/person.svg'
import { ReactComponent as Package} from '../../../assets/images/package.svg'
import { ReactComponent as Rental} from '../../../assets/images/rental.svg'
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'

import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import { Tooltip } from 'reactstrap';
import { APIURL, BASEURL } from '../../../components/constants/common';

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // stylePath:true,
            sidebarClass: "sidebar-icon-only",
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            profile_image: "",
            dashboard: false,
            myProfile: false,
            property: false,
            enquiry: false,
            agentreq: false,
            changePass: false,
            reload:false,
            NoImage:false,
            mobileView: false
            // user:false
        }
    }

    getProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"seller/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        profile_image: response.data.data.url_path,
                        name: response.data.data.name,
                        NoImage:true
                    })
                    console.log("bjsbvjx", this.state.profile_image)
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    dashboard = () => { this.setState({ dashboard: !this.state.dashboard }) }
    myProfile = () => { this.setState({ myProfile: !this.state.myProfile }) }
    property = () => { this.setState({ property: !this.state.property }) }
    enquiry = () => { this.setState({ enquiry: !this.state.enquiry }) }
    agentreq = () => { this.setState({ agentreq: !this.state.agentreq }) }
    changePass = () => { this.setState({ changePass: !this.state.changePass }) }

    componentDidMount() {
        this.getProfileInfo()
    }
   
    goToHome(){
        window.location.href="/";
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    render() {
        
        const { user, name } = this.state
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "Admin") {
            return <Redirect to="/permission" />;
        }
        return (
            <>
                <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                <div className={this.state.mobileView ? "admin-sidebar sidebar_mobile open" : "admin-sidebar sidebar_mobile"}>
                    <div class="brand-logo">
                        <a href="#">
                            {/* <Logo class="img-svg" alt="logo" /> */}
                            <img src={logo_color} alt="genhaven logo" class="img-svg"/>
                        </a>
                    </div>

                    <div class="content">
                        <div>
                            <a href="#" class="d-block">
                                <div class="navprofile d-flex align-items-center">
                                    <div class="navprofile__img">
                                   <span>  
                                        {
                                            this.state.NoImage && this.props.data !== undefined && this.props.data.profile_image !== ""
                                            ? <img src={this.props.data.profile_image} alt="profile" /> 
                                            : this.state.NoImage && <img src={this.state.profile_image !== "" ? this.state.profile_image : agent} alt="profile" />
                                        }
                                    </span>
                                      
                                    </div>
                                    <div>
                                        <div class="navprofile__text ms-3">
                                            <h6 class="font-weight-bold mb-0">{name}</h6>
                                            <span class="text-muted text-small">Admin Panel</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="mt-4">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                 {/* <li class="nav-item">
                                  <Link  className={this.props.activePage === "dashboard" ? "nav-link active" : "nav-link"} to="/admin">
                                  <Gearwide class="img-svg" /><span><span class="ms-3">Dashboard</span></span>
                                  </Link>
                                </li> */}
                                <li class="nav-item">
                                  <Link  className={this.props.activePage === "profile" ? "nav-link active" : "nav-link"} to="/admin">
                                  <Person class="img-svg" /><span><span class="ms-3">My Profile</span></span>
                                  </Link>
                                </li>
                                {/* <li class="nav-item">
                                    <Link className={this.props.activePage === "profile1" ? "nav-link active" : "nav-link"}  class="nav-link" to="/admin/property">
                                    <Buildingcommunity class="img-svg" /><span><span class="ms-3">Property</span></span>
                                    </Link>
                                </li> */}
                                <li class="nav-item">
                                    <h5 className='mt-3'>Property</h5>
                                    <ul className='ms-4'>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "publish-property" ? "nav-link active" : "nav-link"} to="/admin/publish-property">
                                            <Buildingcommunity class="img-svg" /><span><span class="ms-3">Sellers to Publish</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "verify-property" ? "nav-link active" : "nav-link"} to="/admin/verify-property">
                                            <Buildingcommunity class="img-svg" /><span><span class="ms-3">Sellers to Verify</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "buyerproperty" ? "nav-link active" : "nav-link"} to="/admin/buyerproperty">
                                            <Rental class="img-svg" /><span><span class="ms-3">Buyer</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "buyeraddedproperty" ? "nav-link active" : "nav-link"} to="/admin/buyeraddedproperty">
                                            <List class="img-svg" /><span><span class="ms-3">Buyer Added</span></span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <h5 className='mt-3'>Commission</h5>
                                    <ul className='ms-4'>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "commission_history" ? "nav-link active" : "nav-link"} to="/admin/commission-history">
                                            <Transaction class="img-svg" /><span><span class="ms-3">History</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link className={this.props.activePage === "commission_alert_list" ? "nav-link active" : "nav-link"} to="/admin/commission-alert-list">
                                            <List class="img-svg" /><span><span class="ms-3">Alert List</span></span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <h5 className='mt-3'>Verification Transactions</h5>
                                    <ul className='ms-4'>
                                        <li class="nav-item">
                                            <Link  className={this.props.activePage === "seller" ? "nav-link active" : "nav-link"} to="/admin/transaction-history/seller">
                                            <Buildingcommunity class="img-svg" /><span><span class="ms-3">Seller</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link  className={this.props.activePage === "buyer" ? "nav-link active" : "nav-link"} to="/admin/transaction-history/buyer">
                                            <Rental class="img-svg" /><span><span class="ms-3">Buyer</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link  className={this.props.activePage === "buyeradded" ? "nav-link active" : "nav-link"} to="/admin/transaction-history/buyeradded">
                                            <Rental class="img-svg" /><span><span class="ms-3">Buyer Added</span></span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <h5 className='mt-3'>User Management</h5>
                                    <ul className='ms-4'>
                                        <li class="nav-item">
                                            <Link  className={this.props.activePage === "agent" ? "nav-link active" : "nav-link"} to="/admin/user-management/agent">
                                            <Agent class="img-svg" /><span><span class="ms-3">Agent</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link  className={this.props.activePage === "buyerseller" ? "nav-link active" : "nav-link"} to="/admin/user-management/buyer-seller">
                                            <Rental class="img-svg" /><span><span class="ms-3">Buyer/Seller</span></span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <h5 className='mt-3'>SubUser Management</h5>
                                    <ul className='ms-4'>
                                        <li class="nav-item">
                                            <Link  className={this.props.activePage === "subuserlist" ? "nav-link active" : "nav-link"} to="/admin/subuserlist">
                                            <List class="img-svg" /><span><span class="ms-3">SubUser List</span></span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link  className={this.props.activePage === "buyerlist" ? "nav-link active" : "nav-link"} to="/admin/sub-user-assign">
                                            <List class="img-svg" /><span><span class="ms-3">Assign Buyer</span></span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <Link className={this.props.activePage === "transaction_history" ? "nav-link active" : "nav-link"} to="/admin/transaction-history">
                                    <Transaction class="img-svg" /><span><span class="ms-3">Transaction History</span></span>
                                    </Link>
                                </li>

                                <li class="nav-item">
                                    <Link  className={this.props.activePage === "package" ? "nav-link active" : "nav-link"} to="/admin/package">
                                    <Package class="img-svg" /><span><span class="ms-3">Packages </span></span>
                                    </Link>
                                </li>

                                <li class="nav-item">
                                  <Link  className={this.props.activePage === "cms" ? "nav-link active" : "nav-link"} to="/cms">
                                  <CMS class="img-svg" /><span><span class="ms-3">CMS</span></span>
                                  </Link>
                                </li>
                                
                                <li class="nav-item">
                                    <Link  className={this.props.activePage === "change_password" ? "nav-link active" : "nav-link"} to="/admin/change-password">
                                    <Lockaccess class="img-svg" /><span><span class="ms-3">Change Password</span></span>
                                    </Link>
                             </li>                               
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
