import React, { Component } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Footer from '../../../Front/Footer'
import axios from "axios";
import Pagination from "react-js-pagination";
import { APIURL } from '../../../../components/constants/common';
import { Redirect, Link } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";
import { ReactComponent as MenuList}  from '../../../../assets/images/three-dots-vertical.svg'
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import DatePicker from "react-datepicker";

export default class BuyerAddedRequestTransaction extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            errMsg: "",
            scsMsg: "",
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            historyList: [],
            Loader:false,
            norecord:false,
            selectedDate: "",
            showDate: "",
            mobileView: false
        }
    }

    handleSelectedDate(e) {
        this.setState({
            showDate: e,
            selectedDate: dateFormat(e, "yyyy-mm-dd"),
            activePage: 1,
        },()=>{
            this.getTransactionHistory()
        })
    }

    clearDate(){
        this.setState({
            showDate: "",
            selectedDate: "",
            activePage: 1,
        },()=>{
            this.getTransactionHistory()
        })
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getTransactionHistory()
            }
        );
    }
      
    handleSearch(e){
         this.setState({
            search: e,
            activePage: 1
        },()=>{this.getTransactionHistory()});
    }

    getTransactionHistory() {
        this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        //formData.append('search', this.state.search);
        formData.append('datefilter', this.state.selectedDate);
        formData.append('limit', this.state.limit);

        axios
            .post(APIURL + "buyer/get-buyer-added-property-transaction-history-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    historyList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    norecord:true,
                    Loader:false
                })
            })
            .catch((error) => {
                this.setState({
                    norecord:true,
                    Loader:false
                })
            });
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    componentDidMount() {
        window.scrollTo(0,0)
        this.getTransactionHistory();
    }

    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }

        if (this.state.user.user_type !== "Buyer") {
            return <Redirect to="/permission" />;
        }

        return (
            <>
                <Navbar />
                <div class="content-area">
                    {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}

                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account</small></div>
                        </div>
                    </div>
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                        <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-lg-4">
                                <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="buyer_added_request_history" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-lg-8">
                                    <div class="bg-light">
                                        <div class="px-xxl-4 py-4 px-3">
                                            <div>
                                                <div class="d-sm-flex justify-content-between align-items-end">
                                                    <h2 class="mb-3">Request Transaction History</h2>
                                                    <div class="row align-items-end">
                                                        {/* <div class="col-6">
                                                            <Input
                                                                type="text"
                                                                onChange={(e) => this.handleSearch(e.target.value)}
                                                                placeholder="Search"
                                                                className="form-control form-control_new border-0 border-end-0"
                                                            />
                                                        </div> */}
                                                        <div class="col-12">
                                                            {/* <div class="small">Transaction Date</div> */}
                                                                <div className="position-relative">
                                                                    <DatePicker
                                                                        dateFormat="dd/MM/yyyy"
                                                                        selected={this.state.showDate}
                                                                        onSelect={(e) =>this.handleSelectedDate(e)}
                                                                        placeholderText="Transaction Date"
                                                                        className="form-control form-control_new border-0 border-end-0"
                                                                    />
                                                                 <button onClick={() =>this.clearDate()} className="cleardate">x</button>
                                                                </div>
                                                             </div>
                                                        </div>
                                                    </div>
                                                <div class="row">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped table-borderless">
                                                        <thead>
                                                            <tr class="border-bottom">
                                                                <th style={{minWidth:70}}>Sr. No.</th>
                                                                <th style={{minWidth:100}}>Plan Name</th>
                                                                <th style={{minWidth:100}}>Amount (£)</th>
                                                                <th style={{minWidth:120}}>Coupon Applied</th>
                                                                <th style={{minWidth:130}}>Transaction Date</th>                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.historyList.length > 0 && this.state.historyList.map((item, idx) => (
                                                            <tr>
                                                                <td>{idx+1}</td>
                                                                <td>{item.plan_name}</td>
                                                                <td>{item.total_amount}</td>
                                                                <td>{ item.coupon_code !== null &&
                                                                        <span class="badge bg-success">Yes</span>
                                                                    }
                                                                </td>
                                                                <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>                              
                                                            </tr>
                                                        ))}
                                                        {this.state.historyList.length === 0 && this.state.norecord==true && 
                                                            <tr className="text-center">
                                                                <td colspan="6">No record found</td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        {this.state.totalItemsCount > 0 &&
                                                            <div class="justify-content-center d-flex">
                                                                <Pagination
                                                                    activePage={this.state.activePage}
                                                                    itemsCountPerPage={this.state.limit}
                                                                    totalItemsCount={this.state.totalItemsCount}
                                                                    pageRangeDisplayed={5}
                                                                    onChange={this.handlePageChange.bind(this)}
                                                                    itemClass="page-item"
                                                                    linkClass="page-link"
                                                                    innerClass="pagination justify-content-center"
                                                                    activeLinkClass="active"
                                                                    nextPageText="Next"
                                                                    prevPageText="Previous"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
