import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './Sidebar'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import Navbar from './Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import DatePicker from "react-datepicker";
import dateFormat, { masks } from "dateformat";
import Pagination from "react-js-pagination";
import { ReactComponent as EditPencil } from '../../../assets/images/pencil-fill.svg';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            userRecords: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: "",
            selectedPlan: "",
            plans: [],
            selectedDate: "",
            showDate: "",
            search: "",
            status: "",
            noRecordStatus: false
        }
    }

    componentDidMount() {
        this.getAgentList()

    }

    handleSearch(e) {
        this.setState(
            {
                search: e.target.value,
                activePage: 1
            }
            , () => {
                this.getAgentList()
            });
    }

    onLogoutHandler = () => {

        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            {
                activePage: pageNumber
            }
            , () => {
                this.getAgentList()
            }
        );
    }

    handleStatusType(e) {
        this.setState({
            status: e,
            activePage: 1,
        }, () => {
            this.getAgentList()
        })
    }


    getAgentList() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('search', this.state.search);
            formData.append('statusfilter', this.state.status);
            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL + "admin/get-sub-user-list"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    // const info = response.data.data;
                    this.setState({
                        userRecords: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page,
                        noRecordStatus: true
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false,
                        noRecordStatus: true
                    })
                });
        }
    }



    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
            <div className="admin">

                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


                <Sidebar activePage="subuserlist" />
                <div class="content-area">
                    <Navbar />

                    <div class="content">
                        <div className="mb-4 d-flex justify-content-between">
                            {/* <Button onClick={() => this.addPropertyModal()} color="info" className="px-3 btn btn-primary btn-sm">Add Property</Button> */}
                            <Link className="px-3 btn btn-primary btn-sm" to="/admin/addsubuser">Add SubUser</Link>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>SubUser List</span></h2>
                                    <div class="row align-items-end W-500">
                                        <div className="col-12">
                                            <Input
                                                type="text"
                                                onChange={(e) => this.handleSearch(e)}
                                                placeholder="Search"
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                        </div>
                                        {/* <div class="col-6">
                                            <div class="small">Status Type</div>
                                            <select className="form-control form-control_new border-0 border-end-0" onChange={(e) => this.handleStatusType(e.target.value)}>
                                                <option value="selectall">Select All</option>
                                                <option value="active">Active</option>
                                                <option value="deactive">Inactive</option>

                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-striped table-borderless">
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th style={{minWidth:70}}>Sr. No.</th>
                                                    <th style={{minWidth:100}}>Full Name</th>
                                                    <th style={{minWidth:130}}>Email</th>
                                                    <th style={{minWidth:100}}>City</th>
                                                    <th style={{minWidth:100}}>State Name</th>
                                                    {/* <th>Status</th> */}
                                                    <th width="100">Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.userRecords.length > 0 && this.state.userRecords.map((item, idx) => (
                                                    <>
                                                        <tr>
                                                            <td>{idx + 1}</td>
                                                            <td>{item.fullName}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.city_name}</td>
                                                            <td>{item.state_name}</td>
                                                            {/* <td>
                                                            { 
                                                                item.status === "active"
                                                                ? <span class="badge bg-success">Active</span>
                                                                : <span class="badge bg-danger">Inactive</span>
                                                            }
                                                            </td> */}
                                                            <td><Tooltip title="Edit"><Link to={"/admin/updatesubuser/" + item.id} className="btn btn-rounded btn-info"><EditPencil class="img-svg" /></Link></Tooltip></td>
                                                        </tr>
                                                    </>
                                                ))}
                                                { this.state.userRecords.length === 0 && this.state.noRecordStatus==true &&
                                                    <tr className="text-center">
                                                        <td colSpan="6">
                                                            No record found
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-4 text-center">
                                        {/*<ul class="pagination justify-content-center">
                                        <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#" >2</a></li>
                                        <li class="page-item"><a class="page-link" href="#" >3</a></li>
                                    </ul>*/}
                                        {this.state.totalItemsCount > 0 &&
                                            <div class="justify-content-center d-flex">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.state.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination justify-content-center"
                                                    activeLinkClass="active"
                                                    nextPageText="Next"
                                                    prevPageText="Previous"
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}