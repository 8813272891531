import React, { Component } from 'react'
import { Carousel } from 'react-bootstrap';

import axios from "axios";
import { APIURL } from '../../constants/common';
import genhavenWhite from '../../../assets/images/genhaven-white.webp'
import Img1 from '../../../assets/images/image-3.webp'
import Img2 from '../../../assets/images/image-1.webp'
import Img3 from '../../../assets/images/facebook.svg'
import Img4 from '../../../assets/images/linkedin.svg'
import Img5 from '../../../assets/images/twitter.svg'
import Img6 from '../../../assets/images/profile-1.webp'
import Img7 from '../../../assets/images/img-01.png'
import Img8 from '../../../assets/images/img-02.png'
import Img9 from '../../../assets/images/img-03.png'
import Img10 from '../../../assets/images/img-04.png'
import Img11 from '../../../assets/images/img-05.png'
import Navbar from '../../Front/TopNavbar'
import Footer from '../../Front/Footer'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfigurationView = {
	toolbar: [

	],
	ckfinder: {


	},
};

export default class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stylePath: true,
			slug: "about-us",
			cmsData: [],
		}

	}

	componentDidMount() {
		//window.scrollTo(0,0)
		this.getCms()
	}

	getCms = () => {
		//window.scrollTo(0, 0);
		var token = this.state.token
		const formData = new FormData();
		formData.append('url_key', this.state.slug);
		axios
			.post(APIURL + "cms/" + this.state.slug, formData)
			.then((response) => {
				this.setState({
					cmsData: response.data.data
				});
			})
			.catch((error) => {
				// this.setState({
				// 	errMsg: error.response.data.error,
				// })
			});
	};

	render() {
		const { cmsData } = this.state
		// console.log(this.props.match.params.slug)
		return (
			<div>

				{/* Navbar cmp */}
				<Navbar slug="about-us" activePage="about-us" />
				{/* header cmp */}


				<div class="content-area">
					<div class="breadcrumb-area gradient1">
						<div class="container">
							<h1>{cmsData.page_title}</h1>

						</div>
					</div>
					<section id="explore_places" class="burger">
						<div class="container">
							<div class="row">
								<div class="col-lg-12">
									<CKEditor
										editor={Editor}
										config={editorConfigurationView}
										data={cmsData.content}
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</section>
						
				</div>

				{/* footer cmp */}
				<Footer />
			</div>
		)
	}
}
