import React, { Component } from 'react'
import { Carousel } from 'react-bootstrap';

import axios from "axios";
import { APIURL } from '../../constants/common';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Navbar from '../../Front/TopNavbar'
import Footer from '../../Front/Footer'

const editorConfigurationView = {
	toolbar: [

	],
	ckfinder: {


	},
};

export default class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stylePath: true,
			slug: "about-us",
			cmsData: [],
		}

	}

	componentDidMount() {
		//window.scrollTo(0,0)
		this.getCms()
	}

	getCms = () => {
		//window.scrollTo(0, 0);
		var token = this.state.token
		const formData = new FormData();
		formData.append('url_key', this.state.slug);
		axios
			.post(APIURL + "cms/" + this.state.slug, formData)
			.then((response) => {
				this.setState({
					cmsData: response.data.data
				});
			})
			.catch((error) => {
				// this.setState({
				// 	errMsg: error.response.data.error,
				// })
			});
	};

	render() {
		const { cmsData } = this.state
		// console.log(this.props.match.params.slug)
		return (
			<div>

				{/* Navbar cmp */}
				<Navbar slug="about-us" activePage="about-us" />
				{/* header cmp */}


				<div class="content-area">
					<div class="breadcrumb-area gradient1 mb-4">
						<div class="container">
							<h1>Paypal</h1>
						</div>
					</div>
					{/* <PayPalScriptProvider options={{ "client-id": "AbWe-As9NgXer70W18z03Dt1azfH9iUfYPpMeL3xZ1j0vtx9zmQssTbmRn-6RJQZtFlQ3RJu0GFfXhXX&currency=USD" }}>
                        <PayPalButtons style={{ layout: "horizontal" }} />
                    </PayPalScriptProvider> */}
					<PayPalScriptProvider options={{ "client-id": "AbWe-As9NgXer70W18z03Dt1azfH9iUfYPpMeL3xZ1j0vtx9zmQssTbmRn-6RJQZtFlQ3RJu0GFfXhXX&currency=USD" }}>
						<PayPalButtons
							className='btn btn-secondary'
							fundingSource="paypal"
							createOrder={(data, actions) => {
								return actions.order.create({
									purchase_units: [
										{
											amount: {
												value: "0.01",
											},
										},
									],
								});
							}}
							onApprove={(data, actions) => {
								return actions.order.capture().then((details) => {
									const name = details.payer.name.given_name;
									console.log(details);
								});
							}}
						/>
					</PayPalScriptProvider>
				</div>

				{/* footer cmp */}
				<Footer />
			</div>
		)
	}
}
