import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './Sidebar'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import Navbar from './Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner } from 'reactstrap'

export default class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            current_password: "",
            password: "",
            confirm_password: "",
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            currShowPassword: false,
            newShowPassword: false,
            confirmShowPassword: false,
            fullscrLoader: false
        }
    }

    componentDidMount() {
        this.getProfileInfo()
        console.log(this.state.user_type)
    }

    currShowPassword() {
        this.setState({
            currShowPassword: !this.state.currShowPassword
        })
    }
    newShowPassword() {
        this.setState({
            newShowPassword: !this.state.newShowPassword
        })
    }
    confirmShowPassword() {
        this.setState({
            confirmShowPassword: !this.state.confirmShowPassword
        })
    }

    //form handler
  /*  onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({ userInfo ,
            errMsg:"" });
        console.log(userInfo)
    };*/

    handleCurrentPassword(e) {
        this.setState({
            current_password: e.target.value
        })
    };

    handlePassword(e) {
        this.setState({
            password: e.target.value
        })
    };

    handleConfirmPassword(e) {
        this.setState({
            confirm_password: e.target.value
        })
    };


    onLogoutHandler = () => {

        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };
    getProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"admin/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        profile_image: response.data.data.profile_image,
                    })

                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    onSubmitHandler = (e) => {
        var token = this.state.token
        const formData = new FormData();
        // formData.append('token', this.state.token);
        formData.append('email', this.state.user.email);
        formData.append('current_password', this.state.current_password);
        formData.append('password', this.state.password);
        formData.append('confirm_password', this.state.confirm_password);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "change-password/update", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Loader: false });
                this.setState({
                    scsMsg: response.data.message,
                });
                setTimeout(() => this.setState({
                    scsMsg: "",
                    current_password: "",
                    password: "",
                    confirm_password: ""
                }), 3000);
            })
            .catch((error) => {
                console.log(error.response.data.error)
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
                setTimeout(() => this.setState({
                    errMsg: "",
                    current_password: "",
                    password: "",
                    confirm_password: ""
                }), 3000);
            });
    };


    render() {
         if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
           <div className="admin">
           
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


               <Sidebar activePage="change_password" />
                <div class="content-area">
                 <Navbar />

                  <div class="content">
                    <div class="container">
                      <div class="card">
                        <div class="card-body">
                          <div class="px-xxl-4 py-4 px-3">
                            <div>
                              <div>
                                <h2 id="page_headeing"><span>Change Password</span></h2>
                                <hr />
                                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                {/* messgae err */}
                                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                              </div>
                              <form>
                              <div class="row">
                                <div class="col-md-6 col-sm-6 mb-4">
                                <label for="exampleInputPassword1" class="form-label">Old Password</label>
                                <div class="icon-input">
                                        <input
                                            className="form-control"
                                            required=""
                                            type={this.state.currShowPassword ? "text" : "password"}
                                            name="password"
                                            placeholder="Old Password"
                                            value={this.state.current_password}
                                            onChange={(e)=>this.handleCurrentPassword(e)}
                                        />
                                        {this.state.currShowPassword ?
                                            <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.currShowPassword()} className="fas fa-eye-slash"></i> </span>
                                            :
                                            <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.currShowPassword()} className="fas fa-eye"></i> </span>
                                        }
       
                                    <span className="text-danger">{this.state.errMsg.current_password}</span>
                                </div>
                                </div>
                                </div>
                                <hr />
                                <div class="row">
                                <div class="col-md-6 col-sm-6 mb-4">
                                <label for="exampleInputPassword1" class="form-label">New Password</label>
                                <div class="icon-input">
                                       <input
                                                className="form-control"
                                                required=""
                                                type={this.state.newShowPassword ? "text" : "password"}
                                                name="password"
                                                placeholder="New Password"
                                                value={this.state.password}
                                                onChange={(e)=>this.handlePassword(e)}
                                            />
                                            {this.state.newShowPassword ?
                                                <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.newShowPassword()} className="fas fa-eye-slash"></i> </span>
                                                :
                                                <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.newShowPassword()} className="fas fa-eye"></i> </span>
                                            }
                                      
                                        <span className="text-danger">{this.state.errMsg.password}</span>
                                </div>
                                </div>
                                
                                <div class="col-md-6 col-sm-6 mb-5">
                                <label for="exampleInputPassword1" class="form-label">Confirm Password</label>
                                <div class="icon-input">
                                       <input
                                            className="form-control"
                                            required=""
                                            type={this.state.confirmShowPassword ? "text" : "password"}
                                            name="confirm_password"
                                            placeholder="Confirm Password"
                                            value={this.state.confirm_password}
                                            onChange={(e)=>this.handleConfirmPassword(e)}
                                        />
                                        {this.state.confirmShowPassword ?
                                            <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.confirmShowPassword()} className="fas fa-eye-slash"></i> </span>
                                            :
                                            <span className="ad-fa-eye-pass input-visiblity"> <i onClick={() => this.confirmShowPassword()} className="fas fa-eye"></i> </span>
                                        }
                                    <span className="text-danger">{this.state.errMsg.confirm_password}</span>
                                </div>
                                </div>
                                </div>
                                <div>
                                  <button type="button" onClick={() => this.onSubmitHandler()} class="btn btn-primary">Save</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                          
            </div>
        )
    }
}