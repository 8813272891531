import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './Sidebar'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import Navbar from './Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

export default class TransactionHistory extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            transactionRecords: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: "",
            selectedPlan: "",
            plans: [],
            selectedDate: "",
            showDate: "",
            search: "",
            noRecordStatus: false
        }
    }

    componentDidMount() {
        this.getCommissionHistory()
    }

    onLogoutHandler = () => {

        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getCommissionHistory()
            }
        );
    }

    handleSelectedDate(e) {
        this.setState({
            showDate: e,
            selectedDate: dateFormat(e, "yyyy-mm-dd"),
            activePage: 1,
        },()=>{
            this.getCommissionHistory()
        })
    }

    clearDate(){
        this.setState({
            showDate: "",
            selectedDate: "",
            activePage: 1,
        },()=>{
            this.getCommissionHistory()
        })
    }

    handleSearch(e) {
        this.setState(
            { search: e.target.value, activePage: 1 }
            , () => { this.getCommissionHistory() });
    }

    getCommissionHistory() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('datefilter', this.state.selectedDate);
            formData.append('search', this.state.search);
            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL+"admin/get-seller-commission-history-list"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        transactionRecords: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page,
                        noRecordStatus: true,
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        noRecordStatus: true,
                        Loader: false
                    })
                });
        }
    }

    render() {
         if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
           <div className="admin">
           
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


               <Sidebar activePage="commission_history" />
                <div class="content-area">
                 <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 id="page_headeing mb-0"><span>Commission History</span></h2>
                                <div class="row align-items-end w-500">
                                    <div className="col-6">
                                        <div className="small"></div>
                                        <Input 
                                            type="text" 
                                            onChange={(e) => this.handleSearch(e)} 
                                            placeholder="Search" 
                                            className="form-control form-control_new border-0 border-end-0"
                                        />
                                    </div>
                                    <div class="col-6">
                                        <div class="small">Date</div>
                                        <div className="position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.showDate}
                                                //isClearable={true}
                                                onSelect={(e) =>this.handleSelectedDate(e)}
                                                placeholderText="Date"
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                        <button onClick={() =>this.clearDate()} className="cleardate">x</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                <thead>
                                    <tr class="border-bottom">
                                        <th style={{minWidth:70}}>Sr. No.</th>
                                        <th style={{minWidth:120}}>Seller Name</th>
                                        <th style={{minWidth:100}}>Date</th>                                      
                                        <th style={{minWidth:130}}>Verification Count</th>
                                        <th>Amount (£)</th>
                                        {/* <th>Expired Date</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.transactionRecords.length > 0 && this.state.transactionRecords.map((item, idx) => (
                                    <>
                                    <tr>
                                        <td>{idx+1}</td>
                                        <td>{item.seller_name}</td>
                                        <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                                        <td>{item.property_count_to_pay}</td>
                                        <td>{item.commission_pay}</td>
                                    </tr>
                                    </>
                                ))}
                                { this.state.transactionRecords.length === 0 && this.state.noRecordStatus==true &&
                                    <tr className="text-center">
                                        <td colSpan="5">
                                            No record found
                                        </td>
                                    </tr>
                                }
                                </tbody>
                                </table>
                            </div>
                            <div class="mt-4 text-center">
                                {/*<ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >2</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >3</a></li>
                                </ul>*/}
                                {this.state.totalItemsCount>0 && 
                                    <div class="justify-content-center d-flex">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                    </div>
                                }
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                          
            </div>
        )
    }
}