import React, { Component } from 'react'
import logo from '../../../assets/images/genhaven-color.webp'
import { ReactComponent as Home} from '../../../assets/images/home.svg'
import {ReactComponent as About} from '../../../assets/images/sales.svg'
import {ReactComponent as Contact} from '../../../assets/images/envelope.svg'
import { ReactComponent as Sales} from '../../../assets/images/sales.svg'
import { ReactComponent as Buyes} from '../../../assets/images/buyes.svg'
import { ReactComponent as Faq} from '../../../assets/images/faq.svg'
import { ReactComponent as Rental} from '../../../assets/images/rental.svg'
import { ReactComponent as FindProperty}  from '../../../assets/images/find-property.svg'
import search from '../../../assets/images/search.svg'
import person from '../../../assets/images/person.svg'
import heart from '../../../assets/images/heart.svg' 
    
import { Link } from 'react-router-dom'

export default class Navbar extends Component {
	constructor() {
        super();
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false
        }
    }

    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };
    	
    render() {
       
		const {user_type} = this.state
		return (
			<>
                 <nav class="navbar navbar-expand-lg navbar-light bg-light top_area">
                  <div class="container">
                    <Link to="/" class="navbar-brand"> <img src={logo} alt="genhaven logo" /> </Link>
    
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                       
                        <li class="nav-item">
                          <Link class="nav-link" to="/">
                            <Home className="img-svg" alt="home" /> <span>Home</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/page/about-us" className="nav-link">
                            <About className="img-svg" />
                            <span>About Us</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/contact-us" className="nav-link">
                            <Contact className="img-svg" alt="rental"  />
                            <span>Contact Us</span>
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link class="nav-link" to="/search">
                            <FindProperty className="img-svg" alt="find property" /> <span>Find Next Property</span>
                          </Link>
                        </li>
                    
                      </ul>
                      
                      
                    </div>
                  </div>
                </nav>
			</>
		)
	}
}



