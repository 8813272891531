import React, { Component } from 'react'

export default class MoreFeature extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true
		}
	}
	componentDidMount(){
          
	}
	render() {
		return (
		<div>	
			 <section id="more-feature">
		        <div class="container">
		          <div class="row">
		            <div class="col-sm-4">
		              <a href="#" class="more_feature feature_one">
		                <h4>How much is my home worth?</h4>
		                <p>Check Estimate Price</p>
		              </a>
		            </div>
		            <div class="col-sm-4">
		              <a href="#" class="more_feature feature_two">
		                <h4>Get closer to what matters</h4>
		                <p>Search homes by travel time</p>
		              </a>
		            </div>
		            <div class="col-sm-4">
		              <a href="#" class="more_feature feature_three">
		                <h4>Find an agent</h4>
		                <p>Look for local agents</p>
		              </a>
		            </div>
		          </div>
		        </div>
		     </section>

		</div>	
		)
	}
}
