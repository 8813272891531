import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from "axios";
import { APIURL, PAYPAL_CLIENT_ID } from '../../../components/constants/common';
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'

export default class Subscribe extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            reload:false,
            plans: [],
            errMsg: "",
            scsMsg: "",
            basePlan: 1,
            premiumPlan: "1",
            paymentModal: false,
            amountToPay: 0,
            id: ""
        }
    }

    paymentModal(amount, id) {
        this.setState({
            paymentModal: !this.state.paymentModal,
            amountToPay: this.state.premiumPlan === "1" ? amount : this.state.premiumPlan === "12" ? ((amount * this.state.premiumPlan)*(0.85)) : ((amount * this.state.premiumPlan)*(0.9)),
            id: id
        })
    }

    paymentModalClose() {
        this.setState({
            paymentModal: false
        })
    }

    handleBasePlan(e) {
        this.setState({
            basePlan: e
        })
    }

    handlePremiumPlan(e) {
        this.setState({
            premiumPlan: e
        })
    }

    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    componentDidMount() {
        this.getSubscriptionPlans()
    }

    getSubscriptionPlans() {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"get-subscription-plan-list"
            const formData = new FormData();
            // formData.append('token', this.state.token);
           
           axios
                .post(app_url,null, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        plans: response.data.data
                    })
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.error ,
                    })
                });
        }
    }

    buySubscriptionPlan(plan_id) {
        if (this.state.user) {
            var token = this.state.token
            var app_url = APIURL+"subscription-plan-purchase"
            const formData = new FormData();
            formData.append('user_id', this.state.user.id);
            formData.append('plan_id', plan_id);
            formData.append('months', this.state.premiumPlan);
           
           axios
                .post(app_url,formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    
                    this.setState({
                        premiumPlan: "1",
                        paymentModal: false,
                        scsMsg: response.data.message,
                    }, () => {
                        setTimeout(() => this.setState({ 
                            scsMsg: ""
                        }), 4000);
                    })
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.error ,
                    })
                });
        }
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if(this.state.user.user_type !== "Seller"){
            return <Redirect to="/permission" />;
        }
        return (
            <div className="admin">
            <Sidebar  activePage="subscribe"/>
                <div class="content-area">
                  <Navbar />

                 <div class="content">
                    <div class="d-flex justify-content-between align-items-center">
                      <h2 id="page_headeing"><span>My Subscription Plans</span></h2>
                    </div>
                    <hr />
                    {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                                    {/* messgae err */}
                    {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                    <div class="row">
                    {this.state.plans.length > 0 && this.state.plans.map((item, idx) => (
                        <>
                        {/* {   item.plan_name == "Basic" &&
                              <div class="col-md-6 col-sm-6">
                                <div class="card subscribeplans">
                                  <div class="card-body">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h2 class="subscribeplans__heading">Basic</h2>
                                    <div>
                                        <div class="d-flex justify-content-between align-items-center">
                                        <div class="price"><h3>${item.amount * this.state.basePlan}</h3></div>
                                        <div>
                                            <select class="form-control" value={this.state.basePlan} onChange={(e) => this.handleBasePlan(e.target.value)}>
                                                <option>Select Month</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <hr />
                                    <h4 class="subscribeplans__subheading">Features</h4>
                                    <ul class="subscribeplans__feature">
                                      <li>Profile Name Identifiable</li>
                                      <li>Standard Checkout</li>
                                      <li>Individual Shopper</li>
                                      <li>Non Transferrable Verification</li>
                                      <li>No Referral Discount</li>
                                      <li>Free Chatwith Seller</li>
                                      <li>Free to Search/Browse</li>
                                      <li>Create Property Alert</li>
                                    </ul>
                                    <div class="btn btn-primary" onClick={() => this.buySubscriptionPlan(item.id)}>Buy Plan</div>
                                  </div>
                                </div>
                              </div>
                        } */}
                        {   item.plan_name == "Premium Plan" &&
                        <div class="col-md-6 col-lg-4">
                            <div class="card subscribeplans">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h3 class="subscribeplans__heading">Premium </h3>
                                            <div>
                                                <select class="form-control" value={this.state.premiumPlan} onChange={(e) => this.handlePremiumPlan(e.target.value)}>
                                                    <option value="">Select Month</option>
                                                    <option value="1">1 Month</option>
                                                    <option value="3">3 Months</option>
                                                    <option value="6">6 Months</option>
                                                    <option value="12">12 Months</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="ml-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                {
                                                    this.state.premiumPlan === "1"
                                                    ? <div class="price"><h4> £{item.amount}</h4></div>
                                                    : this.state.premiumPlan === "12"
                                                    ? <div class="price"><h4> £{(item.amount * this.state.premiumPlan)*(0.85)}</h4></div>
                                                    : <div class="price"><h4> £{(item.amount * this.state.premiumPlan)*(0.9)}</h4></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                <hr />
                                <h4 class="subscribeplans__subheading">Features</h4>
                                <ul class="subscribeplans__feature">
                                  <li>Incognito Mode</li>
                                  <li>Easier/Faster Checkout</li>
                                  <li>Executive purchase(E2E/P2P)</li>
                                  <li>Transferrable Verification</li>
                                  <li>Referral Discount (Unique User)</li>
                                  <li>Free Chatwith Seller</li>
                                  <li>In App Personal Shopper Chat with Recruiter/Assistance</li>
                                  <li>Create Property Alert</li>
                                </ul>
                                <div class="btn btn-primary" onClick={() => this.paymentModal(item.amount, item.id)}>Buy Plan</div>
                              </div>
                            </div>
                          </div>
                        }
                        </>
                        ))}
                        </div>
                     </div>
                </div>

                <Modal size="md" isOpen={this.state.paymentModal} toggle={() => this.paymentModalClose()}>
                    <ModalHeader className="" toggle={() => this.paymentModalClose()}>
                        Choose Payment Option<br />
                        <span className="text-danger">{this.state.errMsgCmt}</span>
                    </ModalHeader>
                    <ModalBody className="border-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID,}}>
                                <PayPalButtons
                                    //fundingSource="paypal"
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            purchase_units: [
                                                {
                                                    amount: {
                                                        value: this.state.amountToPay,
                                                    },
                                                },
                                            ],
                                            application_context: {
                                                shipping_preference: 'NO_SHIPPING'
                                            }
                                        });
                                    }}
                                    onApprove={(data, actions) => {
                                        return actions.order.capture().then((details) => {
                                            const name = details.payer.name.given_name;
                                            {
                                                details.status === "COMPLETED" &&
                                                this.buySubscriptionPlan(this.state.id)
                                            }
                                        });
                                    }}
                                />
                                </PayPalScriptProvider>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button type="button" className="btn btn-light" onClick={() => this.paymentModalClose()}>Close</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
