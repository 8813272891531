import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import Header from './Header'
import axios from "axios";
import Pagination from "react-js-pagination";
import { APIURL } from '../../../components/constants/common';
import { Redirect, Link } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";
import ReactTooltip from 'react-tooltip';
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import { ReactComponent as EditPencil } from '../../../assets/images/pencil-fill.svg';
import { ReactComponent as CommentIcon } from '../../../assets/images/comment.svg';
import { ReactComponent as FileIcon } from '../../../assets/images/file-text.svg';
import { ReactComponent as Check} from '../../../assets/images/check-circle1.svg';
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'

export default class PropertyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            errMsg: "",
            scsMsg: "",
            PropertyInfo: [],
            isLoading: false,
            imageData: [],
            isOpen: false,
            photoIndex: 0,
            mobileView: false
        }
    }


    componentDidMount() {
        this.ViewPropertyInfo(this.props.match.params.id);
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    ViewPropertyInfo = (e) => {
        var token = this.state.token
        const formData = new FormData();
        formData.append('property_id', e);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "view-buyer-property-detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    PropertyInfo: response.data.data,
                })
                let Images = [];
                
                this.state.PropertyInfo.map((item, idx) => {
                    item.property_media.map((result, index) => {
                        result.type === "image" &&
                        Images.push({
                            url_path: result.url_path,
                            type: result.type,
                            caption: ""
                        })
                    });
                })

                this.setState({
                    imageData: Images,
                })
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };

    render() {
        return (
            <>
                <Navbar />
                <div class="content-area">
                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account</small></div>
                        </div>
                    </div>
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                    <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-lg-4">
                                    <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="addproperty" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-lg-8">
                                    <div class="bg-light">
                                        <div class="px-xxl-4 py-4 px-3">
                                            <div>
                                                <div class="d-flex justify-content-between align-items-end">
                                                    <h2 class="mb-3">Property Details</h2>
                                                </div>
                                                <form>
                                                {   this.state.PropertyInfo.length > 0 && this.state.PropertyInfo.map((item, index) => (
                                                    <div class="submit-section">
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label"> Property Name</label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder="Property Name"
                                                                        value={item.name}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Address</label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        value={item.address}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label for="country" class="form-label">Country</label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="Country"
                                                                        placeholder="Country"
                                                                        value={item.country_name}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label for="state" class="form-label">State</label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="State"
                                                                        placeholder="State"
                                                                        value={item.state_name}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label for="locality" class="form-label">Locality</label>
                                                                    <Input
                                                                        rows="4"
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="locality"
                                                                        placeholder="Locality"
                                                                        value={item.city_name}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Nearby Bus Stop</label>
                                                                    <input
                                                                        className="form-control"
                                                                        required=""
                                                                        type="text"
                                                                        name="near_by_bus_stop"
                                                                        placeholder="Nearby Bus Stop"
                                                                        value={item.near_by_bus_stop}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <h5>Verification Requested</h5>
                                                            <div className="col-sm-4">
                                                                <div className="mb-3">
                                                                    <label for="property_title" class="form-label">Property Title</label>
                                                                    <div className="d-flex">
                                                                        <div> 
                                                                            <Check class="img-svg" alt="" />
                                                                            <label class="form-check-label ms-2">{item.property_title_check}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="mb-3">
                                                                    <label for="survey_doc" class="form-label">Survey Document</label>
                                                                    <div className="d-flex">
                                                                        <div> 
                                                                            <Check class="img-svg" alt="" />
                                                                            <label class="form-check-label ms-2">{item.survey_document_check}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="mb-3">
                                                                    <label for="site_visit" class="form-label">Site Visit</label>
                                                                    <div className="d-flex">
                                                                        <div> 
                                                                            <Check class="img-svg" alt="" />
                                                                            <label class="form-check-label ms-2">{item.site_visit_check}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <div class="mb-4">
                                                                    <label class="form-label">Description</label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        rows="8"
                                                                        required=""
                                                                        type="text"
                                                                        name="description"
                                                                        placeholder="Description"
                                                                        value={item.description}
                                                                        disabled
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {   (item.hands_free_service === "true" || item.hands_free_service === true) &&
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="mb-4 d-flex">
                                                                        <div><Check class="img-svg" alt="" /></div>
                                                                        <label class="form-label" className='ms-2'>Hands free service</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {   (item.verification_acceptance_check === "true" || item.verification_acceptance_check === true) &&
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="mb-4 d-flex">
                                                                        <div><Check class="img-svg" alt="" /></div>
                                                                        <label class="form-label" className='ms-2'>This document contains information in connection with the verification requested for the above property.
                                                                    Issuance of the certificate does not in ANY way mean that your acquisition is fool proof if standard acquisition and 
                                                                    conveyancing process is not engaged</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }                                                   
                                                        <div className="row deatil_features">
                                                            <div className="col-sm-12 img_gallery">
                                                            <div>
                                                                <h5>Images</h5>
                                                                {this.state.imageData.length > 0 && this.state.imageData.map((item, idx) => (
                                                                    
                                                                    item.type === "image" &&
                                                                    <a href="#" onClick={() => this.setState({ isOpen: true })}><img src={item.url_path} class="img-thumbnail" alt="logo" /></a>
                                                                        
                                                                ))}
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))}
                                                        {this.state.isOpen && (
                                                            <Lightbox
                                                                mainSrc={this.state.imageData[this.state.photoIndex].url_path}
                                                                nextSrc={this.state.imageData[(this.state.photoIndex + 1) % this.state.imageData.length].url_path}
                                                                prevSrc={this.state.imageData[(this.state.photoIndex + this.state.imageData.length - 1) % this.state.imageData.length].url_path}
                                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                                onMovePrevRequest={() =>
                                                                this.setState({
                                                                    photoIndex: (this.state.photoIndex + this.state.imageData.length - 1) % this.state.imageData.length,
                                                                })
                                                                }
                                                                onMoveNextRequest={() =>
                                                                this.setState({
                                                                    photoIndex: (this.state.photoIndex + 1) % this.state.imageData.length,
                                                                })
                                                                }
                                                            />
                                                        )}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
