import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './../Sidebar'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
import Navbar from './../Navbar'
import { Button, Input } from 'reactstrap'
import { Spinner } from 'reactstrap'
// import dateFormat, { masks } from "dateformat";
// import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { ReactComponent as EditPencil } from '../../../../assets/images/pencil-fill.svg';

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            userInfo: {
                plan_id: "",
                amount: "",
                plan_name: ""
            },
            errMsg: "",
            scsMsg: "",
            redirect: false
        }
    }
    componentDidMount() {
        this.getEditPackage()
    }
    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({
            userInfo,
            errMsg: ""
        });
    };


    getEditPackage() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('plan_id', this.props.match.params.id);
            var token = this.state.token
            var app_url = APIURL + "admin/edit-subscription-plan"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("plan_id", response.data.data)
                    const info = response.data.data;
                    this.setState({
                        userInfo: {
                            plan_id: info.plan_id,
                            amount: info.amount,
                            plan_name: info.plan_name
                        },
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    handlePlanName(e) {
        this.setState({
            plan_name: e
        }, () => {
            console.log("tav", this.state.plan_name)

        })
    };

    onSubmitHandler = (e) => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        // formData.append('plan_id,', this.state.id);
        formData.append('plan_id', this.props.match.params.id);
        formData.append('amount', this.state.userInfo.amount);
        // formData.append('plan_name', this.state.plan_name);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "admin/update-subscription-plan", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    scsMsg: response.data.message,
                    errMsg: "",

                }, () => {
                    setTimeout(() => this.setState({
                        scsMsg: "",
                        redirect: true
                    }), 4000);
                });
                this.getEditPackage()
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };

    render() {
        // console.log("rakjg",this.props)
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.redirect) {
            return <Redirect to="/admin/package" />;
        }
        return (
            <div className="admin">

                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}

                <Sidebar activePage="package" />
                <div class="content-area">
                    <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>Edit Packages</span></h2>
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="row align-items-end">



                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <form>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="exampleInputName1" class="form-label">Plan Name <strong className="text-danger" >*</strong></label>
                                                <select className="form-control"
                                                    value={this.state.plan_name}
                                                    onChange={(e) => this.handlePlanName(e.target.value)} disabled>
                                                    <option value="">{this.state.userInfo.plan_name}</option>
                                                    <option value="Basic">Basic</option>
                                                    <option value="Premium">Premium</option>
                                                    <option value="Request">Request</option>
                                                </select>
                                                <span className="text-danger">{this.state.errMsg.plan_name}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-4">
                                                <label for="exampleInputName1" class="form-label">Amount (£)<strong className="text-danger" >*</strong></label>
                                                <input
                                                    className="form-control"
                                                    required=""
                                                    type="text"
                                                    name="amount"
                                                    placeholder="Amount"
                                                    value={this.state.userInfo.amount}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.amount}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                    </div>
                                    <div class="row">
                                    </div>
                                    <div>
                                        <Button
                                            color="btn btn-primary"
                                            className="mr-2"
                                            onClick={() => this.onSubmitHandler()}
                                        >Update</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}