import React, { Component } from 'react'
import { Button, Spinner, Input} from 'reactstrap'
import axios from "axios";
import NumberFormat from 'react-number-format';
import { APIURL } from '../../../../components/constants/common';
// import { Helmet } from "react-helmet";

export default class PropertyInfo extends Component {
    constructor() {
        super();

        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            property_id: JSON.parse(localStorage.getItem("property_id")),
            userInfo: {
                propertyName:"",
                yearBuilt: "",
                houseDesc: "",
                size: ""
            },
            listingPrice: "",
            errMsg: "",
            Loader: false,
            propertyStatus: "",
            propertyCategory:"",
            subCategory: "",
            category_list: [],
            sub_category_list: [],
            denomination: "",
            purpose: "For Sale"
        }
    }

    componentDidMount() {
        this.getPropertyInfo()
        // this.getPropertyList()
    }

    getSubCategory(){
        const formData = new FormData();
           formData.append('category_id', this.state.propertyCategory);
           var token = this.state.token
           axios
               .post(APIURL + "get-sub-category-list", formData, {
                   headers: {
                       'Authorization': `Bearer ${token}`
                   }
               })
               .then((response) => {
                   this.setState({
                       sub_category_list: response.data.data,
                   })
               })
               .catch((error) => {
                   this.setState({
                       errMsg: error.response.data.errors,
                       Loader: false
                   })
               });
   }

    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({
            userInfo,
            errMsg: ""
        });
    };

    handlesubCategory = (e) =>{
        this.setState({
            subCategory: e
        })
    }

    handleCategory = (e) =>{
        this.setState({
            propertyCategory: e
        }, () => {
            this.getSubCategory()
        })
    }

    handleDenomination = (e) =>{
        this.setState({
            denomination: e
        })
    }

    handlePurpose = (e) =>{
        this.setState({
            purpose: e
        })
    }

    handleListingPrice = (e) =>{
        this.setState({
            listingPrice: e
        })
    }

    getPropertyInfo() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('property_id', this.props.data.property_id);
            var token = this.state.token
            axios
                .post(APIURL + "seller/property-info-edit", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        userInfo: {
                            propertyName:info.name,
                            yearBuilt: info.year_built === null ? "" : info.year_built,
                            houseDesc: info.house_description === null ? "" : info.house_description,
                            size: info.size === null ? "" : info.size
                        },
                        listingPrice:info.listing_price === "0.00" ? "" : info.listing_price,
                        propertyStatus: info.property_request_status,
                        propertyCategory: info.categoryArray.category_id === null ? "" : info.categoryArray.category_id,
                        subCategory: info.categoryArray.sub_category_id === null ? "" : info.categoryArray.sub_category_id,
                        category_list: info.category_list === null ? "" : info.category_list,
                        sub_category_list: info.sub_category_list === null ? "" : info.sub_category_list,
                        denomination: info.denomination === null ? "" : info.denomination,
                        purpose: info.purpose === null ? "" : info.purpose
                    })
                    this.props.data.paymentMode(info.payment_mode);
                    this.props.data.getStatus(info.property_request_status);
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    updatePropertyInfo = (e) => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('name', this.state.userInfo.propertyName ? this.state.userInfo.propertyName : "");
        formData.append('listing_price', this.state.listingPrice ? this.state.listingPrice.replace(/,/g, '') : "");
        formData.append('year_built', this.state.userInfo.yearBuilt ? this.state.userInfo.yearBuilt : "");
        formData.append('house_description', this.state.userInfo.houseDesc ? this.state.userInfo.houseDesc : "");
        formData.append('size', this.state.userInfo.size ? this.state.userInfo.size : "");
        formData.append('propertyCategory', this.state.propertyCategory ? this.state.propertyCategory : "");
        formData.append('propertySubcategory', this.state.subCategory ? this.state.subCategory : "");
        // formData.append('purpose', this.state.purpose ? this.state.purpose : "");
        formData.append('purpose', "For Sale");
        formData.append('denomination', this.state.denomination ? this.state.denomination : "");
        formData.append('property_id', this.state.property_id ? this.state.property_id : this.props.data.property_id);

        this.setState({ Loader: true });
        axios
            .post(APIURL + "seller/property-info-update", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    
                }, () => {
                    this.props.data.setMessage(response.data.message);
                    this.props.data.handleActiveTab("2");
                    this.props.data.checkPropertyDetails();
                });
                setTimeout(() => this.setState({ scsMsg: "", }), 4000);
            })
            .catch((error) => {
                console.log(error.response.data)
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false,

                })
            });
    };
    
    render() {
        console.log("info",this.props)
        console.log(this.state.scsMsg);
        return (
            <>
                {/* <Helmet>
                    <link rel="stylesheet" href="http://192.168.1.123/rem/css/custom.css" />
                </Helmet> */}
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {/* {this.state.errMsg.message ?
                    <div class="alert alert-danger text-center mt-3" role="alert">
                        {this.state.errMsg.message}
                    </div>
                    : ""} */}
                
                <div className={this.props.data.activatedTab === "propertyInfo" ? "tab-pane fade show" : "tab-pane fade"} id="pills-info" role="tabpanel" aria-labelledby="pills-info-tab">
                    <form class="forms-sample ">
                        <h4>General Info</h4>
                        <hr />
                        <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="propertyName">Property Name <strong class="text-danger">*</strong> </label>
                            <Input
                                class="form-control"
                                type="text"
                                name="propertyName"
                                placeholder="Property Name"
                                value = {this.state.userInfo.propertyName}
                                onChange = {this.onChangehandler}
                            />
                            <span className="text-danger">{this.state.errMsg.name}</span>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="purpose">Purpose <strong class="text-danger">*</strong> </label>
                            <select class="form-control" value={this.state.purpose} onChange={(e) => this.handlePurpose(e.target.value)} disabled>
                                {/* <option value="">Select Purpose</option> 
                                <option value="For Rent">For Rent</option> */}
                                <option value="For Sale">For Sale</option>
                                {/* <option value="Short let">Short let</option> */}
                            </select>
                            <span className="text-danger">{this.state.errMsg.purpose}</span>
                            </div>
                        </div>

                        {/* <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="propertyCategory">Property Category <strong class="text-danger">*</strong> </label>
                            <Input
                                class="form-control"
                                type="text"
                                name="propertyCategory"
                                placeholder="Property Category"
                                value = {this.state.userInfo.propertyCategory}
                                onChange = {this.onChangehandler}
                                disabled
                            />
                            </div>
                        </div> */}

                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="propertyCategory">Property Category <strong class="text-danger">*</strong> </label>
                            <select className="form-control" value={this.state.propertyCategory} onChange={(e) => this.handleCategory(e.target.value)}>
                                <option value="">Select Category</option>
                                {this.state.category_list.length > 0 && this.state.category_list.map((category, idx) => (
                                    <option value={category.id}>{category.category_name}</option>
                                ))}
                            </select>
                            <span className="text-danger">{this.state.errMsg.propertyCategory}</span>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="propertySubCategory">Property Sub-Category <strong class="text-danger">*</strong> </label>
                            <select className="form-control" value={this.state.subCategory} onChange={(e) => this.handlesubCategory(e.target.value)}>
                                <option value="">Select Sub-Category</option>
                                {this.state.sub_category_list.length > 0 && this.state.sub_category_list.map((subCategory, idx) => (
                                    <option value={subCategory.id}>{subCategory.category_name}</option>
                                ))}
                            </select>
                            <span className="text-danger">{this.state.errMsg.propertySubcategory}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="size">Size(Sqm) <strong class="text-danger">*</strong> </label>
                            <Input
                                class="form-control"
                                type="number"
                                min="0"
                                name="size"
                                placeholder="Size (Sqm)"
                                value = {this.state.userInfo.size}
                                onChange = {this.onChangehandler}
                            />
                            <span className="text-danger">{this.state.errMsg.size}</span>
                            </div>
                        </div>  
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="listingPrice">Listing Price <strong class="text-danger">*</strong> </label>
                            <NumberFormat
                                class="form-control"
                                placeholder="Listing Price"
                                thousandsGroupStyle="thousand"
                                value = {this.state.listingPrice}
                                onChange={(e) => this.handleListingPrice(e.target.value)}
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                isNumericString={false}
                            />
                            {/* <Input
                                class="form-control"
                                type="number"
                                min="0"
                                name="listingPrice"
                                placeholder="Listing Price"
                                value = {this.state.userInfo.listingPrice}
                                onChange = {this.onChangehandler}
                            /> */}
                            <span className="text-danger">{this.state.errMsg.listing_price}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="denomination">Denomination (Currency) <strong class="text-danger">*</strong> </label>
                            <select class="form-control" value={this.state.denomination} onChange={(e) => this.handleDenomination(e.target.value)} >
                                <option value="">Select Denomination</option>
                                <option value="Naira">Naira</option>
                                <option value="Pounds">Pounds</option>
                            </select>
                            <span className="text-danger">{this.state.errMsg.denomination}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-4">
                            <label for="yearBuilt">Year Built <strong class="text-danger">*</strong></label>
                            <Input
                                class="form-control"
                                type="number"
                                min="0"
                                name="yearBuilt"
                                placeholder="Year Built"
                                value = {this.state.userInfo.yearBuilt}
                                onChange = {this.onChangehandler}
                            />
                            <span className="text-danger">{this.state.errMsg.year_built}</span>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mb-4">
                            <label for="houseDesc">House Description <strong class="text-danger">*</strong></label>
                            <textarea
                                class="form-control"
                                type="textarea"
                                rows="7"
                                name="houseDesc"
                                placeholder="House Description"
                                value = {this.state.userInfo.houseDesc}
                                onChange = {this.onChangehandler}
                            ></textarea>
                            <span className="text-danger">{this.state.errMsg.house_description}</span>
                            </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-12">
                        {
                            this.state.propertyStatus === null  || this.state.propertyStatus  === "verify" || this.state.propertyStatus  === "reject" 
                            ? <button type="button" class="btn btn-primary" onClick={(e) => this.updatePropertyInfo()}>Save Changes</button>
                            : ""
                        }
                        </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}
