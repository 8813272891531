import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import Header from './Header'
import axios from "axios";
import Pagination from "react-js-pagination";
import { APIURL } from '../../../components/constants/common';
import { Redirect, Link } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";
import Tooltip from '@mui/material/Tooltip';
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import { ReactComponent as EditPencil } from '../../../assets/images/pencil-fill.svg';
import { ReactComponent as EyeIcon} from '../../../assets/images/eye.svg';
import { ReactComponent as CommentIcon } from '../../../assets/images/comment.svg';
import { ReactComponent as FileIcon } from '../../../assets/images/file-text.svg';
import { ReactComponent as DropDown} from '../../../assets/images/three-dots-vertical.svg';
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'

export default class PropertyList extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            plans: [],
            errMsg: "",
            scsMsg: "",
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            status: [],
            sort: false,
            sortby: "DESC",
            propertyList: [],
            commentModal: false,
            adminComment: "",
            newAddProperty:false,
            Loader:false,
            norecord:false,
            mobileView: false
        }
    }
    newAddProperty = ()=>{
            this.setState({
                newAddProperty:true
            })
    }

    handlePageChange(pageNumber) {
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getPropertyList()
            }
        );
    }

    commentModal(adminComment) {
        this.setState({
            commentModal: !this.state.commentModal,
            adminComment: adminComment
        })
    }

    commentModalClose() {
        this.setState({
            commentModal: false
        })
    }

  
    getPropertyList() {
         this.setState({
            Loader: true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('statusfilter', this.state.status);
        // formData.append('sortby', this.state.sortby);
        formData.append('limit', this.state.limit);
        //formData.append('sorttype', this.state.columnName);

        axios
            .post(APIURL + "buyer/get-buyer-property-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                window.scrollTo(0,0)
                this.setState({
                    propertyList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    Loader: false,
                    norecord:true
                })
            })
            .catch((error) => {
                this.setState({
                    norecord:true,
                    Loader: false,
                })
            });
    }
    
    handleSearch(e) {
        this.setState(
            { search: e.target.value, activePage: 1 }
            , () => { this.getPropertyList() });
    }
   
    handleStatus(e) {
        this.setState(
            {
                status: e,
                activePage:1
            }
            , () => { 
                this.getPropertyList() 
            });
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}

    componentDidMount() {
        window.scrollTo(0,0)
        this.getPropertyList();
    }

    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "Buyer") {
            return <Redirect to="/permission" />;
        }
        if (this.state.newAddProperty) {
            return <Redirect to="/buyer/newaddproperty" />;
        }
        return (
            <>
             
                <Navbar />

                <div class="content-area">
                    {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}
                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account</small></div>
                        </div>

                    </div>
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                    <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-lg-4">
                                <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="addproperty" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-lg-8">
                                    <div class="bg-light">
                                        <div class="px-xxl-4 py-4 px-3">
                                            <div>
                                                <div className="mb-4 d-flex justify-content-between">
                                                    <Button  onClick={() => this.newAddProperty()} color="info" className="px-3 btn btn-primary btn-sm">Add Property</Button>
                                                </div>
                                                <div class="d-sm-flex justify-content-between align-items-end">
                                                    <h2 class="mb-3">Property</h2>
                                                    <div class="row align-items-end">
                                                        <div class="col-6">
                                                            <Input
                                                                type="text"
                                                                onChange={(e) => this.handleSearch(e)}
                                                                placeholder="Search"
                                                                className="form-control form-control_new border-0 border-end-0"
                                                            />
                                                        </div>
                                                        <div class="col-6">
                                                            <div className="small">Status</div>
                                                            <select onChange={(e) => this.handleStatus(e.target.value)} className="form-control form-control_new border-0">
                                                                <option value="">All</option>
                                                                <option value="under_review">Under Review</option>
                                                                <option value="verify">Verified</option>
                                                                <option value="reject">Rejected</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                                                {/* messgae err */}
                                                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                                                <div class="row">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped table-borderless">
                                                            <thead>
                                                                <tr class="border-bottom">
                                                                    <th style={{minWidth:70}}>Sr. No.</th>
                                                                    <th style={{minWidth:120}}>Property Name</th>
                                                                    <th style={{minWidth:120}}>Created Date</th>
                                                                    {/* <th>Property Type</th> */}
                                                                    <th>Status</th>
                                                                    <th width="70px">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.propertyList.length > 0 && this.state.propertyList.map((item, idx) => (
                                                                    <tr>
                                                                        <td>{idx + 1}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>
                                                                        {/* <td>{item.categoryArray.category_name}</td> */}
                                                                        <td>
                                                                            {
                                                                                item.status === "verify" ?
                                                                                <span class="badge bg-success">Verified</span>
                                                                                :
                                                                                item.status === "under_review" ?
                                                                                <span class="badge bg-warning">Under Review</span>
                                                                                :
                                                                                item.status === "reject" &&
                                                                                <span class="badge bg-danger">Rejected</span>
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                        <div class="navbar py-0 no_navbar text-center">
                                                                        <div class="dropdown">
                                                                            <a class="dropdown-toggle btn-rounded btn btn-default" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <DropDown class="img-svg" />
                                                                            </a>

                                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                            {
                                                                                item.admin_comment !== null && item.hasOwnProperty('admin_comment')
                                                                                ? <div className='mb-2'><button className="btn mb-0 btn-rounded btn-default" onClick={() => this.commentModal(item.admin_comment)}><CommentIcon class="img-svg" /></button><span>Comment</span></div>
                                                                                : ""
                                                                            }
                                                                            {
                                                                                item.status === "verify" && item.agent_status === "verify"
                                                                                ? <div className='mb-2'><a href={"/common/property-certificate/" + item.id} target="_blank" className="btn mb-0 btn-rounded btn-default ms-0">
                                                                                <FileIcon class="img-svg" /></a><span> Certificate</span></div>
                                                                                : ""
                                                                            }
                                                                            <div><Link to={"/buyer/property/details/" + item.id} className="btn mb-0 btn-info btn-rounded"><EyeIcon class="img-svg" /></Link><span>Details</span></div>
                                                                            </div>
                                                                            </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                {this.state.propertyList.length === 0 && this.state.norecord==true && 
                                                                    <tr className="text-center">
                                                                        <td colspan="5">No record found</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        {this.state.totalItemsCount > 0 &&
                                                            <div class="justify-content-center d-flex">
                                                                <Pagination
                                                                    activePage={this.state.activePage}
                                                                    itemsCountPerPage={this.state.limit}
                                                                    totalItemsCount={this.state.totalItemsCount}
                                                                    pageRangeDisplayed={5}
                                                                    onChange={this.handlePageChange.bind(this)}
                                                                    itemClass="page-item"
                                                                    linkClass="page-link"
                                                                    innerClass="pagination justify-content-center"
                                                                    activeLinkClass="active"
                                                                    nextPageText="Next"
                                                                    prevPageText="Previous"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Modal size="md" isOpen={this.state.commentModal} toggle={() => this.commentModalClose()}>
                        <ModalHeader className="" toggle={() => this.commentModalClose()}>
                            Comment <br />
                            <span className="text-danger">{this.state.errMsgCmt}</span>
                        </ModalHeader>
                        <ModalBody className="border-0">
                            <div className="row">
                                <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
                                        <div className="input-with-icon">
                                            <textarea
                                                rows="4"
                                                className="form-control h-auto"
                                                required=""
                                                type="textarea"
                                                name="comment"
                                                value={this.state.adminComment}
                                                disabled
                                            />
                                            {/* <i class="fas fa-address-card"></i> */}
                                        </div>
                                        {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="">
                                <button type="button" className="btn btn-light" onClick={() => this.commentModalClose()}>Close</button>
                            </div>
                        </ModalFooter>
                    </Modal>

                </div>
                <Footer />

            </>
        )
    }
}
