import React, { Component } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from '../../Front/Footer'
import Header from './Header'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import { Button, Spinner } from 'reactstrap'
import Select from 'react-select'
import { Redirect } from 'react-router'
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { ReactComponent as ViewWishProperty } from '../../../assets/images/eye.svg';
import { ReactComponent as DeleteWishProperty } from '../../../assets/images/trash.svg';
import { ReactComponent as MenuList}  from '../../../assets/images/three-dots-vertical.svg'
import Image3 from '../../../assets/images/image-1.webp';
import { ReactComponent as HeartRed } from '../../../assets/images/heart-fill.svg'
import { ReactComponent as Heart } from '../../../assets/images/heart.svg'
import Tooltip from '@mui/material/Tooltip';

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            wishlistRecordes: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: "",
            Loader:false,
            norecord:false,
            mobileView: false
        }
    }

    componentDidMount() {
        this.getWishlist(1)
    }

    handlePageChange(pageNumber) {
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getWishlist(1)
            }
        );
    }

    getWishlist(scroll) {
        this.setState({
                    Loader:true
                })
        if (this.state.user) {
            const formData = new FormData();

            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL + "buyer/get-property-wishlist"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (scroll) {
                        window.scrollTo(0,0)
                    } 
                    this.setState({
                        wishlistRecordes: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page,
                        Loader:false,
                        norecord:true,
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false,
                        norecord:true,
                    })
                });
        }
    }

    removeWishlistItem(property_id){
        if (this.state.user) {
            const formData = new FormData();

            formData.append('property_id', property_id);
            formData.append('user_id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL + "buyer/property-wishlist-remove"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        scsMsg: response.data.message,
                    });
                    setTimeout(() => this.setState({ 
                        scsMsg: ""
                    }), 3000)
                    this.getWishlist(0)
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }

    slug(name)
    {
        if(name==undefined)
        {
          return "undefined";
        }
        else
        {
            return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
        }
      
    }

    toggleView() {
		this.setState({
			mobileView: !this.state.mobileView
		})
	}


    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "Buyer") {
            return <Redirect to="/permission" />;
        }

        return (
            <>
                <Navbar />
                <div class="content-area">
                    {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}
                    <div class="breadcrumb-area gradient1">
                        <div class="container">
                            <h1>My Account</h1>
                            <div><small>Welcome To Your Account</small></div>
                        </div>
                    </div>
                    {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                    {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                    <div className={this.state.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
                    <div class="myaccount">
                        <div className='more-menu' onClick={()=>this.toggleView()}><MenuList class="img-svg" alt="menu-list"/></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-xxl-3 col-lg-4">
                                    <div className={this.state.mobileView ? "bg-light h-100 sidebar_mobile left open" : "bg-light h-100 sidebar_mobile left"}>
                                        <div class="px-xxl-4 py-4 px-3">
                                            <Sidebar activePage="wishlist"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-9 col-lg-8">
                                    <div class="bg-light">
                                        <div class="px-xxl-4 py-4 px-3">
                                            <div>
                                                <div>
                                                    <div>
                                                        <h2 id="page_headeing"><span>My Wishlist</span></h2>
                                                        <hr />
                                                    </div>

                                                    {this.state.wishlistRecordes.length > 0 && this.state.wishlistRecordes.map((item, idx) => (
                                                        <div class="property wishlist">
                                                            <div class="row align-items-center">
                                                                <div class="col-sm-6">
                                                                    <div class="position-relative">
                                                                        {this.state.user == null &&
                                                                            <Link class="property__like" to="/signin"><Heart class="img-svg" alt="property" /></Link>
                                                                        }
                                                                        {/* {this.state.user != null && this.state.user.user_type == "Buyer" &&
                                                                            <a href="javascript:;" class="property__like" onClick={() => this.handelLike(item)} >
                                                                                {item.wishlist == true ?
                                                                                    <HeartRed class="img-svg" alt="property" />
                                                                                    : <Heart class="img-svg" alt="property" />
                                                                                }
                                                                            </a>
                                                                        } */}
                                                                        <div class="property__img">

                                                                            {/* <div class="property_verified gradient2"><span>verified</span><small>Premium</small></div> */}
                                                                            {item.property_media.length > 0 && item.property_media[0].type === "image"
                                                                                ? <img src={item.property_media[0].url_path} class="animate" alt="property" />
                                                                                : <img src={Image3} class="animate" alt="property" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 position-relative">
                                                                    <div class="property__cnt">
                                                                        <div>
                                                                            <small>{item.property_features.purpose}</small>
                                                                            <h3>{item.property.name}</h3>
                                                                            <h4 class="price">
                                                                                <span>{
                                                                                    item.property_features.denomination === "Dollar" ?
                                                                                        "$"
                                                                                        :
                                                                                        item.property_features.denomination === "Naira" ?
                                                                                            "₦"
                                                                                            :
                                                                                            item.property_features.denomination === "Pounds" ?
                                                                                                "£"
                                                                                                : ""
                                                                                }{item.property_features.listing_price}</span></h4>
                                                                            <div class="my-2">
                                                                                <div>Listed in Rentals and Apartments</div>
                                                                                <div>
                                                                                    <span>City: <span>{item.property_address.city_name}</span></span> ,
                                                                                    <span>Area: <span>{item.property_features.size} m<sup>2</sup></span></span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="mt-3">
                                                                                <Link to={"/realestateproperty/" + this.slug(item.name) + "/" + this.slug(item.property_address.city_name) + "/" + this.slug(item.property_address.state_name) + "/" + item.property_id} class="btn btn-view"><ViewWishProperty class="img-svg" alt="property" /></Link>
                                                                                <Button color='default' className="btn-default" onClick={() => this.removeWishlistItem(item.property_id)}><Tooltip title="Delete Property"><DeleteWishProperty class="img-svg" alt="property" /></Tooltip></Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))}

                                                    {this.state.wishlistRecordes.length === 0 && this.state.norecord &&
                                                        <div className="text-center">
                                                            No item is added to favorite list
                                                        </div>
                                                    }
                                                    </div>
                                                <div class="text-center mt-4">
                                                    {/* <a href="#" class="btn btn-secondary">View More Property</a> */}
                                                    {this.state.totalItemsCount > 0 &&
                                                        <div class="justify-content-center d-flex">
                                                            <Pagination
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={this.state.limit}
                                                                totalItemsCount={this.state.totalItemsCount}
                                                                pageRangeDisplayed={5}
                                                                onChange={(e) => this.handlePageChange(e)}
                                                                itemClass="page-item"
                                                                linkClass="page-link"
                                                                innerClass="pagination justify-content-center"
                                                                activeLinkClass="active"
                                                                nextPageText="Next"
                                                                prevPageText="Previous"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
                <Footer />

            </>
        )
    }
}
