import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import { Modal, Spinner, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap'
import { APIURL } from '../../../constants/common';
import axios from 'axios'
import Pagination from "react-js-pagination";
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",
        };
    },
};

export default class RequestTransaction extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            historyList: [],
            property_id: "",
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            search: "",
            plan: [],
            Loader:false,
            norecord:false,
            selectedDate: "",
            showDate: ""
        }
    }

    handleSelectedDate(e) {
        this.setState({
            showDate: e,
            selectedDate: dateFormat(e, "yyyy-mm-dd"),
            activePage: 1,
        },()=>{
            this.getRequestHistory()
        })
    }

    clearDate(){
        this.setState({
            showDate: "",
            selectedDate: "",
            activePage: 1,
        },()=>{
            this.getRequestHistory()
        })
    }

    handleSearch(e) {
        this.setState(
        { search: e.target.value, activePage: 1 }
        , () => { this.getRequestHistory() });
    }

    handlePlan(e) {
        this.setState(
        {
            plan: e,
            activePage: 1 
        }
        , () => { this.getRequestHistory() });
    }

    handlePageChange(pageNumber) {
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getRequestHistory()
            }
        );
    }

    getRequestHistory() {
        this.setState({
            Loader:true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('datefilter', this.state.selectedDate);
        formData.append('limit', this.state.limit);

        axios
            .post(APIURL + "seller/get-verification-request-purchase-history-list-for-seller", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    historyList: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    limit: response.data.data.per_page,
                    norecord:true,
                    Loader:false
                })
            })
            .catch((error) => {
                this.setState({
                    norecord:true,
                    Loader:false
                })
            });
    }

    componentDidMount() {
        this.getRequestHistory();
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }

        return (
            <div className="admin">
                <Sidebar activePage="request_history" />
                <div class="content-area">
                 {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Navbar activePage="property-listing"/>   
                    <div class="content">
                        <div class="card">
                        <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-center">
                                <h2 id="page_headeing"><span>Request Transaction History</span></h2>
                                <div class="row align-items-end">
                                    <div class="col-6">
                                        <Input 
                                            type="text" 
                                            onChange={(e) => this.handleSearch(e)} 
                                            placeholder="Search"  
                                            className="form-control form-control_new border-0 border-end-0"
                                        />
                                    </div>
                                    <div class="col-6">
                                        <div class="small">Transaction Date</div>
                                            <div className="position-relative">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.showDate}
                                                    onSelect={(e) =>this.handleSelectedDate(e)}
                                                    placeholderText="Transaction Date"
                                                    className="form-control form-control_new border-0 border-end-0"
                                                />
                                            <button onClick={() =>this.clearDate()} className="cleardate">x</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                <div class="table-responsive">
                                    <table class="table table-striped table-borderless">
                                    <thead>
                                        <tr class="border-bottom">
                                            <th style={{minWidth:70}}>Sr. No.</th>
                                            <th style={{minWidth:100}}>Plan Name</th>
                                            <th style={{minWidth:100}}>Amount (£)</th>
                                            <th style={{minWidth:120}}>Coupon Applied</th>
                                            <th style={{minWidth:130}}>Transaction Date</th>                                  
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.historyList.length > 0 && this.state.historyList.map((item, idx) => (
                                        <tr>
                                            <td>{idx+1}</td>
                                            <td>{item.plan_name}</td>
                                            <td>{item.total_amount}</td>
                                            <td>{ item.coupon_code !== null &&
                                                    <span class="badge bg-success">Yes</span>
                                                }
                                            </td>
                                            <td>{dateFormat(item.created_at, "mmm d, yyyy")}</td>                              
                                        </tr>
                                    ))}

                                    {this.state.historyList.length === 0 && this.state.norecord==true && 
                                        <tr className="text-center">
                                            <td colspan="7">No record found</td>
                                        </tr>
                                    }
                                    </tbody>
                                    </table>
                                </div>
                                <div class="mt-4 text-center">
                                    {this.state.totalItemsCount>0 && 
                                        <div class="justify-content-center d-flex">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center"
                                            activeLinkClass="active"
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                        />
                                        </div>
                                    }
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
}
}
