import React, { Component } from 'react'
import axios from "axios";
import { Redirect, Link } from 'react-router-dom'
import { APIURL } from '../../components/constants/common';
import { Modal, ModalBody, ModalHeader, Input, ModalFooter, Spinner, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap'
import {ReactComponent as GeoAlt  } from '../../assets/images/geo-alt.svg'
import Image1 from '../../assets/images/image-1.webp'
import {ReactComponent as Heart  } from '../../assets/images/heart.svg'
import { ReactComponent as HeartRed } from '../../assets/images/heart-fill.svg'
import {ReactComponent as Size  } from '../../assets/images/size.svg'
import {ReactComponent as Property  } from '../../assets/images/property.svg'
import {ReactComponent as Bed  } from '../../assets/images/bed.svg'
import {ReactComponent as Bathroom  } from '../../assets/images/bathroom.svg'
import NumberFormat from 'react-number-format';
   
export default class GoodProperty extends Component {
	constructor(){
		super();
		this.state = {
			stylePath:true,
			latestpropertyList: [],
			token: JSON.parse(localStorage.getItem("token")),
			user: JSON.parse(localStorage.getItem("userData"))
		}
	}

	getLatestProperties() {
		var token = this.state.token
        axios
            .post(APIURL + "get-latest-properties",null,{
	                headers: {
	                    'Authorization': `Bearer ${token}`
	                }
	            })
            .then((response) => {
                this.setState({
					latestpropertyList: response.data.data.data
				})
            })
    }

	handelLike(list){
		if(this.state.user!=null && this.state.user.user_type=="Buyer")
		{
			 const formData = new FormData();
			 formData.append('property_id', list.id);
			 var token = this.state.token
			 if(list.wishlist == false)
			 {
				 var app_url = APIURL+"buyer/property-wishlist-add"
			 }
			 else
			 {
				var app_url = APIURL+"buyer/property-wishlist-remove"
			 }
			
			 axios
				 .post(app_url, formData, {
					 headers: {
						 'Authorization': `Bearer ${token}`
					 }
				 })
				 .then((response) => {
					  this.getLatestProperties()
				 })
				 .catch((error) => {
					 this.setState({
						 // errMsg: error.response.data.errors,
						 Loader: false
					 })
				 });
		 }
			
	 }

	componentDidMount(){
		this.getLatestProperties();
	}
	 slug(name)
  {
  	if(name==undefined)
  	{
      return "undefined";
  	}
  	else
  	{
  		return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
  	}
  
  }

	render() {
		return (
		<div>	
			<section id="explore_places" class="burger theme_bg">
		        <div class="container">
		          <header class="section_heading">
		            <div class="row">
		              <div class="col-lg-6 col-md-8 col-sm-10 m-auto">
		                <h2>Explore Good Places</h2>
		                <p>Dive in and explore options from our list of properties.</p>
		              </div>
		            </div>
		          </header>
		          <div class="property-area">
		            <div class="row">
					{ this.state.latestpropertyList.length > 0 && this.state.latestpropertyList.map((item, index) => (
		              	<div class="col-lg-4 col-sm-6">
							<article class="property">
								{this.state.user==null  &&
			                    	<Link class="property__like" to="/signin"><Heart class="img-svg" alt="property" /></Link>
			                   	}
			                   	{
									this.state.user!=null &&  this.state.user.user_type=="Buyer" &&
									<a href="javascript:;" className="property__like" onClick={()=>this.handelLike(item)} >
									{ item.wishlist == true?
										<HeartRed class="img-svg favourite"  alt="property" />
										:<Heart class="img-svg"  alt="property" />
									}
									</a>
			                   	}
							<div class="property__img">
								{	item.property_image && item.property_image.length > 0 && item.property_image[0].type === "image"
									? <img src={item.property_image[0].url_path} class="animate" alt="property" />
									: <img src={Image1} class="animate" alt="property" />
								}
								{	item.verification_banner === "yes" && 
									<div class="property_verified gradient2"><span>verified</span><small>Premium</small></div>
								}
							</div>
							<div class="property__cnt">
								<div>
									<small>{item.property_features.purpose}</small>
									<h3>{item.name}</h3>
								</div>
									<h4 class="price">{
                                        item.property_features.denomination === "Dollar" ?
                                        "$"
                                        : 
                                        item.property_features.denomination === "Naira" ?
                                        "₦"
                                        :
										item.property_features.denomination === "Pounds" ?
                                        "£"
										: ""
                                    }
									 <NumberFormat
										thousandsGroupStyle="thousand"
										value={Math.round(item.property_features.listing_price)}
										displayType="text"
										type="text"
										thousandSeparator={true}
									/>
									</h4>
							</div>
							<div class="property__features">
								<ul>
									<li data-bs-toggle="tooltip" title="Location"><GeoAlt class="img-svg" alt="" /> <span class="location">{item.property_address.city_name}</span> </li>
									<li data-bs-toggle="tooltip" title="Area"><Size class="img-svg" alt="" /> <span class="size">{item.property_features.size} m<sup>2</sup></span> </li>
									<li data-bs-toggle="tooltip" title="Property Type"><Property class="img-svg" alt="" /> <span class="type">{item.categoryArray.category_name}</span> </li>
									<li data-bs-toggle="tooltip" title="bedRooms"><Bed class="img-svg" alt="" /> <span class="bed">{item.property_features.total_bedrooms}</span> </li>
									<li data-bs-toggle="tooltip" title="Bathroom"><Bathroom class="img-svg" alt="" /> <span class="bathroom">{item.property_features.total_bathrooms}</span> </li>
									<li><Link to={"/realestateproperty/" + this.slug(item.name) + "/" + this.slug(item.property_address.city_name) + "/" + this.slug(item.property_address.state_name) + "/" + item.id} className="btn btn-primary btn-sm">View Details</Link></li>
								</ul> 
							</div>
							</article>
		              	</div>
					))}
		            </div>
		            <div class="text-center mt-4">
		              <Link to="search" class="btn btn-secondary">View More Properties</Link>
		            </div>
		          </div>
		        </div>
		      </section>

		</div>	
		)
	}
}
