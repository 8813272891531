import React, { Component } from 'react'

import banner1 from '../../assets/images/banner-1.webp'
import banner2 from '../../assets/images/banner-2.webp'
import banner3 from '../../assets/images/banner-3.webp'
import axios from "axios";
import { APIURL } from '../constants/common';
import { Redirect, Link } from "react-router-dom";

export default class Home extends Component {
	constructor(){
		super();
		this.state = {
			user: JSON.parse(localStorage.getItem("userData")),
			isLoggedin: localStorage.getItem("isLoggedIn"),
			stylePath:true,
			property_type:"For Rent",
			category_list:[],
			category_id:"",
			Countries:[], 
			countryId:"", 
			States:[], 
			stateId:"",
			searchRedirect:false,
			search:""
		}
	}

	componentDidMount(){
         this.getCategory()
         this.getCountries()
	}
	
	handleType = (e) =>{
        this.setState({
            property_type: e
        })
    }

    handleCategory = (e) =>{
        this.setState({
            category_id: e
        })
    }
	
	getCategory(){
         const formData = new FormData();
            var token = this.state.token
            axios
                .post(APIURL + "get-category-list", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        category_list: response.data.data,
                       
                    })
                })
                .catch((error) => {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
    }

    getCountries() {
        axios
            .get(APIURL + "countries")
            .then((response) => {
                let countries_name = response.data.countries;
                const CountryNames = [];
                for (var c = 1; c < countries_name.length; c++) {
                    CountryNames.push({ value: countries_name[c].id, label: countries_name[c].name })
                }
                this.setState({
                    Countries: CountryNames,
                })
              
            })
    }

    handleCountry(e) {
        this.setState({
            countryId: e
        }, () => {
            console.log(this.state.countryId)
            this.handleCountryState(e)
        })
    };

    // get states
    handleCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                console.log(response.data)
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
            .catch((error) => {
                this.setState({

                })
            });
    };

    // handle states
    handleState(e) {
        this.setState({
            stateId: e
        })
    }

    handleSearch(e) {
        this.setState({
            search: e
        })
    }

    handleSearchSubmit() {
        this.setState({
            searchRedirect: true
        })
    }

	render() {
		if(this.state.searchRedirect==true)
		{
          return <Redirect to={"/search?search="+this.state.search+"&category="+this.state.category_id+"&type=For Sale"} push={true} />;
		}
		return (	
			<div class="banner">
				<div class="swiper banner__image">
					<div class="swiper-wrapper">
						<div class="swiper-slide"> 
				          	<div style={{ backgroundImage: `url(${banner1})` }} class="banner_img"></div>
				      	</div>
					</div>
				</div>
				  
			<div class="banner__txt">
				<div class="container">
					<div class="row">
						<div class="col-lg-8 col-md-10 m-auto">
							<form>
								<div class="banner__cnt">
									<h2>Search properties for sale or to rent</h2>
									
									<div class="d-flex mb-1">
										{/* <div><button type="button" className={this.state.property_type==="For Rent"?"btn btn-light active border-0 " : "btn btn-light border-0"} onClick={()=>this.handleType("For Rent")}>For Rent</button></div> */}
										
											
												{/* <button type="button" className={this.state.property_type==="For Sale"?"btn btn-light active border-0 " : "btn btn-light border-0"} onClick={()=>this.handleType("For Sale")}>For Sale</button> */}
												<button type="button" className= "btn btn-light active border-0 " onClick={()=>this.handleType("For Sale")}>For Sale</button>
											
										
										{/* <div>                    
											<div class="border-start">
												<button type="button" className={this.state.property_type==="Short let"?"btn btn-light active border-0 " : "btn btn-light border-0"} onClick={()=>this.handleType("Short let")}>Short let</button>
											</div>
										</div> */}
									</div>

									<div class="row g-0 w-100 mb-4">
									    <div class="col-2"  style={{display:"none"}}>
									        <select className="form-control" value={this.state.countryId} onChange={(e) => this.handleCountry(e.target.value)} >
				                                <option value=""> Country</option>
				                                {this.state.Countries.map((option) => (
				                                <option value={option.value}>{option.label}</option>
				                                ))}
				                            </select>
			                            </div>
			                            <div class="col-2" style={{display:"none"}}>
									         <select className="form-control" value={this.state.stateId} onChange={(e) => this.handleState(e.target.value)} >
			                                    <option value="" > State</option>
			                                    {this.state.States.map((option) => (
			                                    <option value={option.value}>{option.label}</option>
			                                    ))}
			                                </select>
			                            </div>
										<div class="col-sm-6 col-12">
											<div class="">
												<input type="text"  onChange={(e)=>this.handleSearch(e.target.value)} class="form-control" id="exampleInputEmail1" placeholder="Where do you want to live?" />
											</div>
										</div>
										<div class="col-sm-3 col-6">
											<div class="border-start">
											 <select className="form-control" value={this.state.category_id} onChange={(e) => this.handleCategory(e.target.value)}>
						                        <option value=""> Category</option>
						                        {this.state.category_list.length > 0 && this.state.category_list.map((category, idx) => (
						                            <option value={category.id}>{category.category_name}</option>
						                        ))}
						                    </select>
											
											</div>
										</div>
										<div class="col-sm-3 col-6">
											<button type="button" onClick={()=>this.handleSearchSubmit()} class="btn btn-light active btn-block border-0" style={{padding: "13px"}}>SEARCH</button>
										</div>
									</div>									
								</div>
							</form>
						</div>
					</div>
					<div className='row'>
						<div class="col-lg-8 col-md-10 m-auto home_request_btn">
							{ this.state.user!=null &&  this.state.user.user_type=="Buyer"
								? <Link to="/buyer/newaddproperty" class="btn btn-block btn-light active me-4 text-white">Request an Independent Property Verification</Link>
								: this.state.user == null 
								? <Link to="/signin" class="btn btn-block btn-light active me-4 text-white">Request an Independent Property Verification</Link>
								: ""
							}
						</div>
					</div>
				</div>
			</div>
			</div>
		)
	}
}
