import React, { Component } from 'react'
import logo from '../assets/images/genhaven-white.webp'
import { Link } from 'react-router-dom'
import axios from "axios";
import { Spinner ,    Button } from 'reactstrap';
import { Redirect } from 'react-router-dom'
import { APIURL } from '../components/constants/common';
import Navbar from './Front/TopNavbar'
import Footer from './Front/Footer'
import {ReactComponent as Facebook} from '../assets/images/facebook.svg'
import {ReactComponent as Twitter} from '../assets/images/twitter.svg'

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            scsMsg: "",
            errMsg: "",
            redirect: false
        };
    }
    handleUserName = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ isLoading: true });
        axios
            .post(APIURL + "password/reset", {
                email: this.state.email,
            })
            .then((response) => {
                console.log(response.data)
                this.setState({
                    isLoading:false,
                    scsMsg: response.data.message
                });
                setTimeout(() => this.setState({ scsMsg: "" }), 3000);
                setTimeout(() => this.setState({ redirect: true }), 4000);
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    isLoading: false,
                    errMsg: error.response.data,
                })
                setTimeout(() => this.setState({ errMsg: "" }), 4000);
            });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to="/signin" />;
        }
        return (
            <div class="resido-front">
                {/* Navbar cmp */}
                <Navbar />
                {/* header cmp */}
                <div class="content-area">
                   <div class="authentication">
                        <div class="container">
                          <div class="row g-0">
                            <div class="col-sm-12">
                              <div class="authentication__content">
                                <div class="row g-0 align-self-center">
                                    <div class="col-md-6">
                                        <div class="mx_h-600">
                                            <div class="px-5">
                                                <h1>Recover Password</h1>
                                                 {this.state.scsMsg ? <p class="alert alert-success" role="alert">
                                                                {this.state.scsMsg}
                                                            </p> : ""}
                                                <form onSubmit={(e) => this.handleSubmit(e)}>
                                                    <div class="mb-4">
                                                        <input
                                                        className="form-control"
                                                        required=""
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Enter your email Id"
                                                        value={this.state.email}
                                                        onChange={this.handleUserName}
                                                         />
                                                        <span className="text-danger">{this.state.errMsg.errors}</span>
                                                    </div>

                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div class="text-right">
                                                          <Link to="/signup">Register</Link> <strong>/</strong> <Link to="/signin">Login</Link>
                                                        </div>
                                                        <div class="text-right">
                                                            <Button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                Submit
                                                                {this.state.isLoading ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm ml-2"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : (
                                                                    <span></span>
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 order-md-first">
                                        <div class="px-5 authentication__withsocial gradient1">
                                            <div class="content">
                                                <a href="#"><img loading="lazy" src={logo} class="brand-logo" alt="genhaven Logo" /></a>
                                                <h2>Glad to See You!</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                {/*<div>
                                                    <h5>Login With Social Media</h5>
                                                    <div>
                                                        <a href="#" class="btn btn-facebook"><Facebook class="img-svg" alt="user" /> <span>Login With Facebook</span></a>
                                                        <a href="#" class="btn btn-twitter"><twitter class="img-svg" alt="user" /> <span>Login With Twitter</span></a>
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}
