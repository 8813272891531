import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import { Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Button, Card, CardBody, CardTitle, CardText, Input } from 'reactstrap'
import axios from "axios";
import { Link, Redirect } from 'react-router-dom'
import { APIURL } from '../../../components/constants/common';
import Accordion from 'react-bootstrap/Accordion'
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";
import { Carousel } from 'react-carousel-minimal';
import { ReactComponent as Check} from '../../../assets/images/check-circle1.svg';
import { ReactComponent as Dash} from '../../../assets/images/dash-circle.svg';

// const images = [
//     { url: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJvcGVydHl8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80" },
//     { url: "https://media.istockphoto.com/photos/home-tax-deduction-picture-id1270111816?b=1&k=20&m=1270111816&s=170667a&w=0&h=r15RKKh8GsUCFjX2FvrVmx4CYrk5fiAwSlfAmcwPoUw=" },

// ];

const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
}
const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
}

export default class ViewPropertyDetailsByBuyer extends Component {
    constructor() {
        super();
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            p_id: "",
            PropertyInfo: [],
            Loader: true,
            verifyComment: "",
            rejectComment: "",
            verifyCommentModal: false,
            rejectCommentModal: false,
            initialMsg: "verify",
            accepted: "",
            verify: "verify/unverified",
            redirectBack: false,
            imageData: [],
            videoData: [],
            photoIndex: 0,
            isOpen: false,
            selectedFiles: [],
            verification_status: "",
            propertyCheck: [],
            errMsg: "",
            scsMsg: "",
            signatureFile: "",
            prop_title_length: 0,
            survey_doc_verify_length: 0,
            site_visit_verify_length: 0,
            solicitor_comment_length: 0,
            image_error: "",
            signatureFile: "",
            verifyFormModal: false,
            initialMsg: "verify",
            property_type: "",
            property_address: "",
            property_bus_stop: "",
            property_title: "No",
            survey_doc: "No",
            site_visit: "No",
            property_title_verification: "",
            survey_doc_verification: "",
            site_visit_verification: "",
            solicitors_comment: "",
            solicitors_name: "",
            solicitors_address: "",
            date_of_issuance: "",
            //date_of_expiry: "",
            verification_acceptance: "",
            errMsg: "",
            scsMsg: ""
        }
    }

    componentDidMount() {
        document.body.classList.add('sidebar-icon-only');
        this.ViewPropertyInfo(this.props.match.params.id)

    }

    ViewPropertyInfo = (e) => {
        var token = this.state.token
        const formData = new FormData();
        formData.append('property_id', e);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "view-buyer-property-detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    PropertyInfo: response.data.data,
                    verification_status: response.data.data[0].agent_assign_status
                })

                let Images = [];

                this.state.PropertyInfo.map((item, idx) => {
                    item.property_media.map((result, index) => {
                        result.type === "image" &&
                        Images.push({
                            url_path: result.url_path,
                            type: result.type,
                            caption: ""
                        })
                    });
                })

                this.setState({
                    imageData: Images,
                })
            })
            .catch((error) => {
                this.setState({
                    //errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };
    
    verifyFormModal(e) {
        this.setState({
            verifyFormModal: !this.state.verifyFormModal,
        })
    }

    rejectCommentModal(e) {
        this.setState({
            rejectCommentModal: !this.state.rejectCommentModal,
        })
    }
    
    verifyModalClose() {
        this.setState({
            verifyFormModal: false
        })
    }

    rejectModalClose() {
        this.setState({
            rejectCommentModal: false
        })
    }

    verifyComment(e) {
        this.setState({
            verifyComment: e
        })
    }

    rejectComment(e) {
        this.setState({
            rejectComment: e
        })
    }

    handlepropertyAddress(e) {
        this.setState({
            property_address: e
        })
    }

    handlepropertybusStop(e) {
        this.setState({
            property_bus_stop: e
        })
    }
    
    handlepropertyTitleVerification(e) {
        this.setState({
            property_title_verification: e,
            prop_title_length: e.length
        })
    }

    handlesurveyDocVerification(e) {
        this.setState({
            survey_doc_verification: e,
            survey_doc_verify_length: e.length
        })
    }

    handlesiteVisitVerification(e) {
        this.setState({
            site_visit_verification: e,
            site_visit_verify_length: e.length
        })
    }

    handlesolicitorsComment(e) {
        this.setState({
            solicitors_comment: e,
            solicitor_comment_length: e.length
        })
    }

    handleSolicitors(e) {
        this.setState({
            solicitors_name: e
        })
    }

    handlesolicitorsAddress(e) {
        this.setState({
            solicitors_address: e
        })
    }

    handledateofIssuance(e) {
        this.setState({
            showdateofIssuance: e,
            date_of_issuance: dateFormat(e, "yyyy-mm-dd")
        })
    }

    cleardateofIssuance(){
        this.setState({
            showdateofIssuance: "",
            date_of_issuance: ""
        })
    }

    handlepropertyTitle(e) {
        this.setState({
            property_title: e
        })
    }

    handlesurveyDoc(e) {
        this.setState({
            survey_doc: e
        })
    }

    handlesiteVisit(e) {
        this.setState({
            site_visit: e
        })
    }

    handleselectedFile = (e) => {
        if(this.state.selectedFiles.length < 4){
            this.setState({
                selectedFiles: [...this.state.selectedFiles, ...e.target.files],
                image_error: ""
            }, () => {
                e.target.value = null;
            })
        } else {
            this.setState({
                image_error: "Only 4 pictures are allowed",
            }, () => {
                e.target.value = null;
                setTimeout(() => {
                    this.setState({
                        image_error: ""
                    })
                }, 4000);
            })
        }
    }

    handlesignatureFile = (e) => {
        this.setState({
            signatureFile: e.target.files[0]
        }, () => {
            e.target.value = null;
        })
    }

    handleRemoveFile = (index) => {
        var tempArray = this.state.selectedFiles;
        tempArray.splice(index, 1);
        this.setState({
            selectedFiles: tempArray
        })
    }

    handleRemoveSignatureFile = () => {
        this.setState({
            signatureFile: ""
        })
    }

    handleverificationAcceptance = (e) => {
        this.setState({
            verification_acceptance: e
        })
    }

    VerifyingRequest(status) {
        if (status === "reject") {
            if (this.state.rejectComment) {
                this.onSubmit(status)
            } else {
                this.setState({
                    errMsgCmt: "Please write a comment"
                })
            }
        }
    }

    onSubmit = (status) => {
        this.setState({ isLoading: true })
        var token = this.state.token
        const formData = new FormData();
        formData.append('property_id', this.props.match.params.id);
        formData.append('status', status);
        formData.append('comment', this.state.verifyComment ? this.state.verifyComment : this.state.rejectComment);
        this.setState({ Loader: true, verifyCommentModal: false, rejectCommentModal: false});
        axios
            .post(APIURL + "agent/update-buyer-property-status-by-agent", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    VerifyStatus: response.data.data.status,
                    //redirectBack: true
                });
            })
            .catch((error) => {
                this.setState({
                    Loader: false,
                    errMsg: error.response.data.error,
                })
            });
        this.ViewPropertyInfo(this.props.match.params.id)
    }

    submitVerificationForm(status) {
        this.setState({
            verification_status: status
        })
        this.submitDocument()
    }

    submitDocument () {
        this.setState({ isLoading: true })
        var token = this.state.token
        const formData = new FormData();
        formData.append('property_id', this.state.PropertyInfo[0].id);
        formData.append('user_id', this.state.PropertyInfo[0].agent_id);
        formData.append('property_title_check', this.state.property_title);
        formData.append('address', this.state.property_address);
        //formData.append('property_type', this.state.property_type);
        
        this.state.selectedFiles.forEach(file => {
            formData.append('attachment_files[]', file)
        });

        formData.append('nearest_bus_stop', this.state.property_bus_stop);
        formData.append('survey_document_check', this.state.survey_doc);
        formData.append('site_visit_check', this.state.site_visit);
        formData.append('verification_status', "verify");
        formData.append('property_title_verify_detail', this.state.property_title_verification);
        formData.append('survey_document_verify_detail', this.state.survey_doc_verification);
        formData.append('site_visit_verify_detail', this.state.site_visit_verification);
        formData.append('solicitor_name', this.state.solicitors_name);
        formData.append('solicitor_comment', this.state.solicitors_comment);
        formData.append('solicitor_address', this.state.solicitors_address);
        formData.append('date_of_issuance', this.state.date_of_issuance);
        //formData.append('date_of_expiry', this.state.date_of_expiry);
        formData.append('signed_files[]', this.state.signatureFile);
        formData.append('verification_acceptance_check', this.state.verification_acceptance);
        axios
            .post(APIURL + "agent/buyer-property-verification-document-create", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Loader: true, verifyFormModal: false, errMsg: ""});
                //this.onSubmit(this.state.verification_status);
                this.onSubmit("verify");
            })
            .catch((error) => {
                this.setState({
                    Loader: false,
                    errMsg: error.response.data.error,
                })
            });
        this.ViewPropertyInfo(this.props.match.params.id)
    }
    
    Goback() {
        this.setState({
            redirectBack: true
        })
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.redirectBack) {
            return <Redirect to={"/agent/buyeraddedproperty"} />;
        }

        return (
        <>
            <div className="admin">
                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" /> </div> : ""}
                <Sidebar activePage="property-listing" />
                <div class="content-area">
                <Navbar activePage="view-addedbuyerpropertydetails-by-agent"/>   
                <div class="content">
                {this.state.PropertyInfo.length > 0 && this.state.PropertyInfo.map((item, idx) => (
                    <div class="card">
                    <div class="card-body">
                        <div class="d-sm-flex justify-content-between align-items-center">
                        <h2 id="page_headeing"><span>{item.name}</span>
                        {
                            item.agent_assign_status === "verify" ?
                                <span class="badge bg-success ms-2">Verified</span>
                            :
                            item.agent_assign_status === "under_review" ?
                                <span class="badge bg-warning ms-2">Under Review</span>
                            : 
                                item.agent_assign_status === "reject" ?
                                <span class="badge bg-danger ms-2">Rejected</span>
                            : ""                            
                        }
                        </h2>
                        <div>
                        {
                            item.agent_assign_status === "under_review"
                            ?
                            <>
                                <a href="#" class="py-1 pe-3 text-muted" onClick={(e) => this.rejectCommentModal()}>Reject</a>
                                <a href="#" class="btn btn-primary btn-sm" onClick={(e) => this.verifyFormModal()}>Verify</a>
                            </>
                            : ""
                        }
                        </div>
                        </div>
                        <hr />
                        <div>
                        <div class="deatil_features">
                            <h4>Property Information</h4>
                            <ul class="list mb-2">
                            <li>
                                <strong>Address:</strong><span>{item.address} </span>
                            </li>
                            <li>
                                <strong>Nearest Bus Stop:</strong><span>{item.near_by_bus_stop} </span>
                            </li>
                            <li>
                                <strong>City:</strong><span>{item.city_name} </span>
                            </li>
                            <li>
                                <strong>State:</strong><span>{item.state_name} </span>
                            </li>
                            <li>
                                <strong>Country:</strong><span>{item.country_name} </span>
                            </li>
                            </ul>
                            <h5 class="fs-5">Description</h5>
                            <p class="mt-2">{item.description} </p>
                            <hr />
                            <h4>Verification Requested</h4>
                            <ul class="list mb-2">
                            <li>
                                <strong>Property Title:</strong><span><Check class="img-svg me-2" alt="" />{item.property_title_check} </span>
                            </li>
                            <li>
                                <strong>Survey Document:</strong><span><Check class="img-svg me-2" alt="" />{item.survey_document_check} </span>
                            </li>
                            <li>
                                <strong>Site Visit:</strong><span><Check class="img-svg me-2" alt="" />{item.site_visit_check} </span>
                            </li>
                            </ul>
                            <hr />
                            <div>
                            {   (item.hands_free_service === "true" || item.hands_free_service === true) &&
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="mb-4 d-flex">
                                            <div><Check class="img-svg" alt="" /></div>
                                            <label class="form-label" className='ms-2'>Hands free service</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {   (item.verification_acceptance_check === "true" || item.verification_acceptance_check === true) &&
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="mb-4 d-flex">
                                            <div><Check class="img-svg" alt="" /></div>
                                            <label class="form-label" className='ms-2'>This document contains information in connection with the verification requested for the above property.
                                        Issuance of the certificate does not in ANY way mean that your acquisition is fool proof if standard acquisition and 
                                        conveyancing process is not engaged</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                        <hr />
                        <div class="deatil_features">
                            <div class="img_gallery">
                                <h4>Gallery</h4>
                                <div class="row">
                                    <div class="col-sm-12">
                                    <div>
                                        <h5>Images</h5>
                                        {this.state.imageData.length > 0 && this.state.imageData.map((item, idx) => (
                                            
                                            item.type === "image" ?
                                            <a href="#" onClick={() => this.setState({ isOpen: true })}><img src={item.url_path} class="img-thumbnail" alt="logo" /></a>
                                            : ""  
                                        ))}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    ))}
                </div>
                </div>

                {this.state.isOpen && (
                <Lightbox
                    mainSrc={this.state.imageData[this.state.photoIndex].url_path}
                    nextSrc={this.state.imageData[(this.state.photoIndex + 1) % this.state.imageData.length].url_path}
                    prevSrc={this.state.imageData[(this.state.photoIndex + this.state.imageData.length - 1) % this.state.imageData.length].url_path}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (this.state.photoIndex + this.state.imageData.length - 1) % this.state.imageData.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (this.state.photoIndex + 1) % this.state.imageData.length,
                    })
                    }
                />
                )}
            </div>

            <Modal size="lg" isOpen={this.state.verifyFormModal} toggle={() => this.verifyModalClose()}>
                <ModalHeader className="" toggle={() => this.verifyModalClose()}>
                    Property Verification Document <br />
                    <span className="text-danger">{this.state.errMsgCmt}</span>
                </ModalHeader>
                <ModalBody className="border-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label for="property_address" class="form-label">Full Property Address <strong className="text-danger" >*</strong></label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="property_address"
                                    placeholder="Full Property Address"
                                    value={this.state.property_address}
                                    onChange={(e) => this.handlepropertyAddress(e.target.value)}
                                />
                                <span className="text-danger">{this.state.errMsg.address}</span>
                            </div>
                            
                            <div className="mb-3">
                                <label for="property_bus_stop" class="form-label">Nearest Bus Stop <strong className="text-danger" >*</strong></label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="property_bus_stop"
                                    placeholder="write here"
                                    value={this.state.property_bus_stop}
                                    onChange={(e) => this.handlepropertybusStop(e.target.value)}
                                />
                                
                                <span className="text-danger">{this.state.errMsg.nearest_bus_stop}</span>
                            </div>
                            
                            {/* <div className="mb-3">
                                <label for="property_type" class="form-label">Property Type </label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="property_type"
                                    placeholder="Property Type"
                                    value={this.state.property_type}
                                    disabled
                                />
                                
                            </div> */}
                            
                            <div className="mb-3">
                                <label for="property_pictures" class="form-label">Pictures </label>
                                <input
                                    type="file"
                                    className="form-control form-control-sm"
                                    onChange = {this.handleselectedFile}
                                    accept="image/png, image/jpg, image/jpeg"
                                />

                                <span className="text-danger">{this.state.image_error}</span>
                                <span className="text-danger">{this.state.errMsg.attachment_files}</span>
                            </div>
                            <div className="mb-3 forms-sample">
                                <div class="row">
                                    {this.state.selectedFiles.length > 0 && this.state.selectedFiles.map((file, idx) =>(         
                                    <div class="col-sm-3 col-6">                           
                                        <div className='img-gallery'>
                                            <img className="w-100 " src={URL.createObjectURL(file)} />
                                            <div className="img-delete"><i onClick={(e) => this.handleRemoveFile(idx)} className="cursor_pointer far fa-trash-alt"></i></div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                            <div className="row">
                            <h4>Verification Requested</h4>
                            <hr />
                                <div className="col-sm-4">
                                    <div className="mb-3">
                                        <label for="property_title" class="form-label">Property Title <strong className="text-danger" >*</strong></label>
                                        <div className="d-flex">
                                            <div class="form-check me-3"> 
                                                <Input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="property_title"
                                                    value="Yes"
                                                    checked = {this.state.property_title === "Yes"}
                                                    onChange={(e) => this.handlepropertyTitle(e.target.value)}
                                                />
                                                <label class="form-check-label">Yes</label>
                                            </div>
                                            <div class="form-check"> 
                                                <Input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="property_title"
                                                    value="No"
                                                    checked = {this.state.property_title === "No"}
                                                    onChange={(e) => this.handlepropertyTitle(e.target.value)}
                                                />
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="mb-3">
                                        <label for="survey_doc" class="form-label">Survey Document<strong className="text-danger" >*</strong></label>
                                        <div className="d-flex">
                                            <div class="form-check me-3"> 
                                                <Input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="survey_doc"
                                                    value="Yes"
                                                    checked = {this.state.survey_doc === "Yes"}
                                                    onChange={(e) => this.handlesurveyDoc(e.target.value)}
                                                />
                                                <label class="form-check-label">Yes</label>
                                            </div>
                                            <div class="form-check"> 
                                                <Input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="survey_doc"
                                                    value="No"
                                                    checked = {this.state.survey_doc === "No"}
                                                    onChange={(e) => this.handlesurveyDoc(e.target.value)}
                                                />
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="mb-3">
                                        <label for="site_visit" class="form-label">Site Visit<strong className="text-danger" >*</strong></label>
                                        <div className="d-flex">
                                            <div class="form-check me-3"> 
                                                <Input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="site_visit"
                                                    value="Yes"
                                                    checked = {this.state.site_visit === "Yes"}
                                                    onChange={(e) => this.handlesiteVisit(e.target.value)}
                                                />
                                                <label class="form-check-label">Yes</label>
                                            </div>
                                            <div class="form-check"> 
                                                <Input 
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="site_visit"
                                                    value="No"
                                                    checked = {this.state.site_visit === "No"}
                                                    onChange={(e) => this.handlesiteVisit(e.target.value)}
                                                />
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h4>Verification Status</h4>
                                <hr />
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label for="property_title_verification" class="form-label">Property Title Verification Details <strong className="text-danger" >*</strong></label>
                                        <span className="small ms-2">{this.state.prop_title_length}/400</span>
                                        <textarea
                                            rows="4"
                                            className="form-control h-auto"
                                            required=""
                                            maxlength="400"
                                            type="textarea"
                                            name="property_title_verification"
                                            placeholder="Property Title Verification Details"
                                            value={this.state.property_title_verification}
                                            onChange={(e) => this.handlepropertyTitleVerification(e.target.value)}
                                        />
                                        <span className="text-danger">{this.state.errMsg.property_title_verify_detail}</span>
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    
                                    <div className="mb-3">
                                        <label for="survey_doc_verification" class="form-label">Survey Document Verification Details <strong className="text-danger" >*</strong></label>
                                        <span className="small ms-2">{this.state.survey_doc_verify_length}/400</span>
                                        <textarea
                                            rows="4"
                                            className="form-control h-auto"
                                            required=""
                                            maxlength="400"
                                            type="textarea"
                                            name="survey_doc_verification"
                                            placeholder="Survey Document Verification Details"
                                            value={this.state.survey_doc_verification}
                                            onChange={(e) => this.handlesurveyDocVerification(e.target.value)}
                                        />
                                        <span className="text-danger">{this.state.errMsg.survey_document_verify_detail}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    
                                    <div className="mb-3">
                                        <label for="site_visit_verification" class="form-label">Site Visit Verification Details <strong className="text-danger" >*</strong></label>
                                        <span className="small ms-2">{this.state.site_visit_verify_length}/400</span>
                                        <textarea
                                            rows="4"
                                            className="form-control h-auto"
                                            required=""
                                            maxlength="400"
                                            type="textarea"
                                            name="site_visit_verification"
                                            placeholder="Site Visit Verification Details"
                                            value={this.state.site_visit_verification}
                                            onChange={(e) => this.handlesiteVisitVerification(e.target.value)}
                                        />
                                        <span className="text-danger">{this.state.errMsg.site_visit_verify_detail}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    
                                    <div className="mb-3">
                                    <label for="solicitors_comment" class="form-label">Solicitors Comment <strong className="text-danger" >*</strong></label>
                                    <span className="small ms-2">{this.state.solicitor_comment_length}/400</span>
                                    <textarea
                                        rows="4"
                                        className="form-control h-auto"
                                        required=""
                                        maxlength="400"
                                        type="textarea"
                                        name="solicitors_comment"
                                        placeholder="Solicitors Comment"
                                        value={this.state.solicitors_comment}
                                        onChange={(e) => this.handlesolicitorsComment(e.target.value)}
                                    />
                                    <span className="text-danger">{this.state.errMsg.solicitor_comment}</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="mb-3">
                                <label for="solicitors" class="form-label">Solicitors <strong className="text-danger" >*</strong></label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="solicitors"
                                    placeholder="Solicitors"
                                    value={this.state.solicitors_name}
                                    onChange={(e) => this.handleSolicitors(e.target.value)}
                                />
                                <span className="text-danger">{this.state.errMsg.solicitor_comment}</span>
                            </div>
                            
                            <div className="mb-3">
                                <label for="solicitors_address" class="form-label">Solicitors Address <strong className="text-danger" >*</strong></label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="solicitors_address"
                                    placeholder="Solicitors Address"
                                    value={this.state.solicitors_address}
                                    onChange={(e) => this.handlesolicitorsAddress(e.target.value)}
                                />
                                <span className="text-danger">{this.state.errMsg.solicitor_comment}</span>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    
                                    <div className="mb-3">
                                        <label for="date_of_issuance" class="form-label">Date of Issuance <strong className="text-danger" >*</strong></label>
                                        <div className="position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.showdateofIssuance}
                                                onSelect={(e) =>this.handledateofIssuance(e)}
                                                placeholderText="Date of Issuance"
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                            <button onClick={() =>this.cleardateofIssuance()} className="cleardate">x</button>
                                        </div>
                                        <span className="text-danger">{this.state.errMsg.date_of_issuance}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label for="property_pictures" class="form-label">Upload Signature <strong className="text-danger" >*</strong></label>
                                        <input
                                            type="file"
                                            className="form-control form-control-sm"
                                            onChange = {this.handlesignatureFile}
                                            accept="image/png, image/jpg, image/jpeg"
                                        />
                                        <span className="text-danger">{this.state.errMsg.signed_files}</span>
                                    </div>

                                    <div className="mb-3">
                                        <div class="row">
                                            {this.state.signatureFile !== "" &&         
                                            <div class="col-sm-3 col-6">                           
                                                <div className='img-gallery'>
                                                    <img className="w-100 " src={URL.createObjectURL(this.state.signatureFile)} />
                                                    <div className="img-delete"><i onClick={(e) => this.handleRemoveSignatureFile()} className="cursor_pointer far fa-trash-alt"></i></div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="mb-3">
                                <div className="d-flex">
                                    <div>
                                        <Input
                                            class="form-control"
                                            type="checkbox"
                                            checked = {this.state.verification_acceptance}
                                            onChange={(e) => this.handleverificationAcceptance(e.target.checked)}
                                        />
                                    </div>
                                    <label for="acceptance" class="ms-2">This document contains information in connection with the verification requested for the above property.
                                    Issuance of the certificate does not in ANY way mean that your acquisition is fool proof if standard acquisition and 
                                    conveyancing process is not engaged</label>
                                </div>
                                <span className="text-danger">{this.state.errMsg.verification_acceptance_check}</span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button type="button" className="btn btn-primary me-3" onClick={() => this.submitVerificationForm("verify")}>Save changes</button>
                        <button type="button" className="btn btn-light" onClick={() => this.verifyModalClose()}>Close</button>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal size="md" isOpen={this.state.rejectCommentModal} toggle={() => this.rejectModalClose()}>
                <ModalHeader className="" toggle={() => this.rejectModalClose()}>
                    Comment <br />
                    <span className="text-danger">{this.state.errMsgCmt}</span>
                </ModalHeader>
                <ModalBody className="border-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <textarea
                                        rows="4"
                                        className="form-control h-auto"
                                        required=""
                                        type="textarea"
                                        name="comment"
                                        placeholder="write here"
                                        value={this.state.rejectComment}
                                        onChange={(e) => this.rejectComment(e.target.value)}
                                    />
                                    {/* <i class="fas fa-address-card"></i> */}
                                </div>
                                {/* <span className="text-danger">{this.state.errMsg.address}</span> */}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button type="button" className="btn btn-primary" onClick={() => this.VerifyingRequest("reject")}>Save changes</button>
                        <button type="button" className="btn btn-light" onClick={() => this.rejectModalClose()}>Close</button>
                    </div>
                </ModalFooter>
            </Modal>    
        </>   
        )
    }
}
