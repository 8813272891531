import React, { Component } from 'react'
import axios from "axios";
import { ReactComponent as Filter} from '../../assets/images/funnel.svg';
import { APIURL } from '../../components/constants/common';
import Slider from '@material-ui/core/Slider'


export default class LeftFilter extends Component {
	constructor(props){
		super(props);
		this.state = {
			stylePath: true,
			category_list: [],
			Countries: [],
			States: [],
		}
	}
	
	componentDidMount(){
        this.getCategory()
        this.getCountryState(1)
	}

	getCategory(){
		const formData = new FormData();
		var token = this.state.token
		axios
			.post(APIURL + "get-category-list", formData, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			})
			.then((response) => {
				this.setState({
					category_list: response.data.data,
					
				})
			})
			.catch((error) => {
				this.setState({
					errMsg: error.response.data.errors,
					Loader: false
				})
			});
    }

	getCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                console.log(response.data)
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
            .catch((error) => {
                this.setState({
					errMsg: error.response.data.errors,
					Loader: false
				})
            });
    };

    // getCountries() {
    //     axios
    //         .get(APIURL + "countries")
    //         .then((response) => {
    //             let countries_name = response.data.countries;
    //             const CountryNames = [];
    //             for (var c = 1; c < countries_name.length; c++) {
    //                 CountryNames.push({ value: countries_name[c].id, label: countries_name[c].name })
    //             }
    //             this.setState({
    //                 Countries: CountryNames,
    //             })
              
    //         })
    // }



    minMaxStateRangeSelector (event,val){
	 	this.props.minMaxRangeSelector(val[0],val[1])
	};

	minMaxSizeStateRangeSelector(event,val){
		this.props.minMaxSizeRangeSelector(val[0],val[1])
	};

	render() {
		//alert(this.state.mobileView);
		return (
			<div>
				<div className={this.props.mobileView ? "sidebar_alpha open" : "sidebar_alpha"}></div>
				{/* <div class="property-sorting">
					<div class="filter_area">
						<Filter className="img-svg" alt="home" onClick={() => this.toggleView()} /> <span>Filter</span>
					</div>
				</div>	 */}
			  	<div className={this.props.mobileView ? "property__filter sidebar_mobile open" : "property__filter sidebar_mobile"}>
				  	<h3>Search properties</h3>
				  	<hr />
				  	<form>
				   
                    <div class="mb-3" style={{display:"none"}}>
				      <label  class="form-label">Country</label>
					     <select className="form-control" value={this.props.countryId} onChange={(e) => this.props.handleCountry(e.target.value)} >
	                        <option value=""> Country</option>
	                        {this.state.Countries.map((option) => (
	                        <option value={option.value}>{option.label}</option>
	                        ))}
	                    </select>
                    </div>
					{	this.props.search &&
						<div class="mb-3" >
						<label  class="form-label">Location</label>
						<input type="text" 
						class="form-control" onChange={(e) => this.props.handleSearch(e.target.value)}  value={this.props.search}
						placeholder="Search" />
						</div>
					}
					<div class="mb-3">
				      <label  class="form-label">State</label>
					    <select className="form-control" value={this.props.stateId} onChange={(e) => this.props.handleState(e.target.value)} >
                            <option value="">Select State</option>
                            {this.state.States.map((option) => (
								option.label === "Lagos" || option.label === "Abuja Federal Capital Territory" || option.label === "Ogun" || option.label === "Oyo"
                            	? <option value={option.value} className="fw-bolder fst-italic">{option.label}</option>
								: <option value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
				    <div class="mb-3">
				      <label  class="form-label">Purpose</label>
				      {/* <select  class="form-select" aria-label="Default select example" value={this.props.purpose} onChange={(e) => this.props.handlePurpose(e.target.value)} disabled> */}
						<select  class="form-select" aria-label="Default select example" disabled>
							<option value="">Select Purpose</option>
				        	{/* <option value="For Rent">For Rent</option> */}
				        	<option value="For Sale" selected={true}>For Sale</option>
				        	{/* <option value="Short let">Short let</option> */}
				      	</select>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Property Type</label>
					     <select className="form-control" value={this.props.category_id} onChange={(e) => this.props.handleCategory(e.target.value)}>
	                        <option value="">Select Category</option>
	                        {this.state.category_list.length > 0 && this.state.category_list.map((category, idx) => (
	                            <option value={category.id}>{category.category_name}</option>
	                        ))}
	                    </select>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Sub Property Type</label>
				        <select className="form-control" value={this.props.sub_category_id} onChange={(e) => this.props.handlesubCategory(e.target.value)}>
                            <option value="">Select Sub-Category</option>
                            {this.props.sub_category_list.length > 0 && this.props.sub_category_list.map((subCategory, idx) => (
                                <option value={subCategory.id}>{subCategory.category_name}</option>
                            ))}
                        </select>
				    </div>
				    <div class="mb-3">
				      <label  class="form-label">Price</label>
				      <select class="form-select" value={this.props.denomination} onChange={(e) => this.props.handleDenomination(e.target.value)} aria-label="Default select example">
				        <option value="">Select Currency</option>
				        {/* <option value="Dollar">Dollar</option> */}
				        <option value="Naira">Naira</option>
				        <option value="Pounds">Pounds</option>
				      </select>
				    </div>
				    <div class="mb-3">
				      	<label  class="form-label">Price Range <span class="price-range">Value: {this.props.min_price} - {this.props.max_price} <span id="demo"></span></span></label>
				      	<div class="slidecontainer">
					       	<Slider
						        value={[this.props.min_price,this.props.max_price]}
						        min={0}
						        max={50000000000}
						        classNmae="slider"
						        onChange={(e,val)=>this.minMaxStateRangeSelector(e,val)}
						    />				     
				      	</div>
				    </div>
				    <div class="mb-3">
				    <label  class="form-label">Size Range <span class="price-range">Value in(sq. ft): {this.props.min_size} - {this.props.max_size} <span id="demo"></span></span></label>
						<div class="slidecontainer">
					      	<Slider
						        value={[this.props.min_size,this.props.max_size]}
						        min={0}
						        max={50000}
						        classNmae="slider"
						        onChange={(e,val)=>this.minMaxSizeStateRangeSelector(e,val)}
						    />
					    </div>
				    </div>
				    <div class="mb-5">
				      <label  class="form-label">Year Built</label>
				      <select class="form-select" value={this.props.year_built} onChange={(e) => this.props.handleYearBuilt(e.target.value)} aria-label="Default select example">
				        <option value="">Select Year</option>
				        
				        {this.props.builtYears.length > 0 && this.props.builtYears.map((rs, idx) => (
                                <option value={rs}>{rs}</option>
                            ))}
				       
				      </select>
				    </div>
					{	this.props.mobileView &&
						<div class="mobile_show">
							<button type="button" className="btn btn-default border-0 me-3" onClick={() => this.props.toggleView()}>Cancel</button>
							<button type="submit" className="btn btn-primary" onClick={() => this.props.toggleView()}>Apply</button>
						</div>	
					}
					</form>
				</div>
		</div>	
		)
	}
}
