import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import { Button, Spinner, Input } from 'reactstrap'

import Select from 'react-select'
// import { Helmet } from "react-helmet";

export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            userInfo: {
                name: "",
                lastName:"",
                email: "",
                phone: "",
                state: "",
                language: "",
                image: "",
                address: "",
                streetName:"",
                streetNumber:"",
                City:"",
                Zip:"",
            },
            countryId: "",
            countries_name: [],
            countrySelected: {},
            languages:[],
            Countries: [],
            States: [],
            state_name: [],
            state: "",
            name: "",
            image: "",
            selectType: "",
            msg: "",
            errMsg: "",
            scsMsg: "",
            country_id: "",
            profile_image: "",
            selectedLanguages:[],
            stateId:"",
            cities_name: [],
            city_id: ""
        }
        
        this.handlephone = this.handlephone.bind(this)
    }

 

    //form handler

    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({
            userInfo,
            errMsg: ""
        });
    };

    handleCity(e) {
        this.setState({
            city_id: e
        })
    }

    getCities(state_id) {
        axios
            .post(APIURL + "cities", {
                state_id: state_id,
            })
            .then((response) => {
                let cities_name = response.data.states;
                const cityNames = [];
                for (var c = 0; c < cities_name.length; c++) {
                    cityNames.push({ value: cities_name[c].id, label: cities_name[c].name })
                }
                this.setState({
                    cities_name: cityNames,
                })
            })
    }


    //profile information
    getProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            // formData.append('token', this.state.token);
            formData.append('id', this.state.user.id);
            var token = this.state.token
            var app_url = APIURL+"seller/edit-profile"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        name: response.data.data.name,
                        userInfo: {
                            name: info.name,
                            lastName: info.last_name,
                            email: info.email,
                            phone: "",
                            streetNumber: info.street_number === "null" ? "" :info.street_number,
                            streetName: info.street_name === "null" ? "" :info.street_name,
                            Zip: info.zip_code === "null" ? "" :info.zip_code,
                            // address: info.address,
                            // introduction: info.introduction,
                            // license_number: info.license_number
                        },
                        phone: "",
                        profile_image: response.data.data.url_path,
                        // email: response.data.data.email,
                        selectedOptions: JSON.stringify(response.data.data.language),
                        countryId: response.data.data.country,
                        language: response.data.data.language,
                        stateId: response.data.data.state,
                        city_id: response.data.data.city,
                    })
                    
                    // let list = response.data.data.language_data;
                    // let selectedLanguages = []
                    // for (var c = 0; c < list.length; c++) {
                    //     selectedLanguages.push({ "value": list[c].value, "label": list[c].label })
                    // }
                    // this.setState({
                    //     selectedLanguages: selectedLanguages
                    // })                  
                    this.handleCountryState(1)
                    if(response.data.data.state > 0)
                    {
                        this.getCities(response.data.data.state)
                    }
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false
                    })
                });
        }
    }
    //    get languages

    getLanguages() {
        axios
            .get(APIURL + "languages")
            .then((response) => {
                let languages = response.data.languages;
                for (var c = 0; c < languages.length; c++) {
                    this.state.languages.push({ value: languages[c].id, label: languages[c].name })
                }
            })
    }

    handleLanguages = (selectedLanguages) => {
        this.setState({ selectedLanguages });
    }
  
    handlephone(e){
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({phone: e.target.value})
        }
    }

    getCountries() {
        axios
            .get(APIURL + "countries")
            .then((response) => {
                let countries_name = response.data.countries;
                const CountryNames = [];
                for (var c = 0; c < countries_name.length; c++) {
                    CountryNames.push({ value: countries_name[c].id, label: countries_name[c].name })
                }
                this.setState({
                    Countries: CountryNames,
                })
            })
    }

    handleCountry(e) {
        this.setState({
            countryId: e
        }, () => {
            this.handleCountryState(e)
        })
    };

    // get states
    handleCountryState = (id) => {
        axios
            .post(APIURL + "states", {
                country_id: id,
            })
            .then((response) => {
                let state_name = response.data.states;
                const stateNames = [];
                for (var c = 0; c < state_name.length; c++) {
                    stateNames.push({ value: state_name[c].id, label: state_name[c].name })
                }
                this.setState({
                    States: stateNames,
                })
            })
    };

    // handle states
    handleState(e) {
        this.setState({
            stateId: e
        }, () => {
            if(e) {
                this.getCities(e)
            } else {
                this.setState({
                    cities_name: [],
                    city_id: ""
                })
            }
        })
    }
    //profile image change handler
    handleChangeLogo = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }

    //    update  profile submit handler

    onSubmitHandler = (e) => {
        var token = this.state.token

        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('name', this.state.userInfo.name);
        formData.append('last_name', this.state.userInfo.lastName);
        formData.append('phone', this.state.phone);
        formData.append('email', this.state.userInfo.email);
        formData.append('state', this.state.stateId);
        formData.append('country', this.state.countryId);
        formData.append('profile_image', this.state.image);
        formData.append('street_number', this.state.userInfo.streetNumber);
        formData.append('street_name', this.state.userInfo.streetName);
        formData.append('city', this.state.city_id);
        formData.append('zip_code', this.state.userInfo.Zip);
        formData.append('language', JSON.stringify(this.state.selectedLanguages));
        formData.append('address', this.state.userInfo.address);
        formData.append('id', user.id);
        formData.append('user_type', user.user_type);

        this.setState({ Loader: true });
        axios
            .post(APIURL + "seller/update-profile", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Loader: false, scsMsg: response.data.message,
                    errMsg: "",
                }, () => {
                    setTimeout(() => this.setState({ 
                        scsMsg: ""
                    }), 4000);
                });
                this.getProfileInfo()
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };

    componentDidMount() {
        this.getProfileInfo()
        this.getCountries()
        this.handleCountryState(1);
    }
    
    render() {
        if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        if (this.state.user.user_type !== "Seller") {
            return <Redirect to="/permission" />;
        }
        
        return (


                <div className='admin'>

                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}
                    <Sidebar data={{ profile_image: this.state.profile_image }} activePage="profile" />
                    <div class="content-area">
                        <Navbar data={{ profile_image: this.state.profile_image }} />
                        <div class="content">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <h2 id="page_headeing"><span>Account Details</span></h2>
                                    </div>
                                    <hr />
                                    <form>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="mb-4">
                                                    <label for="exampleInputName1" class="form-label">First Name <strong className="text-danger" >*</strong></label>
                                                    <Input
                                                        className="form-control"
                                                        required=""
                                                        type="text"
                                                        name="name"
                                                        placeholder="First Name"
                                                        value={this.state.userInfo.name}
                                                        onChange={this.onChangehandler}
                                                        />
                                                    <span className="text-danger">{this.state.errMsg.name}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="mb-4">
                                                    <label for="exampleInputName1" class="form-label">Last Name <strong className="text-danger" >*</strong></label>
                                                    <Input
                                                        className="form-control"
                                                        required=""
                                                        type="text"
                                                        name="lastName"
                                                        placeholder="Last Name"
                                                        value={this.state.userInfo.lastName}
                                                        onChange={this.onChangehandler}
                                                        />
                                                    <span className="text-danger">{this.state.errMsg.last_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="mb-4">
                                                    <label for="exampleInputEmail3" class="form-label">Email Address <strong className="text-danger" >*</strong></label>
                                                    <input
                                                        className="form-control"
                                                        required=""
                                                        disabled
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email Address"
                                                        value={this.state.userInfo.email}
                                                        onChange={this.onChangehandler}
                                                        />
                                                    <span className="text-danger">{this.state.errMsg.email}</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="mb-4">
                                                    <label class="form-label">Profile Image</label>
                                                    <div class="mb-3">
                                                        <Input
                                                            type="file"
                                                            onChange={this.handleChangeLogo}
                                                            className="form-control"
                                                            id="fileinput"
                                                            style={{ height: "100%" }}
                                                            accept="image/png, image/jpg, image/jpeg" 
                                                        />
                                                        <span className="text-info small"> (Only png, jpg, jpeg format and size upto 2 MB is allowed)</span><br />
                                                     <span className="text-danger">{this.state.errMsg.profile_image}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="mb-4">
                                                    <label for="country" class="form-label">Country </label>
                                                    <select className="form-control" value={this.state.countryId} onChange={(e) => this.handleCountry(e.target.value)} disabled>
                                                        {/* <option value="">Select Country</option>
                                                        {this.state.Countries.map((option) => (
                                                        <option value={option.value}>{option.label}</option>
                                                        ))} */}
                                                        <option value="1">Nigeria</option>
                                                    </select>
                                                    <span className="text-danger">{this.state.errMsg.country}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="mb-4">
                                                    <label for="state" class="form-label">State <strong className="text-danger" >*</strong></label>
                                                    <select className="form-control" value={this.state.stateId} onChange={(e) => this.handleState(e.target.value)} >
                                                        <option value="" >Select State</option>
                                                        {this.state.States.map((option) => (
                                                        <option value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{this.state.errMsg.state}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="mb-4">
                                                    <label for="city" class="form-label">Locality <strong className="text-danger" >*</strong></label>
                                                    <select className="form-control" value={this.state.city_id} onChange={(e) => this.handleCity(e.target.value)} >
                                                        <option value="" >Select Locality</option>
                                                        {this.state.cities_name.map((option) => (
                                                            <option value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{this.state.errMsg.city}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="mb-4">
                                                    <label for="exampleTextarea1" class="form-label">Zip Code</label>
                                                    <Input
                                                        rows="4"
                                                        className="form-control"
                                                        required=""
                                                        type="text"
                                                        name="Zip"
                                                        placeholder="Zip Code"
                                                        value={this.state.userInfo.Zip}
                                                        onChange={this.onChangehandler}
                                                        />

                                                    <span className="text-danger">{this.state.errMsg.zip_code}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        <Button
                                        color="btn btn-primary"
                                        className="mr-2"
                                        onClick={() => this.onSubmitHandler()}
                                        >Update</Button>
                                        </div>
                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
