import React, { Component } from 'react'
import { Redirect } from 'react-router';
import Sidebar from './../Sidebar'
import axios from "axios";
import { APIURL } from '../../../../components/constants/common';
import Navbar from './../Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import DatePicker from "react-datepicker";
import dateFormat, { masks } from "dateformat";
import Pagination from "react-js-pagination";
import { Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            userRecords: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: "",
            selectedPlan: "",
            plans: [],
            selectedDate: "",
            showDate: "",
            search: "",
            checked: true,
            result: null,
            status: "",
            current_plan: "",
            id:"",
            confirmModal: false,
            BuyerModel:false,
            confirmAlert:false,
            noRecordStatus: false            
        }

    }

    componentDidMount() {
        this.getuserList()
    }

    handleSearch(e) {
        this.setState(
            { search: e.target.value, activePage: 1 }
            , () => { this.getuserList() });
    }

    confirmModal(item) {
        this.setState({
            id: item.id,
            confirmModal: true,
        })
    }

    confirmModalClose() {
        this.setState({
            confirmModal: false,
        })
    }

    onLogoutHandler = () => {

        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    handlePageChange(pageNumber) {
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getuserList()
            }
        );
    }

    handleUserType(e) {
        this.setState({
            userType: e,
            activePage: 1,
        }, () => {
            this.getuserList()
        })
    }

    handleStatusType(e) {
        this.setState({
            showDate: e,
            status: e,
            activePage: 1,
        }, () => {
            this.getuserList()
        })
    }

    getuserList() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('statusfilter', this.state.status);
            formData.append('search', this.state.search);
            formData.append('userfilter', this.state.userType);
            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL + "admin/get-all-user-list"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        userRecords: response.data.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page,
                        noRecordStatus: true
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false,
                        noRecordStatus: true
                    })
                });
        }
    }

 
    onSubmitHandleUser = () => {
        var token = this.state.token
        const formData = new FormData();
        formData.append('user_id', this.state.id);
        formData.append('current_plan', "Premium");
        axios
            .post(APIURL + "admin/make-basic-user-to-premium", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    confirmModal: false,
                    scsMsg: response.data.message,
                    success: true,
                }, () => {
                    setTimeout(() => this.setState({
                        scsMsg: ""
                    }), 4000);
                });
                this.getuserList();
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };


    onSubmitHandler = (id, val) => {
        var token = this.state.token
        const { userInfo, user } = this.state;
        const formData = new FormData();
        formData.append('user_id', id);
        formData.append('status', val ? "unblock" : "block");
        // formData.append('status', this.state.status);

        axios
            .post(APIURL + "admin/update-user-status", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    scsMsg: response.data.message,
                    errMsg: "",
                    success: true,
                }, () => {
                    setTimeout(() => this.setState({
                        scsMsg: ""
                    }), 4000);
                });
                this.getuserList();
            })
            .catch((error) => {
                this.setState({
                    errMsg: error.response.data.error,
                    Loader: false
                })
            });
    };

    // Buyertoggle = (item)  => {
    //     this.setState({
    //         id: item.id,
    //         confirmAlert:true,
    //     })
    //     confirmAlert({
    //         title: 'Confirm to submit',
    //         message: 'Please confirm, if you want to convert BASIC users to PREMIUM users.',
    //         buttons: [
    //             {
    //                 label: 'Ok',
    //                 className:"btn btn-success",
    //                 onClick:() => this.onSubmitHandleUser()
    //             },
    //             {
    //                 label: 'Cancel',
    //                 className:"btn btn-warning",
    //                 onClick: () => this.onClose()
    //             }
    //         ]
    //     });
    // };

    onClose=()=>{
        this.setState({
            confirmAlert:false
        })
    }

    render() {

        if (!this.state.user) {
            return <Redirect to="/signin" />;
            const { checked } = this.state;
            let title = this.state.checked ? "ON" : "OFF";
        }
        return (
            <div className="admin">

                {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                {this.state.scsMsg ? <div class="alert alert-success" role="alert"> {this.state.scsMsg} </div> : ""}
                {this.state.errMsg.message ? <div class="alert alert-danger" role="alert">  {this.state.errMsg.message}   </div> : ""}

                <Sidebar activePage="buyerseller" />
                <div class="content-area">
                    <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h2 id="page_headeing"><span>Buyer/Seller List</span></h2>
                                    <div class="row align-items-end W-500">
                                        <div className="col-4">
                                            <Input
                                                type="text"
                                                onChange={(e) => this.handleSearch(e)}
                                                placeholder="Search"
                                                className="form-control form-control_new border-0 border-end-0"
                                            />
                                        </div>
                                        <div class="col-4">
                                            <div class="small">Status Type</div>
                                            <select className="form-control form-control_new border-0 border-end-0"
                                                onChange={(e) => this.handleStatusType(e.target.value)}>
                                                <option value="selectall">Select All</option>
                                                <option value="block">Block</option>
                                                <option value="unblock">Unblock</option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <div class="small">User Type</div>
                                            <select className="form-control form-control_new border-0 border-end-0" onChange={(e) => this.handleUserType(e.target.value)}>
                                                <option value="selectall">Select All</option>
                                                <option value="seller">Seller</option>
                                                <option value="buyer">Buyer</option>

                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-striped table-borderless">
                                            <thead>
                                                <tr class="border-bottom">
                                                    <th style={{minWidth:70}}>Sr. No.</th>
                                                    <th style={{minWidth:100}}>Full Name</th>
                                                    <th style={{minWidth:130}}>Email</th>
                                                    <th style={{minWidth:130}}>Country</th>
                                                    {/* <th>City</th>
                                                    <th style={{minWidth:70}}>Status</th> */}
                                                    <th style={{minWidth:100}}>User Type</th>
                                                    <th style={{minWidth:100}}>Current Plan</th>
                                                    <th style={{minWidth:70}}>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.userRecords.length > 0 && this.state.userRecords.map((item, idx) => (
                                                    <>
                                                        <tr>
                                                            <td>{idx + 1}</td>
                                                            <td>{item.fullName}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.country_name}</td>
                                                            {/* <td>{item.city}</td>
                                                            <td>{item.status === 'active' ? "Unblock":"Block"} 
                                                            </td>*/}
                                                            <td>{item.user_type}</td>
                                                            <td>

                                                                {/* <td>
                                                                    {item.current_plan === 'Basic Plan' ? <button className='btn btn-info btn-sm btn-block'
                                                                       onClick={() => this.Buyertoggle(item)}>Basic Plan</button> :
                                                                        <button className='btn btn-default btn-sm' disabled>{item.current_plan}</button>}
                                                                         
                                                                </td> */}

                                                                <td>
                                                                    {item.current_plan === 'Basic Plan' ? <button className='btn btn-info btn-sm btn-block'
                                                                       onClick={() => this.confirmModal(item)}>Basic Plan</button> :
                                                                        <button className='btn btn-default btn-sm' disabled>{item.current_plan}</button>}
                                                                         
                                                                </td>

                                                            {/* { item.current_plan==='Premium Plan'?  item.current_plan:
                                                            <div className="custom_switch">
                                                                <label class="switch">
                                                                <input type="checkbox" 
                                                                    onClick={(e) => this.onSubmitHandleUser(item.id, e.target.checked)}
                                                                    checked = { item.current_plan==='Premium Plan' ? true : false}
                                                                />
                                                     
                                                                <div class="slider round">
                                                                    {
                                                                        item.current_plan==='Premium Plan'
                                                                        ?
                                                                        <span className='on' >Premium</span>
                                                                        :
                                                                        <span className='off' >Basic</span>
                                                                       
                                                                    }
                                                                </div> 
                                                                
                                                                </label>
                                                            </div> 
                                                            } */}
                                                            </td>
                                                            <td>
                                                                <div className="custom_switch">
                                                                    <label class="switch">
                                                                        <input type="checkbox"
                                                                            //onChange={(e) => this.handleSwitchUser(idx, e.target.checked)}
                                                                            onClick={(e) => this.onSubmitHandler(item.id, e.target.checked)}
                                                                            checked={item.status === 'active' ? true : false}
                                                                        />

                                                                        <div class="slider round">
                                                                            {
                                                                                item.status === 'active'
                                                                                    ?
                                                                                    <span className='on' >Unblock</span>
                                                                                    :
                                                                                    <span class="off"  >Block</span>
                                                                            }
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                                { this.state.userRecords.length === 0 && this.state.noRecordStatus==true &&
                                                    <tr className="text-center">
                                                        <td colSpan="6">
                                                            No record found
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-4 text-center">
                                        {this.state.totalItemsCount > 0 &&
                                            <div class="justify-content-center d-flex">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.state.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination justify-content-center"
                                                    activeLinkClass="active"
                                                    nextPageText="Next"
                                                    prevPageText="Previous"
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal size="md" isOpen={this.state.confirmModal} toggle={() => this.confirmModalClose()}>
                {/* <ModalHeader className="" toggle={() => this.confirmModalClose()}>
                    Comment <br />
                    <span className="text-danger">{this.state.errMsgCmt}</span>
                </ModalHeader> */}
                <ModalBody className="border-0">
                    <div className="row text-center">
                        <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                                <div className="input-with-icon">
                                    <p>Please confirm, if you want to convert basic users to premium users.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center my-2'>
                        <button onClick={() => this.onSubmitHandleUser()} className="btn btn-primary me-3">Ok</button>
                        <button type="button" className="btn btn-light" onClick={() => this.confirmModalClose()}>Close</button>
                    </div>
                </ModalBody>
                {/* <ModalFooter>
                    <div className="">
                      <button type="button" className="btn btn-light" onClick={() => this.confirmModalClose()}>Close</button>  
                    </div>
                </ModalFooter> */}
                </Modal>
            </div>
        )
    }
}