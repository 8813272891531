import React, { Component } from 'react'
import Sidebar from './Sidebar'
import axios from "axios";
import { APIURL } from '../../../components/constants/common';
import Navbar from './Navbar'
import Button from '@restart/ui/esm/Button';
import { Spinner, Input } from 'reactstrap'
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import { Redirect, Link } from 'react-router-dom'
import { ReactComponent as EyeIcon} from '../../../assets/images/eye.svg';
import { ReactComponent as EditPencil } from '../../../assets/images/pencil-fill.svg';

export default class TransactionHistory extends Component {
    constructor() {
        super();
        this.state = {
            token: JSON.parse(localStorage.getItem("token")),
            user: JSON.parse(localStorage.getItem("userData")),
            user_type: localStorage.getItem("user_type"),
            navigate: false,
            search: "",
            errMsg: "",
            scsMsg: "",
            profile_image: "",
            fullscrLoader: false,
            transactionRecords: [],
            activePage: 1,
            limit: 0,
            totalItemsCount: 0,
            userType: "",
            selectedPlan: "",
            plans: [],
            selectedDate: "",
            showDate: "",
            norecord:false
        }
    }

    componentDidMount() {
        this.getCommissionAlertList()
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => {
                this.getCommissionAlertList()
            }
        );
    }

    handleSelectedDate(e) {
        this.setState({
            showDate: e,
            selectedDate: dateFormat(e, "yyyy-mm-dd"),
            activePage: 1,
        },()=>{
            this.getCommissionAlertList()
        })
    }

    clearDate(){
        this.setState({
            showDate: "",
            selectedDate: "",
            activePage: 1,
        },()=>{
            this.getCommissionAlertList()
        })
    }

    getCommissionAlertList() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('datefilter', this.state.selectedDate);
            formData.append('page', this.state.activePage);
            formData.append('limit', this.state.limit);
            var token = this.state.token
            var app_url = APIURL+"admin/get-seller-list-for-reminder-alert-commission"
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        transactionRecords: response.data.data,
                        activePage: response.data.data.current_page,
                        totalItemsCount: response.data.data.total,
                        limit: response.data.data.per_page,
                        norecord:true
                    })
                })
                .catch((error) => {
                    this.setState({
                        // errMsg: error.response.data.errors,
                        Loader: false,
                        norecord:true
                    })
                });
        }
    }

    render() {
         if (!this.state.user) {
            return <Redirect to="/signin" />;
        }
        return (
           <div className="admin">
           
             {this.state.Loader ? <div className="loader"> <Spinner type="grow" color="dark" style={{ width: '3rem', height: '3rem' }} /> </div> : ""}


               <Sidebar activePage="commission_alert_list" />
                <div class="content-area">
                 <Navbar />

                    <div class="content">
                        <div class="card">
                            <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 id="page_headeing mb-0"><span>Commission Alert List</span></h2>
                            </div>
                            <hr />
                            <div>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                <thead>
                                    <tr class="border-bottom">
                                        <th style={{minWidth:70}}>Sr. No.</th>
                                        <th style={{minWidth:100}}>Seller Name</th> 
                                        <th style={{minWidth:120}}>Property Count</th>
                                        <th style={{minWidth:130}}>Verification Count</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.transactionRecords.length > 0 && this.state.transactionRecords.map((item, idx) => (
                                    <>
                                    <tr>
                                        <td>{idx+1}</td>
                                        <td>{item.seller_name}</td>
                                        <td>{item.seller_verify_property}</td>
                                        <td>{(item.property_count - item.total_given_property_commission_count)}</td>
                                        <td>
                                            <Link to={"/admin/add-commission/" + item.user_id} className="btn btn-rounded btn-info"><EditPencil class="img-svg" /></Link>
                                        </td>
                                    </tr>
                                    </>
                                ))}
                                {this.state.transactionRecords.length === 0 && this.state.norecord==true && 
                                    <tr className="text-center">
                                        <td colspan="5">No seller is qualified for commission. </td>
                                    </tr>
                                }
                                </tbody>
                                </table>
                            </div>
                            <div class="mt-4 text-center">
                                {/*<ul class="pagination justify-content-center">
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >2</a></li>
                                    <li class="page-item"><a class="page-link" href="#" >3</a></li>
                                </ul>*/}
                                {this.state.totalItemsCount>0 && 
                                    <div class="justify-content-center d-flex">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                    />
                                    </div>
                                }
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                          
            </div>
        )
    }
}